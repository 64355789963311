import { FormattedMessage } from 'react-intl';

import { ReactComponent as SVGClose } from '../../../common/svgs/ic-x-lg.svg'
import { useSelector } from 'react-redux';
import { formatAmount } from '../../../common/Share/FormatCurrency';

export default function PersonalSalesWeekSalesModal({semanaActual, dataVentasSemanales}) {

    const cultureInfo = useSelector((state)=> state.userInfo.userInfo.cultureInfo)
    
  return (
    <div className="modal fade" id="weekSalesModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered weekly-sales-modal-width">
            <div className="modal-content rounded-4 overflow-hidden weekly-sales-modal-height">
                <div className="d-flex justify-content-between align-items-center border-bottom weekly-sales-header">
                    <h1 className="modal-title fs-5 museo-sans-500 s20" id="exampleModalLabel"><FormattedMessage id= "performance.week.detail.title" defaultMessage="Venta acumulada por semana" /></h1>
                    <div className="pointer " data-bs-dismiss="modal"aria-label="Close">
                        <SVGClose />
                    </div>
                </div>
                <div className="weekly-sales-body">
                    {
                        dataVentasSemanales?.weeks.map((element, index) => {
                            if (element.week > semanaActual) {
                                return(
                                    <div className={`d-flex justify-content-between ${dataVentasSemanales?.weeks?.length == index +1 ? "" : "border-bottom"}  p-3 s16`}>
                                        <p 
                                            className={`museo-sans-500 brightGrayColor`}
                                        >
                                            <FormattedMessage id="performance.week.detail.week.label" defaultMessage="Semana" /> {element.week}
                                        </p>
                                        <p 
                                            className={`dm-sans-medium brightGrayColor`}
                                        >
                                           {formatAmount(element?.amount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)} 
                                        </p>
                                    </div>
                                )
                            }
                            if(element.week === semanaActual){
                                return(
                                    <div className={`d-flex justify-content-between ${dataVentasSemanales?.weeks?.length == index +1 ? "" : "border-bottom"}  p-3 s16`}>
                                        <p 
                                            className={`museo-sans-700 blackColor`}
                                        >
                                            <FormattedMessage id="performance.week.detail.week.label" defaultMessage="Semana" /> {element.week} (<FormattedMessage id="performance.week.detail.current.week.label" defaultMessage="Actual" />)
                                        </p>

                                        <p 
                                            className={`dm-sans-bold blackColor`}
                                        >
                                           {formatAmount(element?.amount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)} 
                                        </p>
                                    </div>
                                )
                            } else {
                                return(
                                    <div className={`d-flex justify-content-between ${dataVentasSemanales?.weeks?.length == index +1 ? "" : "border-bottom"}  p-3 s16`}>
                                        <p 
                                            className={`museo-sans-500 blackColor`}
                                        >
                                            <FormattedMessage id="performance.week.detail.week.label" defaultMessage="Semana" /> {element.week}
                                        </p>
                                        <p 
                                            className={`dm-sans-medium blackColor`}
                                        >
                                           {formatAmount(element?.amount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)} 
                                        </p>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  )
}
