import { useSelector } from "react-redux";
import { ReactComponent as WarningSVG } from "../../Presentation/icons/WarningRed.svg";

export default function EcommerceError() {
  const ecommerceData = useSelector(
    (state) => state.ecommerceData?.ecommerceData
  );

  return (
    <div style={{top: "50%"}} className=" position-relative d-flex flex-column text-center align-items-center justify-content-center w-100 h-100">
      <div
        style={{ width: "295px" }}
        className="d-flex flex-column gap-4 justify-content-center align-items-center px-3 mx-4"
      >
        <div className=" position-relative d-flex justify-content-center align-items-center" >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="104"
            height="104"
            viewBox="0 0 104 104"
            fill="none"
          >
            <circle cx="52" cy="52" r="52" fill="#FDF0F0" />
          </svg>
          <div className=" position-absolute svg-force-size">
            <WarningSVG />
          </div>
        </div>
        <p className=" museo-sans-600 s24">
            {ecommerceData?.data?.response?.data}
        </p>
        <div className=" d-flex justify-content-center align-items-center rounded-3 btnLoginWidth skyBg text-white s14 museo-sans-600">
          Unknown
        </div>
      </div>
    </div>
  );
}
