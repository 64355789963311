import { ReactComponent as SVGCross } from "../../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as Green } from "../../../../common/svgs/ic-check-circle.svg";
import { ShareSocialBallIcon } from "../../../../common/Share/ShareSocialBallIcon";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useEffect } from "react";

export default function EDBModalVirtualCreated({newEdbType}) {
  const data = useSelector((state) => state.edbDetailsData.data);
  const profileData = useSelector((state) => state.userInfo.userInfo);

  const shareText = `${data?.shareText}`; 

  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem("edbNewId");
      localStorage.removeItem("edbNewType");
    }, 6000);
  }, [newEdbType])

  return (
    <div>
      <div className=" d-flex justify-content-between w-100">
        <div></div>
        <div
          style={{ top: "16px", right: "16px" }}
          data-bs-dismiss="modal"
          aria-label="Close"
          className=" position-absolute pointer"
        >
          <SVGCross />
        </div>
      </div>
      <div
        style={{ marginTop: "40px" }}
        className=" d-flex flex-column w-100 w-100 px-5"
      >
        <div className="d-flex w-100 justify-content-center mb-3 ">
          <div
            style={{ background: "#E7F5F0", width: "104px", height: "104px" }}
            className="rounded-circle d-flex justify-content-center align-items-center"
          >
            <div
              style={{ width: "48px", height: "48px" }}
              className=" svg-force-size"
            >
              <Green />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column w-100 gap-1 text-center mb-4 ">
          <p className=" s20 museo-sans-700">
            <FormattedMessage id={newEdbType == "virtual" ? "edb.modal.virtual.created.title" : "edb.modal.virtual.created.title.presencial" } />
          </p>
          <p className=" s16 museo-sans-500 grayColor">
          <FormattedMessage id="edb.modal.virtual.created.subtitle" />
          </p>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div
            style={{ width: "254px" }}
            className=" d-flex flex-column justify-content-end s12"
          >
            <div
              style={{
                padding: "11px 16px 16px 11px",
                height: "76px",
              }}
              className="d-flex justify-content-center bgWarningHelpMessage rounded-3 align-items-center text-center mb-3"
            >
              <p className=" museo-sans-500 s12">
              <FormattedMessage id="edb.modal.virtual.created.email.warning" /> {profileData?.email}
              </p>
            </div>
            <p className="grayColor s12 museo-sans-500">
            <FormattedMessage id="edb.modal.virtual.created.email.warning.update" />
              <span
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                className="skyColor px-1 pointer text-lowercase "
              >
                <FormattedMessage id="navbar.profile.landing" />
              </span>
            </p>
          </div>
          <div
            style={{ width: "210px" }}
            className="d-flex flex-column justify-content-center align-items-center gap-3"
          >
            <p className=" s20 museo-sans-700"> <FormattedMessage id="edb.modal.virtual.created.share.for" />:</p>
            <div
              style={{ top: "4px" }}
              className=" position-relative d-flex justify-content-center gap-2 s14 museo-sans-500 w-100"
            >
              <ShareSocialBallIcon
                iconName={"whatsapp"}
                iconType={"svg"}
                messageId={"social.sharedata.link.wap"}
                isColumn={true}
                width={"48px"}
                height={"48px"}
                shareText={shareText}
                iconSize={"28px"}
              />
              <ShareSocialBallIcon
                iconName={"email"}
                iconType={"img"}
                messageId={"social.sharedata.link.mail"}
                isColumn={true}
                width={"48px"}
                height={"48px"}
                shareText={shareText}
                iconSize={"28px"}
              />
            </div>
          </div>
        </div>
        <a
          id="mail-touch"
          href={`mailto:?&subject=?&body=${encodeURIComponent(shareText)}`}
          target="_blank"
          className=" position-absolute ms-2 invisible bronceStroke grayColor cancelBootstrap"
        >
          <p>Invisible!</p>
        </a>
        <a
          id="what-touch"
          href={`https://wa.me/?text=${encodeURIComponent(shareText)}`}
          target="_blank"
          className=" position-absolute ms-2 invisible bronceStroke grayColor cancelBootstrap"
        >
          <p>Invisible!</p>
        </a>
        <div className="d-flex justify-content-center align-items-end mt-5 mb-3">
          <div
            data-bs-dismiss="modal"
            aria-label="Close"
            className="d-flex rounded-3 justify-content-center align-items-center btnLoginWidth skyColor skyBorder museo-sans-700 s14 py-3 mb-3 pointer"
          >
            <FormattedMessage id="edb.modal.virtual.created.done" />
          </div>
        </div>
      </div>
    </div>
  );
}
