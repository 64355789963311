import EcommerceFormInput from "./EcommerceFormInput";

export default function EcommerceFormSocialHolder(params) {

    const onChange = (label, value) => {
        params?.onChange(`socialNetwork.${label}`, value);
    };

    return(
        <div className="d-flex flex-column gap-4 museo-sans-400" >
                <div className="d-flex flex-column gap-2" >
                    <p className=" d-flex align-items-end gap-1 s20" >
                    Redes Sociales
                    <span className=" s16" >
                    (opcional)
                    </span>
                    </p>
                    <p className="s14 grayColor" >
                    Agregar los accesos a tus redes sociales te ayudará a que te conozcan mejor y puedan contactarte más fácilmente
                    </p>
                </div>
                <div className=" d-flex flex-column gap-4 w-100" >
                    <div className="d-flex gap-3 w-100" >
                    <EcommerceFormInput onChange={onChange} value={params?.formData?.body?.socialNetwork?.facebook} label={"facebook"} />
                    <EcommerceFormInput onChange={onChange} value={params?.formData?.body?.socialNetwork?.instagram} label={"instagram"} />
                    </div>
                    <div className="d-flex gap-3 w-100" >
                    <EcommerceFormInput onChange={onChange} value={params?.formData?.body?.socialNetwork?.youtube}  label={"youtube"} />
                    <EcommerceFormInput onChange={onChange} value={params?.formData?.body?.socialNetwork?.linkedIn}  label={"linkedIn"} />
                    </div>
                </div>
            </div>
    )
}   