import { initializeApp } from "firebase/app";
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from "firebase/remote-config";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_URL_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_API_URL_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_API_URL_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_API_URL_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_API_URL_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_API_URL_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_API_URL_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const remoteConfig = getRemoteConfig(app);
remoteConfig.settings = {
  minimumFetchIntervalMillis: 5000, // Reducir el intervalo para pruebas
};

const getFirebaseData = async (keyType) => {

  let webKeyType = `WEB_${keyType}`;
  
  return fetchAndActivate(remoteConfig)
  .then(() => {
      const resultString = getValue(remoteConfig, `${webKeyType}`);
      const resultObject = JSON.parse(resultString.asString());
      return resultObject;
    })
    .catch((error) => {
      console.error("Error fetching remote config:", error);
      return null;
    });
};

export { getFirebaseData };
