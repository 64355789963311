import { useState, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";

import StatusAccountTableBody from "./StatusAccountTableBody";
import StatusAccountFilterModal from "./StatusAccountFilterModal";
import processDateData from "../../../common/Share/processDateData";

import { formatAmount } from "../../../common/Share/FormatCurrency";
import { fetchAccountStatusDataTableMyAccount } from "../../Framework/redux/StatusAccountSideEffect";
import { emptyMyAccountTableAction } from "../../Framework/redux/StatusAccountAction";
import { ReactComponent as SVGJustCoins } from "../img/ic-just-coins.svg";
import { ReactComponent as SVGCuenta } from "../img/ic-estado-de-cuenta.svg";
import { ReactComponent as FilterIcon } from "../../../common/svgs/filter-sm.svg";
import ShareFeaturedChildren from "../../../common/Share/ShareFeaturedChildren";

export default function StatusAccountTableMyAccount({
  toggler,
  setToggler,
  data,
  cultureInfo,
  calendarData,
  fatherLoader,
  fatherSetLoader,
  defaultInit,
  defaultEnd,
}) {
  const extraRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [loader, setLoader] = useState(true);
  const [offSet, setOffSet] = useState(20);
  const [isEmpty, setIsEmpty] = useState(false);
  const [filters, setFilters] = useState({
    accountType: null,
    filterType: null,
    iniDate: null,
    endDate: null,
    iniPeriod: defaultInit,
    endPeriod: defaultEnd,
  });

  const dispatch = useDispatch();

  const togglerSetAccount = async () => {
    fatherSetLoader(true);
    dispatch(emptyMyAccountTableAction());
    setFilters({ ...filters, accountType: null });
    setOffSet(20);
    setToggler(false);
    setIsEmpty(false);
    firstFetchServices(null);
    setTimeout(() => {
      fatherSetLoader(false);
    }, 1200);
  };

  const clearAllFilters = () => {
    setFilters({
      accountType: null,
      filterType: null,
      iniDate: null,
      endDate: null,
      iniPeriod: null,
      endPeriod: null,
      defaultIniDate: defaultInit,
      defaultEndDate: defaultEnd,
    });
    setToggler(false);
    setIsEmpty(false);
    setOffSet(20);
  };

  const togglerSetJustCoin = async () => {
    fatherSetLoader(true);
    dispatch(emptyMyAccountTableAction());
    setFilters({ ...filters, accountType: "justCoins" });
    setOffSet(20);
    setToggler(true);
    setIsEmpty(false);
    firstFetchServices("justCoins");
    setTimeout(() => {
      fatherSetLoader(false);
    }, 1200);
  };

  const firstFetchServices = async (accountType) => {
    setIsEmpty(false);
    let result = await dispatch(
      fetchAccountStatusDataTableMyAccount({
        accountType: accountType,
        filterType: filters.filterType,
        iniDate: filters.iniDate,
        endDate: filters.endDate,
        iniPeriod: filters.iniPeriod,
        endPeriod: filters.endPeriod,
        offSet: 0,

        defaultIniDate: defaultInit,
        defaultEndDate: defaultEnd,
      })
    );
  };

  const filtersSetter = async ({ content, number }) => {
    setLoader(true);
    fatherSetLoader(true);
    setOffSet(20);
    let activeFilters = filters;

    if (content.accountType == "currentAccount") {
      setToggler(false);
    } else {
      setToggler(true);
    }

    switch (number) {
      case 3:
        activeFilters.iniDate = content.iniDate;
        activeFilters.endDate = content.endDate;
        break;
      case 4:
        activeFilters.iniPeriod = content.iniPeriod;
        activeFilters.endPeriod = content.endPeriod;
        break;
    }

    activeFilters.accountType = content.accountType;
    activeFilters.filterType = content.filterType;

    dispatch(emptyMyAccountTableAction());

    let result = await dispatch(
      fetchAccountStatusDataTableMyAccount({
        accountType: activeFilters.accountType,
        filterType: activeFilters.filterType,
        iniDate: activeFilters.iniDate,
        endDate: activeFilters.endDate,
        iniPeriod: activeFilters.iniPeriod,
        endPeriod: activeFilters.endPeriod,
        offSet: 0,

        defaultIniDate: defaultInit,
        defaultEndDate: defaultEnd,
      })
    );

    setFilters(activeFilters);
    setIsVisible(true);
    setIsEmpty(false);
    fatherSetLoader(false);
    setLoader(false);
  };

  useEffect(() => {
    if (extraRef.current) {
      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        setIsVisible(entry.isIntersecting);
      });
      observer.observe(extraRef.current);
    }

    if (isVisible) {
      const result = callbackExtras();
    }
  }, [isVisible, extraRef.current]);

  const callbackExtras = async () => {
    setLoader(true);
    setOffSet(offSet + 20);
    let result = null;

    result = await dispatch(
      fetchAccountStatusDataTableMyAccount({
        accountType: filters.accountType,
        filterType: filters.filterType,
        iniDate: filters.iniDate,
        endDate: filters.endDate,
        iniPeriod: filters.iniPeriod,
        endPeriod: filters.endPeriod,
        offSet: offSet,

        defaultIniDate: defaultInit,
        defaultEndDate: defaultEnd,
      })
    );

    if (result?.payload?.summary?.length < 20) {
      setIsEmpty(true);
    }

    setLoader(false);
    return result;
  };

  const simpleTogglerAccount = () => {
    setToggler(false);
  };

  const simpleTogglerJustCoins = () => {
    setToggler(true);
  };

  const renderCurrentYear = () => {
    let current = moment().format("YYYY");
    return current;
  };

  const switchDetailMovement = () => {
    switch (filters.filterType) {
      case "lastPeriod":
        return (
          <>
            <FormattedMessage id="dashboard.prev.earning.period" />{" "}
            <FormattedMessage id="later" /> (P
            {calendarData?.currentPeriod > 10
              ? calendarData?.currentPeriod - 1
              : `0${calendarData?.currentPeriod - 1}`}
            )
          </>
        );
      case "lastYear":
        return (
          <span className=" ">
            <FormattedMessage id="year" /> {renderCurrentYear()}
          </span>
        );
      case "dateFilter":
        let arrayOrigin = filters.iniDate.split("-");

        let yearOrigin = arrayOrigin[0];
        let monthOrigin = arrayOrigin[1];
        let datOrigin = arrayOrigin[2];

        let arrayTarget = filters.endDate.split("-");

        let yearTarget = arrayTarget[0];
        let monthTarget = arrayTarget[1];
        let datTarget = arrayTarget[2];

        let pastDate = `${datOrigin}-${monthOrigin}-${yearOrigin}`;
        let postDate = `${datTarget}-${monthTarget}-${yearTarget}`;

        return (
          <span className=" text-uppercase">
            <FormattedMessage id="del" /> {pastDate}{" "}
            <FormattedMessage id="al" /> {postDate}
          </span>
        );
      case "periodFilter":
        return (
          <span className=" text-uppercase">
            <FormattedMessage id="del" />{" "}
            <FormattedMessage id="dashboard.prev.earning.period" />{" "}
            {filters.iniPeriod.slice(2, 4)} <FormattedMessage id="al" />{" "}
            <FormattedMessage id="dashboard.prev.earning.period" />{" "}
            {filters.endPeriod.slice(2, 4)}
          </span>
        );
      default:
        if (calendarData) {
          let pastDateTwo = processDateData(calendarData?.periodStartDate);

          let currentDate = moment().format("DD-MM-YYYY");

          return (
            <span className=" text-uppercase">
              <FormattedMessage id="del" /> {pastDateTwo}{" "}
              <FormattedMessage id="al" /> {currentDate}
            </span>
          );
        }
        break;
    }
  };

  return (
    <div className="bg-white h-100 container--padding">
      <StatusAccountFilterModal
        toggler={toggler}
        filters={filters}
        filtersSetter={filtersSetter}
        toggleAccount={simpleTogglerAccount}
        toggleJustCoins={simpleTogglerJustCoins}
        clearAllFilters={clearAllFilters}
        calendarData={calendarData}
      />
      <div className="container--padding--card-holder  ">
        <div
          style={{ paddingTop: "42px" }}
          className="d-flex flex-row justify-content-between"
        >
          <ShareFeaturedChildren keyType="JUST_COINS" falsePositive={true}>
            <div></div>
          </ShareFeaturedChildren>
          <ShareFeaturedChildren keyType="JUST_COINS">
            <div
              style={{ width: "392px" }}
              className="d-flex museo-sans-700 flex-row justify-content-center align-items-center lightVioletBg p-1 rounded-3 lightBlueColor"
            >
              <div
                onClick={togglerSetAccount}
                className={`
                    ${
                      !toggler
                        ? "bronceBg rounded-3 w-100 px-3 pt-1 pb-1 d-flex justify-content-center align-items-center"
                        : " justify-content-center w-100 hover-pointer rounded-3 px-3 pt-1 pb-1 d-flex align-items-center"
                    }
                    `}
              >
                <div className=" d-flex gap-1 s14 museo-sans-700 justify-content-center align-items-center">
                  <SVGCuenta />
                  <p>
                    <FormattedMessage id="balance.salary.current" />
                  </p>
                </div>
              </div>
              <div
                onClick={togglerSetJustCoin}
                className={`
                    ${
                      toggler
                        ? "bronceBg rounded-3 ps-4 w-100 pe-4 pt-1 pb-1 d-flex justify-content-center align-items-center"
                        : "  w-100 hover-pointer rounded-3 ps-4 pe-4 pt-1 pb-1 d-flex justify-content-center align-items-center"
                    }
                    `}
              >
                <div className="d-flex gap-1 s14 museo-sans-700 justify-content-center align-items-center">
                  <SVGJustCoins />
                  <p>
                    <FormattedMessage id="balance.salary.justCoins" />
                  </p>
                </div>
              </div>
            </div>
          </ShareFeaturedChildren>
          <div className="d-flex">
            <div
              data-bs-toggle="modal"
              data-bs-target="#modalStatusFilter"
              className=" pointer ps-4 pe-4 me-4 d-flex align-items-center justify-content-center museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-fill button-bootstrap-radius button-padding position-relative perf-history-week gap-2 "
            >
              <div className=" position-relative">
                {filters.filterType != null && (
                  <div
                    style={{ top: "0px", right: "-1px" }}
                    className=" position-absolute"
                  >
                    <div
                      className="rounded-circle "
                      style={{
                        width: "8px",
                        height: "8px",
                        backgroundColor: " rgb(240, 110, 105)",
                      }}
                    ></div>
                  </div>
                )}
                <FilterIcon />
              </div>
              <FormattedMessage id="edb.filter.button" />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ marginTop: "30px" }}
        className="container--padding--card-holder  "
      >
        <div className="d-flex flex-row justify-content-start">
          <div className=" w-100">
            <p className=" s20 museo-sans-500">
              <FormattedMessage id="movements" />
            </p>
            <p className=" pb-1 mb-3 dm-sans-medium s14 grayColor text-uppercase ">
              {switchDetailMovement()}
            </p>
            <div
              style={{ background: "#A4A4D726", borderRadius: "8px" }}
              className=" s14 d-flex  px-3 my-2 py-1"
            >
              <div className="py-2">
                <p className=" museo-sans-500">
                  <FormattedMessage id="current.balance" />:{" "}
                  {toggler ? (
                    <span className="dm-sans-bold">
                      {formatAmount(
                        data?.data?.justCoins,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}
                    </span>
                  ) : (
                    <span className="dm-sans-bold">
                      {formatAmount(
                        data?.data?.currentAccount,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <StatusAccountTableBody
          fatherLoader={fatherLoader}
          summary={data?.dataTable?.summary}
          cultureInfo={cultureInfo}
          descId={false}
        />
      </div>

      {!isEmpty && (
        <div ref={extraRef} className="mt-4" style={{ height: "30px" }}>
          {isVisible ? (
            loader && !fatherLoader ? (
              <div
                style={{ top: "-30px" }}
                className=" position-relative d-flex flex-column justify-content-center align-items-center"
              >
                <div className="spinner-border blueColor" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <p className="museo-sans-500 s14 mt-2">
                  <FormattedMessage id="loading.more.movements" />
                </p>
              </div>
            ) : (
              <></>
            )
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}
