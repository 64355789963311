export default function ShareLocalityController(countryCode) {
  const references = {
    mx: [
      "street",
      "state",
      "municipality",
      "locality",
      "postalCode",
      "reference",
    ],
    cl: ["street", "region", "province","commune"],
    pe: ["street", "department","district","city", "reference"],
    co: ["street", "department", "city", "neighborhood"],
    uy: ["street", "department", "locality", "neighborhood", "signs"],
    cr: ["province", "canton", "district", "street", "signs"],
    pa: ["street", "reference", "district", "province", "township"],
    ar: ["street", "province", "locality", "postalCode"],
  };
  const needSelect = [
    "municipality",
    "state",
    "canton",
    "region",
    "commune",
    "province",
    "postalCode",
    "township",
    "locality",
    countryCode != "co" &&  "neighborhood",
    "district",
    "city",
    "department",
  ];

  const referencesWithLevels = {};


  Object.keys(references).forEach((country) => {
    let levelCounter = 0
    referencesWithLevels[country] = references[country].map((reference) => {
      return {
        name: reference,
        isLevel: needSelect.includes(reference)
          ? ++levelCounter
          : -1,
      };
    });
  });

  const countryReferences = referencesWithLevels[countryCode];
  if (!countryReferences?.length ?? countryReferences?.length < 1) {
    return [];
  }
  const maxLevel = Math.max(...countryReferences.map((ref) => ref.isLevel));
  
  return {references: countryReferences, maxLevel}
}
