import '../pages/Configuration.css'

import { ReactComponent as EditSVG } from "../icons/Edit.svg";
import ShareFeaturedChildren from '../../../Share/ShareFeaturedChildren';

export default function DataField({fieldLabel, fieldContent, clickFunction, whereTo, noWhere}) {

  const onVoid = () => {
    return
  };

  return (
    <div className={`${noWhere ? "" : "hover-pointer"}`} onClick={ noWhere ? onVoid : () => clickFunction(whereTo)}>
        <p className='fw-bold mb-2 museo-sans-700 s12 blackColor'>{fieldLabel}</p>
        <div className='d-flex justify-content-between border-bottom mb-3'>
            <p style={{width: "278px"}} className=' overflow-auto mb-1 museo-sans-500 s16 grayColor'>{fieldContent}</p>
            
            {
              noWhere == "nowhere" ?
              <>
              
              </>
              :
                <ShareFeaturedChildren keyType="SETTINGS" >
                  <EditSVG />
                </ShareFeaturedChildren>
            }
        </div>
    </div>
  )
}
