import { useState, useEffect, useRef } from "react";

import { ReactComponent as SVGChevArrowDown } from "../svgs/ic-chevron-down-md.svg";
import { ReactComponent as SVGChevArrowUp } from "../svgs/ic-chevron-up-md.svg";

export default function ShareInputWithSelectableValue(params) {
  const [currentInputValue, setCurrentInputValue] = useState("");
  const [savedValue, setSavedValue] = useState(params?.inheritValue ? true : false);
  const divRef = useRef(null);

  const onChangeCurrentValue = (evt) => {
    setSavedValue(false);
    setCurrentInputValue(evt.target.value);
  };

  const onSelectedValue = (item) => {
    setSavedValue(true);
    setCurrentInputValue(item?.label ?? item);
    let returnObject = { ...item, key: params.name, level: params.level };
    params.onChangeValue(returnObject);
  };

  const operateSelectContainer = () => {
    if (!params?.IsDisabled) {
      params.setTogglerSelect(params.name);
    }
  };

  const ifValueIsClearTrim = (currentValue) => {
    if (currentValue.trim().length === 0) {
      return "";
    }
    return currentValue
  };
  
  useEffect(() => {
      setCurrentInputValue(params?.inheritValue || " ");
      setSavedValue(true);
  }, [params?.inheritValue]);

  useEffect(() => {
    if (params?.inheritValue == "") {
      setCurrentInputValue("");
      
    }
  }, [params?.IsDisabled]);

  useEffect(() => {
    if (!savedValue) {
      const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
      params?.onLostSavedValue(params.level, auxFormBody?.addressInfo);
    };
  }, [savedValue]);


  const inputStyleOnActive =
    "d-flex bg-white rounded-3 input-border w-100 pointer";
  const inputStyleOnInactive =
    "d-flex rounded-3 brightGrayBgOpacity20 input-border  w-100";
    const inputStyleOnInactiveError =
    "d-flex rounded-3 brightGrayBgOpacity20 input-error-border  w-100";
  const inputStyleOnError =
    "d-flex bg-white rounded-3 input-error-border w-100 pointer";

  return (
    <div
      ref={divRef}
      style={{ width: params?.width || "300px", height: "40px" }}
      onClick={() => operateSelectContainer(!params.togglerSelect)}
      className="d-flex position-relative museo-sans-500 "
    >
      <div
        className={
          !params?.IsDisabled
            ? params?.isError
              ? inputStyleOnError
              : inputStyleOnActive
            : params?.isError ? inputStyleOnInactiveError : inputStyleOnInactive
        }
      >
        {
          params?.IsDisabled ?
          <input
            type="text"
            className="input-styless w-100 s14 px-2 mx-1"
            value={ifValueIsClearTrim(currentInputValue)}
            onChange={(evt) => onChangeCurrentValue(evt)}
            placeholder={params.placeholder || "Choose placeholder"}
            disabled />
            :
            <input
          type="text"
          className="input-styless w-100 s14 px-2 mx-1"
          value={ifValueIsClearTrim(currentInputValue)}
          onChange={(evt) => onChangeCurrentValue(evt)}
          placeholder={params.placeholder || "Choose placeholder"}
        />
        }
        
        <div
          style={{ width: "1px" }}
          className={`${
            params?.isError ? "input-error-border" : "input-border"
          }`}
        ></div>
        <div
          style={{ width: "50px" }}
          className={` d-flex justify-content-center align-items-center ${
            !params?.IsDisabled ? "" : "svg-force-path-stroke-grey"
          } s14`}
        >
          {params.togglerSelect ? <SVGChevArrowUp /> : <SVGChevArrowDown />}
        </div>
      </div>
      {params.togglerSelect && (
        <SelectFloatingList
          list={params.list}
          inputValue={savedValue ? "" : currentInputValue}
          onSelectedValue={onSelectedValue}
          toggler={params.togglerSelect}
        />
      )}
    </div>
  );
}

export function SelectFloatingList(params) {
  const [selectList, setSelectList] = useState([]);

  useEffect(() => {
    if (params.inputValue) {
      setSelectList(
        params.list.filter((item) =>
          item?.label
            ? item.label.toLowerCase().includes(params.inputValue.toLowerCase().trim())
            : item.toLowerCase().includes(params.inputValue.toLowerCase().trim())
        )
      );
    } else {
      setSelectList(params?.list || []);
    }
  }, [params?.list, params.inputValue]);

  return (
    <div
      style={{ top: "42px", left: -3, maxHeight: "200px", zIndex: 4 }}
      className=" position-absolute w-100 hidden-y-scroll bg-white input-border rounded-2 px-2 mx-1 d-flex flex-column gap-2 py-2 s14 "
    >
      <>
        {selectList?.length > 0 ? (
          <>
            {selectList.map((item, index) => {
              return (
                <div
                  className="text-onhover-bold pointer "
                  key={item?.value ?? index}
                  onClick={() => params.onSelectedValue(item)}
                >
                  {item?.label ?? item}
                </div>
              );
            })}
          </>
        ) : (
          <>No hay resultados</>
        )}
      </>
    </div>
  );
}
