import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const fetchVirtualEcommerce  = createAsyncThunk(
    'fetchVirtualEcommerce',
    async (number, {rejectWithValue}) => {
        try {
            let country = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios(false,false,false,false,false,false,true).get(`${country}/profile/${clientId}/store`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const updateVirtualEcommerce  = createAsyncThunk(
    'fetchVirtualEcommerce',
    async (body, {rejectWithValue}) => {
        try {
            let country = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            console.log("body: ", body);
            const response = await customNetServAxios("application/json;charset=UTF-8", null, [400]).put(`${country}/profile/${clientId}/store/${body?.id}`, body);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const fetchVirtualEcommerceTopics  = createAsyncThunk(
    'fetchVirtualEcommerceTopics',
    async (number, {rejectWithValue}) => {
        try {
            let country = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`${country}/profile/${clientId}/store/topics`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

export { fetchVirtualEcommerce, fetchVirtualEcommerceTopics, updateVirtualEcommerce };