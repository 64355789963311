import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import { ReactComponent as SVGError } from "../../../../EDBs/Presentation/img/redcross.svg";
import { ReactComponent as SVGGreen } from "../../../../common/svgs/ic-check-circle.svg";

export default function EcommerceRegisterModalName({ current }) {
  return (
    <div className="w-100" style={{ padding: "44px" }}>
      <div className="d-flex align-items-center gap-2 museo-sans-600 s24 ">
        <HelpIconNoTarget />
        <p>Requisitos del nombre</p>
      </div>
      <div className="d-flex gap-4 mt-3 pt-1">
        <div>
          <div className="d-flex gap-1 mb-1">
            <div
              style={{ width: "16px", height: "16px" }}
              className=" position-relative svg-force-size"
            >
              <SVGGreen />
            </div>
            <p
              style={{ top: "4px" }}
              className=" position-relative greenColor s14 museo-sans-600"
            >
              Lo que sí:
            </p>
          </div>
          <ul
            style={{ left: "-11px" }}
            className=" position-relative museo-sans-400 s14"
          >
            <li>
              Elegí un nombre fácil para que tus clientes puedan recordarlo.
            </li>
            <li>Te recomendamos utilizar tu nombre y apellido.</li>
            <li>
              El nombre de tu tienda puede tener hasta 25 caracteres y puedes
              utilizar únicamente letras y números.
            </li>
            <li>
              Una vez que lo selecciones no vas a poder cambiarlo. Ejemplos
              correctos: https://www.justargentina.com/jimenabila
              https://www.justargentina.com/jimena
            </li>
          </ul>
        </div>
        <div>
          <div className="d-flex gap-1 mb-1">
            <div
              style={{ width: "16px", height: "16px" }}
              className=" svg-force-size"
            >
              <SVGError />
            </div>
            <p
              style={{ top: "4px" }}
              className=" position-relative lightRedColor s14 museo-sans-600"
            >
              Lo que no:
            </p>
          </div>
          <ul
            style={{ left: "-11px" }}
            className=" position-relative museo-sans-400 s14"
          >
            <li>
              No se puede utilizar la palabra Just, SwissJust o algún nombre de
              producto Just. Tampoco provincia, ciudad, aromaterapia, bienestar,
              localidades, etc.
            </li>
            <li>
              No utilices espacios, ni acentos, ni símbolos especiales (ñ,#.%).
            </li>
            <li>
              Ejemplos incorrectos: https://www.justargentina.com/jimenajust
              https://www.justargentina.com/oleo31
            </li>
          </ul>
        </div>
      </div>
      <div
        style={{ marginTop: "32px" }}
        className="w-100 d-flex justify-content-center"
      >
        <button
          data-bs-dismiss="modal"
          aria-label="Close"
          className=" border-0 rounded-3 btnLoginWidth skyBg s14 museo-sans-600 text-white "
        >
          <FormattedMessage id="buttons.understood" />
        </button>
      </div>
    </div>
  );
}
