import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ShareCheckEnvKey from "../../../common/Share/ShareCheckEnvKey";

import { ReactComponent as SVGCrossGray } from "../img/icons/crossGray.svg";
import { ReactComponent as SVGCards } from "../img/icons/cards.svg";
import { ReactComponent as SVGTrash } from "../img/trashCan.svg";
import { ReactComponent as SVGChevronRight } from "../img/icons/chevronsm.svg";
import ShareFeaturedChildren from "../../../common/Share/ShareFeaturedChildren";

export default function EDBDetailsTableList({
  data,
  type,
  loading,
  cultureInfo,
  setInviteeInfo,
  addInvitation,
  directorId,
}) {
  const history = useNavigate();

  const onSeekClient = (clientId, hasCards) => {
    if (hasCards) {
      history(`invitee/details/${clientId}`);
    }
    if (!hasCards) {
      history(`invitee/details/${clientId}/empty`);
    }
  };

  return (
    <div className=" w-100 mb-3 container--padding container--no-padding py-3">
      <h3 className="py-4 s20 museo-sans-500">
        {type !== "something" ? (
          <FormattedMessage id="edb.details.list.title.assist" />
        ) : (
          <FormattedMessage id="edb.details.list.title.invited" />
        )}
      </h3>
      {!loading && type !== "cerrado" && (
        <ShareFeaturedChildren keyType="CREATE_EDB">
          {data?._metadata.inviteEdbLink && !directorId && (
            <button
              onClick={addInvitation}
              style={{ width: "288px", height: "41px" }}
              className=" mb-3 skyBg museo-sans-700 s14 text-white rounded-2 border-0 "
            >
              <FormattedMessage id="edb.details.assistant.button.add" />
            </button>
          )}
        </ShareFeaturedChildren>
      )}
      <div>
        <div className=" d-flex justify-content-between py-1">
          {loading ? (
            <SkeletonEDBDetailsList />
          ) : (
            <p className=" museo-sans-500 s12 text-uppercase grayColor">
              <FormattedMessage id="edb.details.list.name" />
            </p>
          )}

          {type !== "something" && (
            <>
              {loading ? (
                <SkeletonEDBDetailsList type={type} />
              ) : (
                <p className=" museo-sans-500 s12 text-uppercase grayColor">
                  <FormattedMessage id="edb.details.list.buy" />
                </p>
              )}
            </>
          )}
        </div>
        <div className=" museo-sans-700">
          <>
            {loading ? (
              <>
                <hr></hr>
                <div className="edb--details-table">
                  <SkeletonEDBDetailsListRow type={type} />
                </div>
                <hr></hr>
                <div className="edb--details-table">
                  <SkeletonEDBDetailsListRow type={type} />
                </div>
                <hr></hr>
                <div className="edb--details-table">
                  <SkeletonEDBDetailsListRow type={type} />
                </div>
                <hr></hr>
                <div className="edb--details-table">
                  <SkeletonEDBDetailsListRow type={type} />
                </div>
              </>
            ) : (
              <>
                {data ? (
                  data.attendees.length > 0 ? (
                    [...data.attendees]
                      .sort((a, b) => (a.host === "S" ? -1 : 1))
                      .map((guest, index) => (
                        <EDBDetailsTableListRow
                          key={index}
                          onSeekClient={onSeekClient}
                          {...{
                            guest,
                            cultureInfo,
                            data,
                            type,
                            index,
                            directorId,
                            setInviteeInfo,
                          }}
                        />
                      ))
                  ) : (
                    <>
                      <hr />
                      <div></div>
                    </>
                  )
                ) : null}
                <hr />
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
}

export function EDBDetailsTableListRow({
  guest,
  cultureInfo,
  data,
  type,
  index,
  setInviteeInfo,
  onSeekClient,
  directorId,
}) {
  const hasCards = guest?.hasCards ? (
    <div className="d-flex greenColor s14 museo-sans-500 gap-1 align-items-center ">
      {" "}
      <FormattedMessage id="edb.has.cards.true" /> <SVGCards />
    </div>
  ) : (
    <div className="d-flex grayColor s14 museo-sans-500 gap-1 align-items-center ">
      {" "}
      <FormattedMessage id="edb.has.cards.false" /> <SVGCrossGray />{" "}
    </div>
  );

  return (
    <>
      <hr></hr>
      <div className=" d-flex justify-content-between ">
        <div className="d-flex jus s14 gap-2 align-items-center museo-sans-700 w-90 ">
          <p id={guest.attendeeId} className="">
            {guest.name}
            {guest.host == "S" && (
              <span className="px-2 pe-0 grayColor s12 museo-sans-500">
                <FormattedMessage id="host" />
              </span>
            )}
          </p>
          {!directorId && (
            <div
              onClick={() => onSeekClient(guest.attendeeId, guest?.hasCards)}
              className=" position-relative d-flex gap-1 pointer skyColor"
            >
              <p>
                <FormattedMessage id="calendar.event.card.view.profile" />
              </p>
              <div className=" position-relative " style={{ top: "-1px" }}>
                <SVGChevronRight />
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between w-100 ">
          {hasCards}
          <div className="d-flex s14 gap-2">
            {type !== "fechado" && (
              <p className=" grayColor museo-sans-700 ">
                {guest.totalAmount !== 0 ? (
                  <>
                    {data && (
                      <>
                        {formatAmount(
                          guest.totalAmount,
                          cultureInfo?.languageCode,
                          cultureInfo?.defaultCurrencyCode
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <FormattedMessage id="edb.details.list.no.buys" />
                )}
              </p>
            )}
            <ShareFeaturedChildren keyType="CREATE_EDB">
              <>
                {
                  (type !== "cerrado" &&
                  guest.totalAmount == 0 &&
                  index != 0) &&
                  <div
                  data-bs-toggle="modal"
                  data-bs-target="#modalDeleteInvitee"
                  onClick={() =>
                    setInviteeInfo({
                      id: guest.attendeeId,
                      name: guest.name,
                    })
                  }
                  style={{ top: -2, width: "16px" }}
                  className=" pointer position-relative svg-force-size "
                >
                  <SVGTrash />
                </div>
                }
              </>
              </ShareFeaturedChildren>
          </div>
        </div>
      </div>
    </>
  );
}

export function SkeletonEDBDetailsList() {
  return (
    <div
      style={{ height: "8px", width: "47px" }}
      className="lightui1-shimmer brightGrayBg rounded-3 "
    ></div>
  );
}

export function SkeletonEDBDetailsListRow({ type }) {
  return (
    <>
      <div
        style={{ height: "8px", width: "47px" }}
        className="lightui1-shimmer brightGrayBg rounded-3 "
      ></div>
      {type != "fechado" && (
        <div
          style={{ height: "8px", width: "47px" }}
          className="lightui1-shimmer brightGrayBg rounded-3 "
        ></div>
      )}
    </>
  );
}
