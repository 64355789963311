import { ReactComponent as SVGTriangle } from "../../../common/svgs/ic-triangle-warning-sm.svg";
import { ReactComponent as SVGBook } from "../../../common/svgs/ic-book-lg.svg";
import { ReactComponent as SVGRightArrow } from "../../../common/svgs/ic-chevron-right-md.svg";

import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AccountBalanceSmaller from "./AccountBalanceSmaller";
import AccountBalanceBigger from "./AccountBalanceBigger";
import ShareFeaturedChildren from "../../../common/Share/ShareFeaturedChildren";

export default function AccountBalance({ periodReducer, loading }) {
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  const biggerThan1000 = useMediaPredicate("(min-width: 1281px)");
  const history = useNavigate();

  const goTo = () => {
    history("status");
  };

  const renderBalance = (title, amount, status) => {
    return (
      <>
        
        {status !== "normal" ? (
          <div style={{ alignItems: "center" }} className="d-flex">
            <p className="dm-sans-bold s26 goldColor">{formatAmount(amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}</p>
            <p className="invisible">a</p>
            <SVGTriangle />
          </div>
        ) : <p className="dm-sans-bold s26">{formatAmount(amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}</p> }
        <p className="museo-sans-500 s14">
          <FormattedMessage id={`balance.salary.${title}`} />
        </p>
      </>
    );
  };

  return (
    <div id="account-balance--container" className="position-relative">
    {biggerThan1000 && (
      <AccountBalanceBigger goTo={goTo} renderBalance={renderBalance} periodReducer={periodReducer} />
    )}
    {!biggerThan1000 && (
      <AccountBalanceSmaller goTo={goTo} renderBalance={renderBalance} periodReducer={periodReducer} />
    )}

    <div id="ethic" className="">
      <p className="d-flex museo-sans-500 s20">
        <FormattedMessage id="balance.salary.business.title" />
      </p>

      <div id="ethic-content" data-bs-toggle="modal" href="#ethicsCode" className="f9GrayBorder pointer d-flex justify-content-between p-3 mt-3 account--card-shadow bg-white rounded-account-cards" style={{ alignItems: "center" }}>
        {loading ? (
          <>
            <div style={{ width: "32px", height: "32px" }} className="rounded-circle brightGrayBg"></div>
            <div style={{ width: "116px", height: "12px" }} className="rounded-2 brightGrayBg"></div>
            <p><SVGRightArrow /></p>
          </>
        ) : (
          <>
            <p><SVGBook /></p>
            <p className="museo-sans-500 s16"><FormattedMessage id="politics.meta.code" /></p>
            <p><SVGRightArrow /></p>
          </>
        )}
      </div>
    </div>
  </div>
  );
}

export function SkeletonAccountBalanceEthic() {
  return (
    
      <div className=" d-flex flex-column gap-1 ">
        <div className=" d-flex flex-column gap-3">
          <div
            style={{ height: "24px" }}
            className=" rounded-3 brightGrayBg w-50  lightui1-shimmer"
          ></div>
          <div
            style={{ height: "8px" }}
            className=" rounded-3 brightGrayBg w-50  lightui1-shimmer"
          ></div>
        </div>
        <hr></hr>
        <div className=" d-flex flex-column gap-3 ">
          <ShareFeaturedChildren keyType="JUST_COINS" >
          <>
          <div
            style={{  height: "24px" }}
            className=" rounded-3 brightGrayBg w-50  lightui1-shimmer"
          ></div>
          <div
            style={{ height: "8px" }}
            className=" rounded-3 brightGrayBg w-50  lightui1-shimmer"
          ></div>
          </>

            </ShareFeaturedChildren >
          <div
            style={{ height: "40px" }}
            className=" rounded-3 brightGrayBg w-80  lightui1-shimmer"
          ></div>
        </div>
      </div>
    
  );
}
