import ShareFeaturedChildren from "../../../common/Share/ShareFeaturedChildren";
import FirstStepsDashboardShowcase from "../../../FirstSteps/Presentation/pages/FirstStepsDashboardShowcase";

export default function DashboardFirstStepsContainer({
  callServiceFirstSteeps,
  profileData,
  country,
  loader,
}) {
  return (
    <ShareFeaturedChildren keyType="FIRST_STEPS">
      <FirstStepsDashboardShowcase
        reloadMessage={callServiceFirstSteeps}
        profileData={profileData}
        country={country}
        loader={loader}
        callServiceFirstSteeps={callServiceFirstSteeps}
      />
    </ShareFeaturedChildren>
  );
}
