import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import "../pages/Configuration.css";

import UserDataMainPage from "../components/UserDataMainPage";
import UserDataChangeEmail from "../components/UserDataChangeEmail";
import ConfigChangeSuccessful from "../components/ConfigChangeSuccessful";
import UserDataChangePhoneNumber from "../components/UserDataChangePhoneNumber";
import UserDataPhoneNumberVerifyCode from "../components/UserDataPhoneNumberVerifyCode";
import UserDataChangeStreetAddress from "../components/UserDataChangeStreetAddress";
import UserDataConfirmNewStreetAddress from "../components/UserDataConfirmNewStreetAddress";
import UserDataChangeProfilePicture from "../components/UserDataChangeProfilePicture";
import ConfigurationPassword from "../components/ConfigurationPassword";
import { fetchUserProfile } from "../../../../Login/Framework/redux/loginSideEffects";

export default function UserData({setModalTexts}) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userInfo.userInfo);

  const [value, setValue] = useState(1.1);
  const [savedValues, setSavedValues] = useState({});

  const profilePictureChangeDescription =
    "¡Tu foto de perfil ha sido cambiada con éxito";

  const JSXObject = {
    1.1: <UserDataMainPage userData={userData} travelFunction={setValue} />,
    1.2: <UserDataChangeEmail userData={userData} setSavedValues={setSavedValues} travelFunction={setValue} />,
    1.3: (
      <ConfigChangeSuccessful
        travelFunction={setValue}
        setModalTexts={setModalTexts}
        savedValues={savedValues}
      />
    ),
    1.4: <UserDataChangePhoneNumber userData={userData} setSavedValues={setSavedValues} travelFunction={setValue} />,
    1.5: <UserDataPhoneNumberVerifyCode savedValues={savedValues} setModalTexts={setModalTexts} travelFunction={setValue} />,
    1.6: (
      <ConfigChangeSuccessful
        travelFunction={setValue}
        setModalTexts={setModalTexts}
        savedValues={savedValues}
      />
    ),
    1.7: <UserDataChangeStreetAddress travelFunction={setValue} />,
    1.8: <UserDataConfirmNewStreetAddress travelFunction={setValue} />,
    2.0: <UserDataChangeProfilePicture travelFunction={setValue} />,
    2.1: (
      <ConfigChangeSuccessful
        travelFunction={setValue}
        title={"settings.user.data.profile.picture.title"}
        description={profilePictureChangeDescription}
      />
    ),
    3.0: (<ConfigurationPassword setModalTexts={setModalTexts} travelFunction={setValue} />
    ),
  };

  const onStartUp = async () => {
    localStorage.removeItem("profile-ecommerce")
    await dispatch(fetchUserProfile())
  };
  
  useEffect(() => {
    onStartUp();
  }, [])

  return (
    <section className="position-relative h-100 config-section-container">
      {JSXObject[value]}
    </section>
  );
}
