import { useState } from "react";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { FormattedMessage, IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { LoginPin } from "./app/Login/Presentation/pages/LoginPin";
import { Login } from "./app/Login/Presentation/pages/Login";
import { PrivateRoute, PublicRoute } from "./app/common/routes/routes";

import LoginPassRec from "./app/Login/Presentation/pages/LoginPassRec";
import LoginProfile from "./app/Login/Presentation/pages/LoginProfile";
import LoginReady from "./app/Login/Presentation/pages/LoginReady";
import LoginNumber from "./app/Login/Presentation/pages/LoginNumber";
import LoginConfirm from "./app/Login/Presentation/pages/LoginConfirm";
import LoginPinBuild from "./app/Login/Presentation/pages/LoginPinBuild";
import LoginPinCheck from "./app/Login/Presentation/pages/LoginPinCheck";
import LoginPinRecovery from "./app/Login/Presentation/pages/LoginPinRecovery";
import LoginPassRecPin from "./app/Login/Presentation/pages/LoginPassRecPin";
import LoginPassRecNew from "./app/Login/Presentation/pages/LoginPassRecNew";
import Dashboard from "./app/Dashboard/Presentation/pages/Dashboard";
import EDBsDashboard from "./app/EDBs/Presentation/pages/EDBsDashboard";
import GoodPractices from "./app/GoodPractice/Presentation/pages/GoodPractices";
import EDBsListing from "./app/EDBs/Presentation/pages/EDBsListing";
import EDBDetails from "./app/EDBs/Presentation/pages/EDBDetails";
import Account from "./app/Account/Presentation/pages/Account";
import Calendar from "./app/Calendar/Presentation/pages/Calendar";
import Performance from "./app/Performance/Presentation/pages/Performance";
import DashboardPrevPeriods from "./app/Dashboard/Presentation/pages/DashboardPrevPeriods";
import BusinessPlan from "./app/BusinessPlan/Presentation/pages/BusinessPlan";
import PersonalSalesDetails from "./app/Performance/Presentation/components/PersonalSalesDetails";
import OtherUserPersonalSales from "./app/Performance/Presentation/components/OtherUserPersonalSales";
import Descendants from "./app/Descendants/Presentation/pages/Descendants";
import DescendantPersonalGroup from "./app/Descendants/Presentation/pages/DescendantPersonalGroup";
import DescendantsDashboard from "./app/Descendants/Presentation/pages/DescendantsDashboard";
import DescendantProfile from "./app/Descendants/Presentation/pages/DescendantProfile";
import DescendantAscendentProfile from "./app/Descendants/Presentation/pages/DescendantAscendentProfile";
import phones from "./app/common/img/pantallasApp.png";
import google from "./app/common/img/google.png";
import ios from "./app/common/img/ios.png";
import huawei from "./app/common/img/huawei.png";
import leaf from "./app/common/img/Leaf-Top.png";
import symbols from "./app/common/img/Symbols.png";
import EDBsListOutlet from "./app/EDBs/Presentation/pages/EDBsListOutlet";
import LoginConsultantError from "./app/Login/Presentation/pages/LoginConsultantError";
import PersonalSalesHistory from "./app/Performance/Presentation/components/PersonalSalesHistory";
import PersonalSalesRegisters from "./app/Performance/Presentation/components/PersonalSalesRegisters";
import StatusAccount from "./app/StatusAccount/Presentation/pages/StatusAccount";
import StatusAccountDetails from "./app/StatusAccount/Presentation/pages/StatusAccountDetails";
import StatusAccountTable from "./app/StatusAccount/Presentation/pages/StatusAccountTable";
import DarshboardFirstStepsPersonalIncorporated from "./app/FirstSteps/Presentation/components/DarshboardFirstStepsPersonalIncorporated";
import DashboardFirstSteepsRestOfGroup from "./app/FirstSteps/Presentation/components/DashboardFirstSteepsRestOfGroup";
import DashboardFirstStepsFirstGeneration from "./app/FirstSteps/Presentation/components/DashboardFirstStepsFirstGeneration";
import DashboardOffers from "./app/Oferts&Features/Presentation/pages/DashboardOffers";
import FirstStepsDashboard from "./app/FirstSteps/Presentation/pages/FirstStepsDashboard";
import ClientList from "./app/ClientList/Presentation/pages/ClientList";
import ClientListDetails from "./app/ClientList/Presentation/pages/ClientListDetails";
import ClientListForm from "./app/ClientList/Presentation/pages/ClientListForm";
import InvitationsList from "./app/Invitations/Presentation/pages/InvitationsList";
import InvitationsAdd from "./app/Invitations/Presentation/pages/InvitationsAdd";
import Invitations from "./app/Invitations/Presentation/pages/Invitations";
import LoginSearch from "./app/Login/Presentation/pages/LoginSearch";
import EDBsForm from "./app/EDBs/Presentation/pages/EDBsForm";
import EDBsFormPresent from "./app/EDBs/Presentation/components/EDBsFormPresent";
import EDBsFormVirtual from "./app/EDBs/Presentation/components/EDBsFormVirtual";
import EDBDetailsAddInvitation from "./app/EDBs/Presentation/pages/EDBDetailsAddInvitation";
import GoodPracticesListBond from "./app/GoodPractice/Presentation/pages/GoodPracticesListBond";
import EcommerceDashboard from "./app/Ecommerce/Presentation/pages/EcommerceDashboard";
import EcommerceRegister from "./app/Ecommerce/Presentation/pages/EcommerceRegister";
import EcommerceForm from "./app/Ecommerce/Presentation/pages/EcommerceForm";
import EcommerceError from "./app/Ecommerce/Presentation/pages/EcommerceError";

function Routering() {
  const [firstLoginListener, setFirstLoginListener] = useState(false);
  const ecommerceData = useSelector((state) => state.ecommerceData?.ecommerceData?.data);

  const activateFirstLogin = () => {
    setFirstLoginListener(true);
  };

  const deactivateFirstLogin = () => {
    setFirstLoginListener(false);
  };

  const JSXEdbRouteringClients = (
    <>
      <Route index element={<EDBDetails />} />
      <Route path="invitee/details/:clientId" element={<ClientListDetails />} />
      <Route
        path="invitee/details/:clientId/empty"
        element={<ClientListDetails isEmpty={true} />}
      />
      <Route path="invitation" element={<EDBDetailsAddInvitation />} />
      <Route path="client" element={<Outlet />}>
        <Route path="list" element={<ClientList />} />
        <Route path=":operation" element={<Outlet />}>
          <Route index element={<ClientListForm />} />
          <Route path=":contactId" element={<ClientListForm />} />
        </Route>
      </Route>
    </>
  );

  return (
    <Router>
      <Routes>
        <Route path="/" element={<PublicRoute />}>
          <Route index element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/directora/logging" element={<LoginSearch />} />
          <Route path="/phone" element={<LoginNumber />} />
          <Route path="/confirm" element={<LoginConfirm />} />
          <Route path="/error" element={<LoginConsultantError />} />

          <Route path="/pin" element={<LoginPin />} />
          <Route path="/pin/build" element={<LoginPinBuild />} />
          <Route path="/pin/check" element={<LoginPinCheck />} />
          <Route path="/pin/recovery" element={<LoginPinRecovery />} />

          <Route path="/profile" element={<LoginProfile />} />

          <Route path="/recovery" element={<LoginPassRec />} />
          <Route path="/recovery/pin" element={<LoginPassRecPin />} />
          <Route path="/recovery/password" element={<LoginPassRecNew />} />

          <Route
            path="/ready"
            element={<LoginReady activateFirstLogin={activateFirstLogin} />}
          />
          <Route path="/dashboard" element={<PrivateRoute />}>
            <Route
              index
              element={
                <Dashboard
                  firstLoginListener={firstLoginListener}
                  deactivateFirstLogin={deactivateFirstLogin}
                  activateFirstLogin={activateFirstLogin}
                />
              }
            />
            <Route path="ecommerce" element={<EcommerceDashboard />}>
              <Route index element={<></>} />
              <Route path="edit" element={<EcommerceForm />} >
                <Route path=":id" element={<></>} />
              </Route>
              <Route path="mod" element={<EcommerceRegister />} />
              <Route path="new" element={<EcommerceRegister />} />
              <Route path="error" element={<EcommerceError />} />
            </Route>
            <Route path="invitations" element={<Invitations />}>
              <Route path="list" element={<InvitationsList />} />
              <Route path="add" element={<InvitationsAdd />} />
            </Route>
            <Route path="weeklydeals" element={<DashboardOffers />} />
            <Route path="clientlist" element={<ClientList />} />

            <Route
              path="clientlist/details/:id"
              element={<ClientListDetails />}
            />
            <Route
              path="clientlist/details/:id/cards"
              element={<ClientListDetails />}
            />
            <Route path="clientlist/form/:operation" element={<Outlet />}>
              <Route index element={<ClientListForm />} />
              <Route path={":contactId"} element={<ClientListForm />} />
            </Route>
            <Route path="firstSteps" element={<FirstStepsDashboard />}>
              <Route
                path="incorporatedPersonal"
                element={<DarshboardFirstStepsPersonalIncorporated />}
              />
              <Route
                path="restOfGroup"
                element={<DashboardFirstSteepsRestOfGroup />}
              />
              <Route
                path="firstGeneration"
                element={<DashboardFirstStepsFirstGeneration />}
              />
            </Route>
            <Route path="businessplan" element={<BusinessPlan />} />
            <Route path="earning/period" element={<DashboardPrevPeriods />} />
            <Route path="edbs" element={<Outlet />}>
              <Route index element={<EDBsDashboard />} />
              <Route path="list/:state" element={<EDBsListOutlet />}>
                <Route index element={<EDBsListing />} />
                <Route path=":id" element={<Outlet />}>
                  {JSXEdbRouteringClients}
                </Route>
              </Route>
              <Route path="details" element={<Outlet />}>
                <Route path=":type/:id" element={<Outlet />}>
                  {JSXEdbRouteringClients}
                </Route>
              </Route>
              <Route path="form" element={<Outlet />}>
                <Route path="type" element={<EDBsForm />}>
                  <Route path="present" element={<EDBsFormPresent />}>
                    <Route path=":edbId" element={<></>} />
                  </Route>
                  <Route path="virtual" element={<EDBsFormVirtual />}>
                    <Route path=":edbId" element={<></>} />
                  </Route>
                </Route>
                <Route path="client" element={<Outlet />}>
                  <Route path="list" element={<ClientList />} />
                  <Route path=":operation" element={<Outlet />}>
                    <Route index element={<ClientListForm />} />
                    <Route path=":contactId" element={<ClientListForm />} />
                  </Route>
                </Route>
                <Route path="client/list" element={<ClientList />} />
              </Route>
            </Route>
            <Route path="goodpractice" element={<GoodPractices />} />
            <Route
              path="goodpractice/bonds"
              element={<GoodPracticesListBond />}
            />
            <Route path="account/status" element={<StatusAccount />}>
              <Route index element={<StatusAccountTable />} />
              <Route
                path="details/:id/:descId"
                element={<StatusAccountDetails />}
              />
            </Route>
            <Route path="account" element={<Account />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="descendants" element={<Descendants />}>
              <Route path="dashboard" element={<DescendantsDashboard />} />
              <Route
                path="personal-group/:root"
                element={<DescendantPersonalGroup />}
              />
              <Route path="profile/:id" element={<DescendantProfile />} />
              <Route
                path="profile/asc/:id"
                element={<DescendantAscendentProfile />}
              />
            </Route>
            <Route path="performance/:listType" element={<Performance />}>
              <Route index element={<PersonalSalesRegisters />} />
              <Route path="register" element={<PersonalSalesRegisters />} />
              <Route
                path="period/:currentPeriod"
                element={<PersonalSalesHistory />}
              />
              <Route
                path="detailsSales/:id/:status"
                element={<PersonalSalesDetails />}
              />
            </Route>
            <Route
              path="otherUserSales/:consultantId/:listType"
              element={<OtherUserPersonalSales />}
            ></Route>
            <Route
              path="detailsSales/:id/:status"
              element={<PersonalSalesDetails />}
            />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

function MobBrowse() {
  return (
    <div
      style={{ height: "100vh" }}
      className=" position-relative d-flex align-items-center flex-column mobileBackground w-100 justify-content-evenly overflow-hidden "
    >
      <div
        style={{ zIndex: -2 }}
        className=" mobileBackground position-absolute h-100 w-100 "
      ></div>
      <img
        style={{ top: 70, right: -110, zIndex: 0 }}
        className=" position-absolute  "
        src={leaf}
        alt=""
      />
      <img
        style={{ bottom: -50, left: -60, zIndex: 0 }}
        className=" position-absolute  "
        src={symbols}
        alt=""
      />
      <div className=" position-relative ">
        <p
          style={{ width: "290px" }}
          className=" text-center text-white  museo-sans-700  s32 "
        >
          <FormattedMessage
            id="login.title.download.app"
            defaultMessage="Descargá la App Oficina Virtual Just"
          />
        </p>
      </div>
      <div className=" position-relative ">
        <img className="" src={phones}></img>
      </div>
      <div
        style={{ width: "312px" }}
        className=" position-relative text-center text-white  "
      >
        <p className=" museo-sans-500 s16">
          <FormattedMessage
            id="login.info.download.app"
            defaultMessage="Para poder visualizar desde tu celular toda la información sobre tu Carrera Just necesitás descargar la app."
          />
        </p>
      </div>
      <div
        style={{ zIndex: 2 }}
        className=" position-relative pt-3 d-flex flex-column gap-2"
      >
        <div
          className=" bg-black text-white d-flex justify-content-center align-items-center "
          style={{ height: "56px", width: "210px", borderRadius: "13px " }}
        >
          <div className=" position-relative">
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.swissjust.oficinavirtual"
            >
              <img src={google}></img>
            </a>
          </div>
        </div>
        <div
          className=" bg-black text-white d-flex justify-content-center align-items-center "
          style={{
            zIndex: 2,
            height: "56px",
            width: "210px",
            borderRadius: "13px ",
          }}
        >
          <div>
            <a
              target="_blank"
              href="https://apps.apple.com/ar/app/oficina-virtual-just/id1605642708"
            >
              <img src={ios}></img>
            </a>
          </div>
        </div>
        <div
          className=" bg-black text-white d-flex justify-content-center align-items-center "
          style={{ height: "56px", width: "210px", borderRadius: "13px " }}
        >
          <div>
            <a
              target="_blank"
              href="https://appgallery.huawei.com/app/C107554221"
            >
              <img src={huawei}></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  const lang = useSelector((state) => state.common.lang);

  return (
    <IntlProvider messages={lang.folder} defaultLocale="es-AR" locale="es-AR">
      {isMobile ? <MobBrowse /> : <Routering />}
    </IntlProvider>
  );
}

export default App;
