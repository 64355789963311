import { useEffect, useState } from "react";
import ShareCheckEnvKey from "./ShareCheckEnvKey";

export default function ShareFeaturedChildren(params) {
  const [toggler, setToggler] = useState(false);

  const getValueFromSharedKey = async () => {
    const result = await ShareCheckEnvKey(params.keyType);
    
    if (params?.falsePositive) {
      setToggler(!result);
      return;
    }
    setToggler(result);
  };

  useEffect(() => {
    getValueFromSharedKey();
  }, [params.reactivateIf]);

  return (<>{toggler ? params?.children : null}</>);
}
