import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";

export default function EcommerceFormModalAssociate(params) {
  const onClickLink = () => {
    const clickMe = document.getElementById("on-click-associate-link");
    clickMe.click();
  };

  return (
    <div style={{ padding: "44px" }} className="w-100" >
      <div className="d-flex  gap-2 museo-sans-400 pb-2">
        <div
          style={{ top: "2px", width: "24px", height: "24px" }}
          className=" position-relative svg-force-size "
        >
          <HelpIconNoTarget />
        </div>
        <p className="s24 museo-sans-600 ">Cuenta de pago asociada</p>
      </div>
      <div className="pt-1 s14 d-flex flex-column gap-3 museo-sans-400">
        <p>
          Just cuenta con los servicios de Mercado Pago para administrar los
          cobros y pagos por internet. Ellos se ocupan de procesar todas las
          compras que se realizan en tu Tienda. Velan por el cuidado de tu
          información personal y la transparencia en el pago de tus ganancias.
        </p>
        <p>
          <p className=" museo-sans-600">
            ¿Necesitas desvincular tu cuenta de MercadoPago de tu Tienda
            Virtual?
          </p>
          Puedes hacerlo desde la App de MercadoPago. Ingresa a tu perfil {">"}
          Seguridad {">"} Aplicaciones Conectadas o ingresa al siguiente link
          <span onClick={() => onClickLink()}>
            {" "}
            https://www.mercadopago.com.ar/hub-engine/hubs/my-apps
          </span>{" "}
          y haz click en “Quitar permisos” de la Aplicación de Just.
        </p>
        <a
          id="on-click-associate-link"
          className=" position-absolute invisible"
        ></a>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <button
          data-bs-dismiss="modal"
          aria-label="Close"
          style={{ marginTop: "44px" }}
          className=" btnLoginWidth s14 museo-sans-600 skyBg text-white rounded-3 border-0 "
        >
          <FormattedMessage id="buttons.understood" />
        </button>
      </div>
    </div>
  );
}
