
import ShareFeaturedChildren from "../../../common/Share/ShareFeaturedChildren";
import DashboardOffertsNews from "./DashboardOffertsNews";

export default function DashboardOffersContainer({changeModalConsult,changeModalClient, noticeLider, togglerLider, noticeConsultant, noticeClient, offerData, setOffersInfoModal, shareNotice, onOpenCopyModal, controllerModalOfertsToTop, callServicesOffers }) {
    
    return(
        <ShareFeaturedChildren keyType="CMS" >
              <div className="d-flex w-100 gap-3 " >
                <DashboardOffertsNews
                  togglerConsult={changeModalConsult}
                  togglerClient={changeModalClient}
                  noticeLider={noticeLider}
                  togglerLider={togglerLider}
                  noticeConsultant={noticeConsultant}
                  noticeClient={noticeClient}
                  offerData={offerData}
                  setOffersInfoModal={setOffersInfoModal}
                  setShareNoticeInfo={shareNotice}
                  onCopyOpen={onOpenCopyModal}
                  controllerModalOfertsToTop={controllerModalOfertsToTop}
                  callServicesOffers={callServicesOffers}
                />
                <div className="w-100" >

                </div>
                <div className=" w-100" >

                </div>
              </div>

          </ShareFeaturedChildren>
    )
}