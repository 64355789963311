import React, { useState }  from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { fetchEDBDetailsForFormBody } from "../../Framework/redux/EDBFormSideEffect";

import { ReactComponent as SVGPencil } from "../../../common/svgs/pencil-edit-sm.svg";
import { ReactComponent as SVGUpload } from "../../../common/svgs/upload-icon-sm.svg";
import { ReactComponent as SVGUser } from "../../../common/svgs/user-sm.svg";
import { ReactComponent as SVGTrash } from "../../../common/svgs/ic-trash-sm.svg";
import { ReactComponent as SVGShare } from "../../../common/svgs/ic-share-sm.svg";
import { ReactComponent as SVGExternal } from "../../../common/svgs/ic-external-link-md.svg";
import ShareCheckEnvKey from "../../../common/Share/ShareCheckEnvKey";
import ShareFeaturedChildren from "../../../common/Share/ShareFeaturedChildren";

export default function EDBMenu({
  data,
  type,
  onClearInvitee,
  addInvitation,
  onNewInvitee,
}) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [toggler, setToggler] = useState(false);

  const onClick = () => {
    setToggler(!toggler);
  };

  const onEditEDB = async () => {
    const result = await dispatch(fetchEDBDetailsForFormBody(data.id));
    localStorage.setItem("formBody", JSON.stringify(result.payload));
    if (result.payload.edbType == "virtual") {
      history(`/dashboard/edbs/form/type/virtual/${data.id}`);
      return;
    }
    history(`/dashboard/edbs/form/type/present/${data.id}`);
    return;
  };

  return (
    <div className="py-1 position-relative">
      <button
        onClick={onClick}
        className=" rounded bg-white p-0 btnThreeDots skyBorder"
      >
        <i className="bi s24 skyColor bi-three-dots-vertical"></i>
      </button>
      {toggler && (
        <div
          style={{
            borderRadius: "1rem",
            height: "auto",
            width: "239px",
            right: "0px",
            zIndex: "1",
          }}
          className=" d-flex flex-column shadow bg-white position-absolute"
        >
          {[
            {
              hasKeyType: "CREATE_EDB",
              isFalsePositive: false,
              isVisible:
                data?._metadata?.editEdbLink,
              content: (
                <a className="pointer" target="_blank" onClick={onEditEDB}>
                  <div className=" museo-sans-500 s14 edb-details-menu-list p-3">
                    <div style={{ width: "20px" }} className=" svg-force-size">
                      <SVGPencil />
                    </div>
                    <div className=" blackColor ">
                      <FormattedMessage id="edb.details.menudown.label.one" />
                    </div>
                    <div
                      className=" invisible pointer"
                      style={{ justifySelf: "flex-end" }}
                    >
                      <SVGExternal />
                    </div>
                  </div>
                </a>
              ),
            },
            {
              hasKeyType: "CREATE_EDB",
              isFalsePositive: true,
              isVisible:
                data?._metadata?.editEdbLink,
              content: (
                <a target="_blank" href={data?._metadata.editEdbLink}>
                  <div className=" museo-sans-500 s14 edb-details-menu-list p-3">
                    <div>
                      <SVGPencil />
                    </div>
                    <div className=" blackColor ">
                      <FormattedMessage id="edb.details.menudown.label.one" />
                    </div>
                    <div
                      className=" pointer"
                      style={{ justifySelf: "flex-end" }}
                    >
                      <SVGExternal />
                    </div>
                  </div>
                </a>
              ),
            },
            {
              hasKeyType: "CREATE_EDB",
              isFalsePositive: false,
              isVisible: data?._metadata.inviteEdbLink,
              content: (
                <a className=" pointer" onClick={onNewInvitee}>
                  <div className=" museo-sans-500 s14 edb-details-menu-list p-3">
                    <div
                      style={{ width: "20px" }}
                      className=" svg-force-size position-relative skyColor s16"
                    >
                      <SVGUser />
                      <div
                        style={{ width: "9px", top: 6.5, right: 0 }}
                        className=" svg-force-size position-absolute"
                      > 
                        <svg
                          border="2px white solid"
                          width="10"
                          height="11"
                          viewBox="0 0 10 11"
                          fill="#5AAFF1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.73682 4.10571V6.95971M6.16381 5.53271H3.30981M8.71581 5.53271C8.71581 7.73025 6.93435 9.51171 4.73681 9.51171C2.53927 9.51171 0.757812 7.73025 0.757812 5.53271C0.757812 3.33517 2.53927 1.55371 4.73681 1.55371C6.93435 1.55371 8.71581 3.33517 8.71581 5.53271Z"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                    </div>

                    <div className=" blackColor ">
                      <FormattedMessage id="edb.details.menudown.label.two" />
                    </div>
                    <div
                      className=" invisible pointer"
                      style={{ justifySelf: "flex-end" }}
                    >
                      <SVGExternal />
                    </div>
                  </div>
                </a>
              ),
            },
            {
              hasKeyType: "CREATE_EDB",
              isFalsePositive: true,
              isVisible: data?.shareText,
              content: (
                <a className=" pointer" onClick={addInvitation}>
                  <div className=" museo-sans-500 s14 edb-details-menu-list align-items-center p-3">
                    <div
                      style={{ width: "20px" }}
                      className=" svg-force-size position-relative skyColor s16"
                    >
                      <SVGShare />
                    </div>

                    <p className=" text-capitalize blackColor ">
                      <FormattedMessage id="invite.assitant" />
                    </p>
                    <div
                      className=" invisible pointer"
                      style={{ justifySelf: "flex-end" }}
                    >
                      <SVGExternal />
                    </div>
                  </div>
                </a>
              ),
            },
            {
              hasKeyType: "CREATE_EDB",
              isFalsePositive: true,
              isVisible: data?._metadata.inviteEdbLink,
              content: (
                <a target="_blank" href={data?._metadata.inviteEdbLink}>
                  <div className=" museo-sans-500 s14 edb-details-menu-list p-3">
                    <div className=" skyColor s16">
                      <SVGUser />
                    </div>
                    <div className=" blackColor ">
                      <FormattedMessage id="edb.details.menudown.label.two" />
                    </div>
                    <div
                      className=" pointer"
                      style={{ justifySelf: "flex-end" }}
                    >
                      <SVGExternal />
                    </div>
                  </div>
                </a>
              ),
            },
            {
              isVisible: data?._metadata?.checkInOrder,
              content: (
                <a target="_blank" href={data?._metadata.checkInOrder}>
                  <div className=" museo-sans-500 s14 edb-details-menu-list p-3">
                    <div style={{ width: "20px" }} className=" svg-force-size">
                      <SVGUpload />
                    </div>
                    <div className=" blackColor ">
                      <FormattedMessage id="edb.details.menudown.label.three" />
                    </div>
                    <div
                      className=" pointer"
                      style={{ justifySelf: "flex-end" }}
                    >
                      <SVGExternal />
                    </div>
                  </div>
                </a>
              ),
            },
            {
              hasKeyType: "CREATE_EDB",
              isFalsePositive: false,
              isVisible: data?._metadata?.deleteEdbLink,
              content: (
                <a
                  onClick={onClearInvitee}
                  data-bs-toggle="modal"
                  data-bs-target="#modalDelete"
                  target="_blank"
                >
                  <div className=" pointer museo-sans-500 s14 edb-details-menu-list p-3">
                    <div>
                      <SVGTrash />
                    </div>
                    <div className=" blackColor ">
                      <FormattedMessage id="edb.details.menudown.label.four" />
                    </div>
                    <div
                      className=" invisible pointer"
                      style={{ justifySelf: "flex-end" }}
                    >
                      <SVGExternal />
                    </div>
                  </div>
                </a>
              ),
            },
            {
              hasKeyType: "CREATE_EDB",
              isFalsePositive: true,
              isVisible: data?._metadata?.deleteEdbLink,
              content: (
                <a target="_blank" href={data?._metadata.deleteEdbLink}>
                  <div className=" museo-sans-500 s14 edb-details-menu-list p-3">
                    <div>
                      <SVGTrash />
                    </div>
                    <div className=" blackColor ">
                      <FormattedMessage id="edb.details.menudown.label.four" />
                    </div>
                    <div
                      className=" pointer"
                      style={{ justifySelf: "flex-end" }}
                    >
                      <SVGExternal />
                    </div>
                  </div>
                </a>
              ),
            },
          ]
            .filter((button) => button.isVisible)
            .map((button, index, array) => (
              <>
              {
                button?.hasKeyType ?
              <ShareFeaturedChildren keyType={button?.hasKeyType} falsePositive={button?.isFalsePositive} >
                <React.Fragment key={index}>
                  {" "}
                  {button.content}{" "}
                  {index < array.length - 1 && <hr className="m-0" />}{" "}
                </React.Fragment>

                </ShareFeaturedChildren>
                :
                <React.Fragment key={index}>
                  {" "}
                  {button.content}{" "}
                  {index < array.length - 1 && <hr className="m-0" />}{" "}
                </React.Fragment>

              }
              
              </>
            ))}
        </div>
      )}
    </div>
  );
}
