import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { formatAmount } from "../../../common/Share/FormatCurrency";

export default function DescendantProfileSales({ bills, loading, data }) {
  const [toggler, setToggler] = useState(false);
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  const currentWeek = useSelector((state) => state.headerData.currentWeek);


  const onClickSales = () => {
    setToggler(false);
  };

  const onClickStatus = () => {
    setToggler(true);
  };

  const handleKnownStates = (state) => {
    switch (state) {
      case "notSent":
        return "Sin enviar";
      case "toInvoice":
        return "A facturar";
    }
  };

  const selected =
    "bg-white pointer h-100 w-100 museo-sans-500 s14 blackColor d-flex justify-content-center align-items-center text-capitalize  ";

  const unselected =
    "lightGreyBg pointer h-100 w-100 museo-sans-500 s14 grayColor d-flex justify-content-center align-items-center text-capitalize ";

  return !loading ? (
    <div className=" cards-shadow rounded-3 ">
      <div
        style={{ height: "38px" }}
        className="  d-flex justify-content-between w-100 "
      >
        <div
          onClick={onClickSales}
          style={{ borderRadius: "16px 16px 0px 0px" }}
          className={` ${toggler ? unselected : selected}`}
        >
          <FormattedMessage id="group.incorporations.descendant.switch.week.sales" />
        </div>
        <div
          onClick={onClickStatus}
          style={{ borderRadius: "16px 16px 0px 0px" }}
          className={` ${!toggler ? unselected : selected}`}
        >
          <FormattedMessage id="group.incorporations.descendant.switch.request.status" />
        </div>
      </div>
      <div
        className=" d-flex flex-column gap-4 p-4 rounded-3 bg-white "
      >
        {!toggler && (
          <>
            {bills?.weeks?.map((week) => {
              return (
                <>
                  {week.week > currentWeek ? (
                    <div className=" grayColor s14 d-flex justify-content-between w-100">
                      <p className=" museo-sans-500">
                        <FormattedMessage id="header.week" /> {week?.week}
                      </p>
                      <p className=" museo-sans-500 s14 ">
                        <FormattedMessage id="performance.week.detail.current.unavailable" />
                      </p>
                    </div>
                  ) : (
                    <div className=" s14 d-flex justify-content-between w-100">
                      <p className=" museo-sans-500">
                        <FormattedMessage id="header.week" /> {week?.week}
                      </p>
                      <p className=" dm-sans-medium ">
                        {formatAmount(
                          week?.amount,
                          cultureInfo?.languageCode,
                          cultureInfo?.defaultCurrencyCode
                        )}
                      </p>
                    </div>
                  )}
                </>
              );
            })}
          </>
        )}
        {toggler && (
          <>
            {data.rootSummary.balanceSummary.orderStatusAmounts.map(
              (element) => {
                return (
                  <div className=" justify-content-between s14 d-flex">
                    <p className=" museo-sans-500">
                      {handleKnownStates(element.state)}
                    </p>
                    <p className=" dm-sans-medium ">
                      {formatAmount(
                        element?.amount,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}
                    </p>
                  </div>
                );
              }
            )}
          </>
        )}
      </div>
    </div>
  ) : (
    <SkeletonDescProfileProfileSale />
  );
}

export function SkeletonDescProfileProfileSale(params) {
  const selected =
    "bg-white pointer h-100 w-100 museo-sans-500 s14 blackColor d-flex justify-content-center align-items-center text-capitalize  ";

  const unselected =
    "lightui1-shimmer lightGreyBg pointer h-100 w-100 museo-sans-500 s14 grayColor d-flex justify-content-center align-items-center text-capitalize ";

  return (
    <div className=" cards-shadow rounded-3 ">
      <div
        style={{ height: "38px" }}
        className="  d-flex justify-content-between w-100 "
      >
        <div style={{ borderRadius: "16px 16px 0px 0px" }} className={selected}>
          <div
            style={{ height: "12px" }}
            className="lightui1-shimmer w-50 rounded-3 brightGrayBg"
          ></div>
        </div>
        <div
          style={{ borderRadius: "16px 16px 0px 0px" }}
          className={unselected}
        >
          <div
            style={{ height: "12px" }}
            className="lightui1-shimmer w-50 rounded-3 brightGrayBg"
          ></div>
        </div>
      </div>
      <div
        style={{ height: "210px" }}
        className=" d-flex flex-column gap-4 p-4 rounded-3 bg-white "
      >
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
      </div>
    </div>
  );
}

export function SkeletonRow(params) {
  return (
    <>
      <div className="d-flex w-100 justify-content-between">
        <div
          style={{ height: "12px" }}
          className="lightui1-shimmer w-50 rounded-3 brightGrayBg"
        ></div>
        <div
          style={{ height: "12px" }}
          className="lightui1-shimmer invisible w-50 rounded-3 brightGrayBg"
        ></div>
        <div
          style={{ height: "12px" }}
          className="lightui1-shimmer invisible w-50 rounded-3 brightGrayBg"
        ></div>
        <div
          style={{ height: "12px" }}
          className="lightui1-shimmer w-50 rounded-3 brightGrayBg"
        ></div>
      </div>
    </>
  );
}
