import { FormattedMessage } from "react-intl";

import { ReactComponent as RadiusActive } from "../svgs/radio/radioActive.svg";
import { ReactComponent as RadiusDefault } from "../svgs/radio/radioDefault.svg";
import { ReactComponent as RadiusDisable } from "../svgs/radio/radioDisable.svg";

export function ShareRadio(params) {
  const onVoid = () => {
    return "";
  };

  return (
    <>
      {params?.arrayRadios?.map((radio, index) => {
        return (
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer", gap: params?.gap || "0.5rem" }}
            onClick={radio?.disabled ? onVoid : radio?.onClick}
          >
            <div className="position-relative" style={{width: params?.radioSize, height: params?.radioSize, top: params?.topAdjustment}} >
              {radio?.disabled ? (
                <RadiusDisable />
              ) : radio?.active ? (
                <RadiusActive />
              ) : (
                <RadiusDefault />
              )}
            </div>
            <div
              className={` ${radio?.disabled ? " grayColor" : "blackColor"} `}
            >
              <FormattedMessage id={radio?.labelId} />
            </div>
          </div>
        );
      })}
    </>
  );
}
