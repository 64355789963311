import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import ShareCenterControlPage from "../../../common/Share/ShareCenterControlPage";
import ShareComponenteVisagra from "../../../common/Share/ShareComponenteVisagra";
import { fetchPersonalSalesBond } from "../../Framework/redux/GoodPracticeSideEffect";
import GoodPracticesListBondRow from "../components/GoodPracticesListBondRow";

import { ReactComponent as SVGChevArrowDown } from "../../../common/svgs/ic-chevron-down-md.svg";
import { ReactComponent as SVGChevArrowUp } from "../../../common/svgs/ic-chevron-up-md.svg";
import { ReactComponent as SVGPerson } from "../../Icons/People/People.svg";

export default function GoodPracticesListBond(params) {
  const dispatch = useDispatch();
  const extraRef = useRef();

  const [loader, setLoader] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [loading, setLoading] = useState(true);
  const [returnedEmpty, setReturnedEmpty] = useState(false);
  const [onlyOnce, setOnlyOnce] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [servParams, setServParams] = useState({
    filterType: "salesBondEarned",
    offset: 0,
    billedOrder: "billed.desc",
  });
  const [resultedList, setResultedList] = useState([]);

  const onStartUp = async (byRequest) => {
    setLoading(true);
    const result = await dispatch(
      fetchPersonalSalesBond({
        filterType: byRequest?.filterType ?? servParams?.filterType,
        offset: byRequest?.offset ?? servParams?.offset,
        billedOrder: byRequest?.billedOrder ?? servParams?.billedOrder,
      })
    );
    setResultedList(result?.payload?.personalGroup || []);
    setReturnedEmpty(false);
    setLoading(false);
    setIsVisible(true);
  };

  const onTogglerChange = (value) => {
    setToggler(value);
  };

  const onCallExtras = async () => {
    setLoader(true);
    setServParams({
      filterType: servParams?.filterType,
      offset: servParams?.offset + 20,
      billedOrder: servParams?.billedOrder,
    });
    const result = await dispatch(
      fetchPersonalSalesBond({
        filterType: servParams?.filterType,
        offset: servParams?.offset + 20,
        billedOrder: servParams?.billedOrder,
      })
    );

    if (result?.payload?.personalGroup?.length === 0) {
      setReturnedEmpty(true);
    }

    let prevList = [...resultedList];
    result?.payload?.personalGroup?.forEach((element) => {
      prevList.push(element);
    });
    setResultedList(prevList);
    setLoader(false);
  };

  const togglerOrderBy = (billedOrder) => {
    setServParams({
      filterType: servParams?.filterType,
      offset: 0,
      billedOrder: billedOrder,
    });
    onStartUp({ filterType: servParams?.filterType, offset: 0, billedOrder });
  };

  const onTogglerType = (type) => {
    setServParams({
      filterType: type ? "salesBondEarned" : "salesBondFailed",
      offset: 0,
      billedOrder: servParams?.billedOrder,
    });
    onTogglerChange(false);
    onStartUp({
      filterType: type ? "salesBondEarned" : "salesBondFailed",
      offset: 0,
    });
  };

  useEffect(() => {
    onStartUp();
  }, []);

  useEffect(() => {
    if (extraRef.current) {
      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        setIsVisible(entry.isIntersecting);
      });
      observer.observe(extraRef.current);
    }

    if (isVisible && !onlyOnce) {
      onCallExtras();
    }

    setTimeout(() => {
      setOnlyOnce(false);
    }, 1000);
  }, [isVisible, extraRef.current]);

  const JSXWarningRow = (
    <div
      className="d-flex justify-content-between bgWarningHelpMessage align-items-center rounded-2 mt-4 mb-5  p-3 gap-2"
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21.744 18.1516L13.5386 3.90967C13.3787 3.63211 13.153 3.40251 12.8833 3.24313C12.6137 3.08375 12.3092 3 11.9996 3C11.6899 3 11.3854 3.08375 11.1158 3.24313C10.8461 3.40251 10.6204 3.63211 10.4605 3.90967L2.25602 18.1516C2.09252 18.4369 2.00423 18.7624 2.00015 19.095C1.99607 19.4276 2.07635 19.7554 2.2328 20.045C2.38925 20.3345 2.61628 20.5755 2.89073 20.7432C3.16518 20.911 3.47723 20.9996 3.79507 21H20.2049C20.5228 20.9996 20.8348 20.911 21.1093 20.7432C21.3837 20.5755 21.6107 20.3345 21.7672 20.045C21.9237 19.7554 22.0039 19.4276 21.9999 19.095C21.9958 18.7624 21.9075 18.4369 21.744 18.1516ZM20.636 19.3773C20.5928 19.4571 20.5301 19.5235 20.4544 19.5697C20.3787 19.6159 20.2926 19.6402 20.2049 19.6401H3.79507C3.70741 19.6403 3.62128 19.6161 3.54551 19.5699C3.46975 19.5238 3.40708 19.4573 3.36392 19.3775C3.32076 19.2976 3.29868 19.2072 3.29992 19.1155C3.30117 19.0237 3.32571 18.934 3.37102 18.8554L11.5764 4.6135C11.6203 4.53701 11.6824 4.47372 11.7567 4.42978C11.8309 4.38584 11.9147 4.36275 12 4.36275C12.0853 4.36275 12.1691 4.38584 12.2433 4.42978C12.3176 4.47372 12.3797 4.53701 12.4236 4.6135L20.629 18.8554C20.6742 18.934 20.6986 19.0237 20.6999 19.1154C20.7011 19.2071 20.6791 19.2975 20.636 19.3773Z"
            fill="#171A22"
          />
          <path
            d="M12.0056 8.6086C11.5104 8.6086 11.1276 8.88425 11.1276 9.38042C11.1276 10.8855 11.2971 13.0475 11.2971 14.5525C11.2971 14.9449 11.6237 15.1084 12.01 15.1084C12.2997 15.1084 12.7124 14.944 12.7124 14.5525C12.7124 13.0475 12.8818 10.8855 12.8818 9.38042C12.8773 9.26778 12.8505 9.15733 12.8031 9.05612C12.7556 8.95491 12.6885 8.86515 12.6061 8.79255C12.5237 8.71995 12.4278 8.6661 12.3244 8.63443C12.221 8.60276 12.1124 8.59397 12.0056 8.6086Z"
            fill="#171A22"
          />
          <path
            d="M12.0183 15.9812C11.8937 15.9772 11.7697 15.9995 11.6535 16.0466C11.5373 16.0937 11.4313 16.1648 11.3418 16.2556C11.2524 16.3464 11.1812 16.455 11.1327 16.5751C11.0841 16.6953 11.0591 16.8243 11.0591 16.9548C11.0591 17.0852 11.0841 17.2143 11.1327 17.3344C11.1812 17.4545 11.2524 17.5632 11.3418 17.654C11.4313 17.7447 11.5373 17.8158 11.6535 17.8629C11.7697 17.91 11.8937 17.9323 12.0183 17.9282C12.26 17.9205 12.4894 17.8145 12.6578 17.6328C12.8262 17.4511 12.9203 17.2079 12.9203 16.9548C12.9203 16.7016 12.8262 16.4584 12.6578 16.2767C12.4894 16.095 12.26 15.989 12.0183 15.9812Z"
            fill="#171A22"
          />
        </svg>
      </div>
      <p className=" w-100 museo-sans-500 s12">
        <FormattedMessage id="bond.warning.message" />
      </p>
    </div>
  );

  return (
    <ShareCenterControlPage pageBackground={"white"}>
      <div className="d-flex justify-content-between pt-4 mt-2 ">
        <div className="" style={{ width: "400px" }}>
          <ShareComponenteVisagra
            idMessageTwo={"bond.not.reached"}
            idMessageOne={"bond.reached"}
            toggler={servParams?.filterType == "salesBondEarned"}
            onTogglerType={onTogglerType}
          />
        </div>
        <GoodPracticeListBondOrderBy
          setToggler={onTogglerChange}
          toggler={toggler}
          billedOrder={servParams?.billedOrder}
          togglerOrderBy={togglerOrderBy}
        />
      </div>
      <div
        style={{ letterSpacing: "0.24px" }}
        className=" d-flex justify-content-between w-100 museo-sans-500 s12 grayColor text-uppercase  mt-4 pt-2 "
      >
        <p>
          <FormattedMessage id="edb.details.list.name" />
        </p>
        <p className=" mx-4">
          <FormattedMessage id="bond.total.billed" />
        </p>
      </div>

      {loading ? (
        <GoodPracticeListBondSkeleton />
      ) : (
        <>
          {resultedList && resultedList.length > 0 ? (
            <>
              {JSXWarningRow}
              <div className="d-flex flex-column gap-2 mt-5">
                <>
                  {resultedList?.map((item, index) => {
                    return (
                      <>
                        <GoodPracticesListBondRow key={index} data={item} />
                        {index != resultedList?.length - 1 && <hr />}
                      </>
                    );
                  })}
                  <div className="">
                    {!returnedEmpty && (
                      <div
                        ref={extraRef}
                        className="mt-4"
                        style={{ height: "30px" }}
                      >
                        {isVisible ? (
                          loader ? (
                            <div
                              style={{ top: "-10px" }}
                              className=" position-relative d-flex flex-column justify-content-center align-items-center"
                            >
                              <div
                                className="spinner-border blueColor"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </>
              </div>
            </>
          ) : (
            <GoodPracticeListEmpty
              toggler={servParams?.filterType == "salesBondEarned"}
            />
          )}
          <div className=" mt-4"></div>
        </>
      )}
    </ShareCenterControlPage>
  );
}

export function GoodPracticeListEmpty(params) {
  return (
    <div
      style={{ height: "332px" }}
      className="w-100 d-flex justify-content-center align-items-center mt-5"
    >
      <div className="d-flex flex-column align-items-center gap-3">
        <div
          style={{ height: "104px", width: "104px" }}
          className=" d-flex justify-content-center align-items-center donutHeaderBg rounded-circle "
        >
          <SVGPerson />
        </div>
        {!params?.toggler ? (
          <div
            style={{ width: "255px" }}
            className="text-center s24 museo-sans-700"
          >
            <p>
              <FormattedMessage id="bond.list.empty.p.one" />
            </p>
            <p>
              <FormattedMessage id="bond.list.empty.p.two" />
            </p>
          </div>
        ) : (
          <div
            style={{ width: "255px" }}
            className=" d-flex gap-3 flex-column text-center s24 museo-sans-700"
          >
            <p className="s20 museo-sans-700">
              <FormattedMessage id="bond.list.empty.p.three" />
            </p>
            <p className=" s14 museo-sans-500">
            <FormattedMessage id="bond.list.empty.p.four" />
            </p>
          </div>
        )}
        <div></div>
      </div>
    </div>
  );
}

export function GoodPracticeListBondOrderBy(params) {
  const JSXActiveRadio = (
    <div
      class="rounded-circle border border-2 skyBorder bronceBg d-flex justify-content-center align-items-center"
      style={{ height: "22px", width: "22px" }}
    >
      <div
        class="rounded-circle skyBg"
        style={{ height: "12px", width: "12px" }}
      ></div>
    </div>
  );

  const JSXDeactiveRadio = (
    <div
      class="rounded-circle border border-2 brightGrayBorder d-flex justify-content-center align-items-center"
      style={{
        height: "22px",
        width: "22px",
        background: "rgba(221, 221, 221, 0.2)",
      }}
    ></div>
  );

  const JSXDisableRadio = (
    <div
      class="rounded-circle border border-2 brightGrayBorder d-flex justify-content-center align-items-center"
      style={{
        height: "22px",
        width: "22px",
        background: "rgba(221, 221, 221, 0.2)",
      }}
    ></div>
  );

  const onVoid = () => {
    return "";
  };

  return (
    <div>
      <div
        style={{ width: "184px" }}
        className=" pointer position-relative  h-100 d-flex s14 justify-content-center museo-sans-700 skyColor bg-transparent skyBorder align-items-center rounded"
      >
        <div
          onClick={() => params?.setToggler(!params?.toggler)}
          className="d-flex"
        >
          <p>
            <FormattedMessage id="bond.order" />
          </p>
          {params?.toggler ? <SVGChevArrowUp /> : <SVGChevArrowDown />}
        </div>
        {params?.toggler && (
          <div
            style={{
              top: "46px",
              left: "-73px",
              height: "96px",
              width: "256px",
              boxShadow: "10px 10px 25px 0px rgba(164, 164, 215, 0.20)",
            }}
            className="position-absolute d-flex flex-column blackColor museo-sans-500 justify-content-between rounded-3 bg-white py-2"
          >
            <div
              onClick={
                params?.billedOrder == "billed.asc"
                  ? onVoid
                  : () => params?.togglerOrderBy("billed.asc")
              }
              className=" d-flex gap-2 py-1 px-3 "
            >
              {params?.billedOrder == "billed.asc"
                ? JSXActiveRadio
                : JSXDeactiveRadio}
              <FormattedMessage id="bond.amount.asc" />
            </div>
            <div
              onClick={
                params?.billedOrder != "billed.asc"
                  ? onVoid
                  : () => params?.togglerOrderBy("billed.desc")
              }
              className=" d-flex gap-2 py-1 px-3 "
            >
              {params?.billedOrder != "billed.asc"
                ? JSXActiveRadio
                : JSXDeactiveRadio}
              <FormattedMessage id="bond.amount.desc" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function GoodPracticeListBondSkeleton(params) {
  const JSXSkeletonRow = (
    <>
      <div className="d-flex justify-content-between w-100">
        <div className="d-flex gap-2">
          <div
            style={{ width: "45px", height: "45px" }}
            className="rounded-circle align-items-center grayColor brightGrayBg d-flex gap-0 justify-content-center text-center text-uppercase lightui1-shimmer "
          ></div>
          <div className="d-flex flex-column gap-2">
            <div
              style={{ width: "200px", height: "14px" }}
              className=" brightGrayBg rounded-2  lightui1-shimmer "
            ></div>
            <div
              style={{ width: "100px", height: "8px" }}
              className=" brightGrayBg rounded-2  lightui1-shimmer "
            ></div>
          </div>
        </div>
        <div>
          <div
            style={{ width: "150px", height: "14px" }}
            className=" brightGrayBg rounded-2  lightui1-shimmer "
          ></div>
        </div>
      </div>
      <hr />
    </>
  );

  return (
    <>
      {JSXSkeletonRow}
      {JSXSkeletonRow}
      {JSXSkeletonRow}
      {JSXSkeletonRow}
      {JSXSkeletonRow}
      {JSXSkeletonRow}
      {JSXSkeletonRow}
      {JSXSkeletonRow}
      {JSXSkeletonRow}
      {JSXSkeletonRow}
      {JSXSkeletonRow}
      {JSXSkeletonRow}
    </>
  );
}
