import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";

export default function EcommerceFormInput(params) {
  return (
    <div className="d-flex flex-column gap-2 s14 museo-sans-600 w-100">
      <div className="d-flex gap-1">
        <label htmlFor={params?.id} className="">
          {params?.label}
        </label>
        <div
          style={{ width: "16px", height: "16px", top: "-2px" }}
          className=" position-relative svg-force-size"
        >
          <HelpIconNoTarget />
        </div>
      </div>
      <input
        type={params?.type}
        className=" input-border rounded-3 px-3 py-2 museo-sans-400 "
        id={params?.id}
        placeholder={params?.placeholder ?? "Ej.: Placeholder"}
        onChange={(evt) => params?.onChange(params?.label, evt.target.value)}
        value={params?.value}
      />
    </div>
  );
}
