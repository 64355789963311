import DataField from './DataField'

import { useMediaPredicate } from 'react-media-hook'

import {ReactComponent as EditLogoSmallSVG} from '../../../svgs/pencil-edit-sm.svg'
import { FormattedMessage } from 'react-intl'
import ImageCircleHandler from '../../../Share/ImageCircleHandler'
import ReformatLevelByGender from '../../../Share/ReformatLevelByGender'

export default function UserDataMainPage({userData, travelFunction}) {

    const biggerThan992 = useMediaPredicate("(min-width: 992px)");

  return (

    <>
    
        <div className="position-relative picture-and-icon-container">
        <ImageCircleHandler width={"72px"} height={"72px"} url={userData.image} name={userData?.name} firstName={userData?.firstName} lastName={userData?.lastName} top={0} />
          <div
            id='configuration-button-start-profile-change'
            onClick={() => travelFunction(2.0)}
            className="hover-pointer rounded-circle position-absolute skyBg bronceFill d-flex justify-content-center align-items-center picture-change-icon"
          >
            <EditLogoSmallSVG />
          </div>
        </div>
        <div>
          <p className="fs-5 mt-2 mb-0 museo-sans-700 s20">{userData.name}</p>
          <p className="m-0 mb-4 museo-sans-500 s12 grayColor">
          <ReformatLevelByGender level={userData.level} gender={userData.gender} roleDescriptionDefault={userData?.roleDescription}  />
          </p>
        </div>
        <div
          className={`${
            biggerThan992 ? "w-50" : "w-100"
          } d-flex flex-column justify-content-evenly`}
        >
          <DataField
            fieldLabel={<FormattedMessage id="login.label.two" defaultMessage="Contraseña"/>}
            fieldContent={"****************"}
            clickFunction={travelFunction}
            noWhere={"settings"}
            whereTo={3.0}
          />
          <DataField
            fieldLabel={<FormattedMessage id="settings.user.data.email" defaultMessage="Email"/>}
            fieldContent={userData.email}
            clickFunction={travelFunction}
            noWhere={"settings"}
            whereTo={1.2}
          />
          <DataField
            fieldLabel={<FormattedMessage id="settings.user.data.phone" defaultMessage="Número de telefono"/>}
            fieldContent={userData.phone}
            clickFunction={travelFunction}
            noWhere={"settings"}
            whereTo={1.4}
          />
          <DataField
            fieldLabel={<FormattedMessage id="settings.user.data.address" defaultMessage="Dirección"/>}
            fieldContent={userData.address}
            clickFunction={travelFunction}
            noWhere={"nowhere"}
            whereTo={1.7}
          />
        </div>
      </>

  )
}
