import moment from "moment-timezone";
import Radius from "../../../common/Share/Radius";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { ReactComponent as SVGCross } from "../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGJustCoins } from "../img/ic-just-coins.svg";
import { ReactComponent as SVGCuenta } from "../img/ic-estado-de-cuenta.svg";
import { ReactComponent as SVGChevArrowDown } from "../../../common/svgs/ic-chevron-down-md.svg";
import ShareFeaturedChildren from "../../../common/Share/ShareFeaturedChildren";

export default function StatusAccountFilterModal({
  filtersSetter,
  calendarData,
  toggleJustCoins,
  toggleAccount,
  clearAllFilters,
  desc,
}) {
  const [filtering, setFiltering] = useState(1);
  const [date, setDate] = useState({ origin: "11-11-1999", target: "" });
  const [periods, setPeriods] = useState({ origin: "", target: "" });
  const [toggler, setToggler] = useState(false);
  const [togglePeriodOrigin, setTogglePeriodOrigin] = useState(false);
  const [togglePeriodTarget, setTogglePeriodTarget] = useState(false);
  const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

  const togglerSetAccount = () => {
    setToggler(false);
    toggleAccount();
  };
  const togglerSetJustCoin = () => {
    setToggler(true);
    toggleJustCoins();
  };

  const renderCurrentYear = () => moment().format("YYYY");

  const changeDateOrigin = (evt) =>
    setDate({ ...date, origin: evt.target.value });
  const changeDateTarget = (evt) =>
    setDate({ ...date, target: evt.target.value });
  const changePeriodOrigin = (elm) => {
    setPeriods({ ...periods, origin: elm });
    setTogglePeriodOrigin(!togglePeriodOrigin);
  };
  const changePeriodTarget = (elm) => {
    setPeriods({ ...periods, target: elm });
    setTogglePeriodTarget(!togglePeriodTarget);
  };

  const toggleElm = (elmToToggle, valueToToggle) => {
    if (elmToToggle == "target") {
      setTogglePeriodTarget(valueToToggle);
    }
    if (elmToToggle == "origin") {
      setTogglePeriodOrigin(valueToToggle);
    }
  };

  const clearFilters = () => {
    setFiltering(1);
    let nextMonth = moment().add(0, "M").format("YYYY-MM-DD");
    let lastMonth = moment().add(-1, "M").format("YYYY-MM-DD");

    setDate({ origin: `${lastMonth}`, target: `${nextMonth}` });
    setPeriods({
      origin: `${
        calendarData?.currentPeriod == 1 ? 1 : calendarData?.currentPeriod - 1
      }`,
      target: `${
        calendarData?.currentPeriod == 13 ? 13 : calendarData?.currentPeriod
      }`,
    });
    clearAllFilters();
    filterByValue("clear");
    setToggler(false);
    const target = document.getElementById("closeThyModal");
    target.click();
  };

  const filterByValue = (arg) => {
    if (arg !== "clear") {
      switch (filtering) {
        case 0:
          filtersSetter({
            content: {
              filterType: "lastWeek",
              accountType: toggler ? "justCoins" : "currentAccount",
            },
            number: 0,
          });
          return;
        case 1:
          filtersSetter({
            content: {
              filterType: "lastPeriod",
              accountType: toggler ? "justCoins" : "currentAccount",
            },
            number: 1,
          });
          return;
        case 2:
          filtersSetter({
            content: {
              filterType: "lastYear",
              accountType: toggler ? "justCoins" : "currentAccount",
            },
            number: 2,
          });
          return;
        case 3:
          filtersSetter({
            content: {
              filterType: "dateFilter",
              accountType: toggler ? "justCoins" : "currentAccount",
              iniDate: date.origin,
              endDate: date.target,
            },
            number: 3,
          });
          return;
        case 4:
          let current = moment().format("YYYY");
          let splittedYear = current.slice(2, 4);
          let finalOrigin = `${splittedYear}${
            periods.origin < 10 ? `0${periods.origin}` : periods.origin
          }`;
          let finalTarget = `${splittedYear}${
            periods.target < 10 ? `0${periods.target}` : periods.target
          }`;
          filtersSetter({
            content: {
              filterType: "periodFilter",
              accountType: toggler ? "justCoins" : "currentAccount",
              iniPeriod: finalOrigin,
              endPeriod: finalTarget,
            },
            number: 4,
          });
          return;
      }
    } else {
      filtersSetter({
        content: {
          filterType: null,
          accountType: toggler ? "justCoins" : "currentAccount",
        },
        number: 1,
      });
    }

    const target = document.getElementById("closeThyModal");
    target.click();
  };

  const lastPeriodValue = () => {
    const callBackValue = () => {
      if (calendarData?.currentPeriod == 1) {
        return 13;
      }
      if (calendarData?.currentPeriod > 10) {
        return calendarData?.currentPeriod - 1;
      }
      return `0${calendarData?.currentPeriod - 1}`;
    };
    return `(P${callBackValue()})`
  };

  useEffect(() => {
    let nextMonth = moment().add(0, "M").format("YYYY-MM-DD");
    let lastMonth = moment().add(-1, "M").format("YYYY-MM-DD");

    setPeriods({
      origin: `${
        calendarData?.currentPeriod == 1 ? 1 : calendarData?.currentPeriod - 1
      }`,
      target: `${
        calendarData?.currentPeriod == 13 ? 13 : calendarData?.currentPeriod
      }`,
    });
    setDate({ origin: `${lastMonth}`, target: `${nextMonth}` });

    if (desc) {
      const descClickMe = document.getElementById("account-current-button");
      descClickMe.click();
    }
  }, []);

  return (
    <div
      className="modal fade"
      id="modalStatusFilter"
      aria-hidden="true"
      aria-labelledby="modalStatusFilter"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "608px" }}
      >
        <div className="modal-content modal-radius">
          <div
            style={{ height: "660px" }}
            className="s14 modal-body d-flex flex-column justify-content-between p-0"
          >
            <div>
              <div>
                <div className="d-flex justify-content-between p-4 m-3">
                  <div className="d-flex align-items-center">
                    <h5 className="museo-sans-500 px-2 s20 m-0">
                      <FormattedMessage id="edb.filter.button" />
                    </h5>
                  </div>
                  <div
                    id="closeThyModal"
                    className="pointer"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <SVGCross />
                  </div>
                </div>
                <hr className="m-0" />
              </div>
              <div style={{ height: "0px" }} className="invisible">
                <div
                  id="account-current-button"
                  onClick={togglerSetAccount}
                  className={`bronceBg rounded-3 w-100 px-3 pt-1 pb-1 d-flex justify-content-center align-items-center`}
                >
                  <div className="d-flex gap-1 justify-content-center align-items-center">
                    <SVGCuenta />
                    <p>
                      <FormattedMessage id="balance.salary.current" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column align-items-center px-4">
                <ShareFeaturedChildren keyType="JUST_COINS" >

                  <>
                    {
                      !desc && 
                      <div className="s14 w-100 px-3 pt-4">
                    <div className="d-flex museo-sans-700 flex-row justify-content-center align-items-center lightVioletBg p-1 rounded-3 lightBlueColor">
                      <div
                        id="account-current-button"
                        onClick={togglerSetAccount}
                        className={`bronceBg rounded-3 w-100 px-3 pt-1 pb-1 d-flex justify-content-center align-items-center`}
                      >
                        <div className="d-flex gap-1 justify-content-center align-items-center">
                          <SVGCuenta />
                          <p>
                            <FormattedMessage id="balance.salary.current" />
                          </p>
                        </div>
                      </div>
                      <div
                        onClick={togglerSetJustCoin}
                        className={`w-100 hover-pointer rounded-3 ps-4 pe-4 pt-1 pb-1 d-flex justify-content-center align-items-center`}
                      >
                        <div className="d-flex gap-1 justify-content-center align-items-center">
                          <SVGJustCoins />
                          <p>
                            <FormattedMessage id="balance.salary.justCoins" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                    }
                  </>
                  
                  </ShareFeaturedChildren>
                <div className="w-100 museo-sans-500 mt-4 px-4 mx-3">
                  <div className="d-flex flex-column gap-3">
                    <p className="text-uppercase museo-sans-500 s12 grayColor">
                      <FormattedMessage id="date" />
                    </p>
                    <div
                      onClick={() => setFiltering(1)}
                      className="d-flex pointer gap-2"
                    >
                      <Radius status={filtering == 1} />
                      <span
                        className={`${
                          filtering == 1 ? "s14" : "grayColor s14"
                        }`}
                      >
                        <FormattedMessage id="dashboard.prev.earning.period" />{" "}
                        <FormattedMessage id="later" /> {lastPeriodValue()}
                      </span>
                    </div>
                    <div
                      onClick={() => setFiltering(2)}
                      className="d-flex pointer gap-2"
                    >
                      <Radius status={filtering == 2} />
                      <span
                        className={`${
                          filtering == 2 ? "s14" : "grayColor s14"
                        }`}
                      >
                        <FormattedMessage id="year" /> {renderCurrentYear()}
                      </span>
                    </div>
                    <div
                      onClick={() => setFiltering(3)}
                      className="d-flex pointer gap-2"
                    >
                      <Radius status={filtering == 3} />
                      <span
                        className={`${
                          filtering == 3 ? "s14" : "grayColor s14"
                        }`}
                      >
                        <FormattedMessage id="filter.by.date" />
                      </span>
                    </div>
                    {filtering == 3 && (
                      <div className="d-flex gap-3">
                        <div
                          style={{ width: "256px" }}
                          className="position-relative px-0"
                        >
                          <p className="museo-sans-700 s12">
                            <FormattedMessage id="desde" />
                          </p>
                          <div className="no-calendar">
                            <input
                              value={date.origin}
                              onChange={changeDateOrigin}
                              id={"date-start"}
                              data-date-format="DD-MMMM-YYYY"
                              style={{ width: "256px" }}
                              className="pointer mt-1 dm-sans-medium s14 skyBorder p-2 rounded-3"
                              type="date"
                            />
                          </div>
                        </div>
                        <div
                          style={{ width: "256px" }}
                          className="position-relative px-0"
                        >
                          <p className="museo-sans-700 s12">
                            <FormattedMessage id="hasta" />
                          </p>
                          <div className="no-calendar">
                            <input
                              value={date.target}
                              onChange={changeDateTarget}
                              id={"date-end"}
                              data-date-format="DDMMYYYY"
                              style={{ width: "256px" }}
                              className="pointer mt-1 dm-sans-medium s14 skyBorder p-2 rounded-3"
                              type="date"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      onClick={() => setFiltering(4)}
                      className="d-flex pointer gap-2"
                    >
                      <Radius status={filtering == 4} />
                      <span
                        className={`${
                          filtering == 4 ? "s14" : "grayColor s14"
                        }`}
                      >
                        <FormattedMessage id="filter.by.periods" />
                      </span>
                    </div>
                    {filtering == 4 && calendarData && (
                      <div className="d-flex gap-3">
                        <div
                          style={{ width: "256px" }}
                          onClick={() =>
                            toggleElm("origin", !togglePeriodOrigin)
                          }
                          className="pointer position-relative px-0"
                        >
                          {togglePeriodOrigin && (
                            <div
                              style={{
                                height: "150px",
                                top: 60,
                                borderRadius: "16px",
                                zIndex: 5,
                              }}
                              className="w-100 position-absolute bg-white shadow hidden-y-scroll"
                            >
                              {array.map((elm) => {
                                if (elm < periods.target) {
                                  return (
                                    <div
                                      onClick={() => changePeriodOrigin(elm)}
                                      className="py-2 d-flex flex-column gap-2"
                                      key={elm}
                                    >
                                      <p className="px-2">
                                        <FormattedMessage id="dashboard.prev.earning.period" />{" "}
                                        {elm}
                                      </p>
                                      {elm != 13 && <hr className="m-0" />}
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          )}
                          <p className="museo-sans-700 s12">
                            <FormattedMessage id="desde" />
                          </p>
                          <div
                            className="dm-sans-medium no-calendar d-flex gap-1 border-1 justify-content-between blueBorder p-2 rounded-3"
                            style={{ width: "256px" }}
                          >
                            <div className="d-flex gap-1">
                              <p>
                                <FormattedMessage id="select.period" />
                              </p>
                              <div>{periods.origin}</div>
                            </div>
                            <div>
                              <SVGChevArrowDown />
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ width: "256px" }}
                          onClick={() =>
                            toggleElm("target", !togglePeriodTarget)
                          }
                          className="pointer position-relative px-0"
                        >
                          {togglePeriodTarget && (
                            <div
                              style={{
                                height: "150px",
                                top: 60,
                                borderRadius: "16px",
                                zIndex: 5,
                              }}
                              className="w-100 position-absolute bg-white shadow hidden-y-scroll"
                            >
                              {array.map((elm) => {
                                if (elm > periods.origin) {
                                  return (
                                    <div
                                      onClick={() => changePeriodTarget(elm)}
                                      className="py-2 d-flex dm-sans-medium flex-column gap-2"
                                      key={elm}
                                    >
                                      <p className="px-2">
                                        <FormattedMessage id="select.period" />{" "}
                                        {elm}
                                      </p>
                                      {elm != 13 && <hr className="m-0" />}
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          )}
                          <p className="museo-sans-700 s12">
                            <FormattedMessage id="hasta" />
                          </p>
                          <div
                            className="no-calendar d-flex gap-1 border-1 justify-content-between blueBorder p-2 rounded-3"
                            style={{ width: "256px" }}
                          >
                            <div className="dm-sans-medium d-flex gap-1">
                              <p>
                                <FormattedMessage id="select.period" />
                              </p>
                              <div>{periods.target}</div>
                            </div>
                            <div>
                              <SVGChevArrowDown />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="px-5 w-100 my-5 d-flex justify-content-center">
              <div className="d-flex flex-column gap-3">
                <button
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="button"
                  className="btnLoginWidth museo-sans-700 s14 skyBg whiteColor skyBorder button-hover-light-text button-bootstrap-radius button-padding"
                  onClick={filterByValue}
                >
                  <FormattedMessage
                    id="buttons.apply"
                    defaultMessage="Entendido"
                  />
                </button>
                <button
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="button"
                  className="btnLoginWidth museo-sans-700 s14 bg-transparent lightRedColor redBorder button-bootstrap-radius button-padding"
                  onClick={clearFilters}
                >
                  <FormattedMessage id="buttons.remove.filters" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
