import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ReactComponent as SVGPC } from "../../../common/svgs/ic-edb-virtual-sm.svg";
import { ReactComponent as SVGHome } from "../../../common/svgs/ic-edb-presencial-sm.svg";
import { ReactComponent as SVGPadlock } from "../../../common/svgs/ic-padlook-sm.svg";
import { ReactComponent as SVGClock } from "../../../common/svgs/ic-clock-md.svg";
import { ReactComponent as SVGBlackClock } from "../../../common/svgs/ic-clock-black-md.svg";

import { FormattedMessage } from "react-intl";
import { formatAmount } from "../../../common/Share/FormatCurrency";

export default function EDBDetailsCardParams({
  data,
  type,
  loading,
  cultureInfo,
  SVGTriangle,
}) {
  const sortColors = (color) => {
    if (color === "completed") {
      return { color: "#16BE7D" };
    }
    if (color === "warning") {
      return { color: "#DCB632" };
    }
    if (color === "risk") {
      return { color: "#F06E69" };
    }
    if (color === "normal") {
      return;
    }
  };

  const colorStatus = (data) => {
    if (data.toLowerCase() == "confirmed") {
      return { color: "#16BE7D" };
    } else if (data.toLowerCase() == "waiting" || data.toLowerCase() == "dated") {
      return { color: "#DCB632" };
    } else {
      return { color: "#F06E69" };
    }
  };

  const edbTextStatus = (data) => {
    if (data.toLowerCase() === "open") {
      return (
        <p style={colorStatus(data)} className="museo-sans-500">
          {" "}
          <FormattedMessage id="edb.details.params.state.one" />
        </p>
      );
    } else if (data.toLowerCase() === "closed") {
      return (
        <p style={colorStatus(data)} className="museo-sans-500">
          {" "}
          <FormattedMessage id="edb.details.params.state.three" />
        </p>
      );
    } else {
      return (
        <p style={colorStatus(data)} className="museo-sans-500">
          {" "}
          <FormattedMessage id="edb.details.params.state.two" />
        </p>
      );
    }
  };

  return (
    <div
      style={{ borderRadius: "15px" }}
      className="p-3 edb-details--cards f9GrayBorder edb-details-card-shadow"
      id="edb--details-card-params"
    >
      {loading ? (
        <SkeletonEDBDetailsParams />
      ) : (
        <>
          <div className=" p-1 d-flex flex-column">
            <div
              style={{ alignItems: "center" }}
              className="  align-items-start s12 d-flex "
            >
              {type === "fechado" && (
                <>
                  <FontAwesomeIcon
                    style={data && sortColors(data.edbStatus)}
                    icon={faCalendarDays}
                  />
                  <p
                    style={data && sortColors(data.edbStatus)}
                    className=" museo-sans-500 px-2"
                  >
                    <FormattedMessage id="edb.details.params.title.one" />
                  </p>
                </>
              )}
              {type === "abierto" && (
                <>
                  {data?.edbStatus === "warning" ? (
                    <>
                      <div
                        className="svg-force-size  "
                        style={{ width: "16px", height: "16px" }}
                      >
                        <SVGClock />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="svg-force-size  "
                        style={{ width: "16px", height: "16px" }}
                      >
                        <SVGBlackClock />
                      </div>
                    </>
                  )}

                  <p
                    style={data && sortColors(data.edbStatus)}
                    className=" mt-1 text-uppercase museo-sans-500 px-2"
                  >
                    <FormattedMessage id="edb.details.params.title.two" />{" "}
                    {data ? data.daysToClose : "10"}{" "}
                    <FormattedMessage id="edb.details.params.days" />
                  </p>
                </>
              )}
              {type === "cerrado" && (
                <>
                  <SVGPadlock />
                  <p className=" text-uppercase museo-sans-500 px-2">
                    <FormattedMessage id="edb.details.params.title.three" />
                  </p>
                </>
              )}
            </div>
            <div style={data && sortColors(data.edbStatus)} className="mt-2">
              {type === "fechado" && (
                <>
                  <p className=" dm-sans-bold m-0 s34">
                    {data ? data.daysToOpen : "15"}
                  </p>
                  <p className=" text-capitalize museo-sans-500 s16">
                    <FormattedMessage id="edb.details.params.days" />
                  </p>
                </>
              )}
              {type === "abierto" && (
                <>
                  <div className="d-flex align-items-center gap-2">
                    <p
                      style={{
                        ...sortColors(data?.amountStatus),
                        fontSize: "32px",
                      }}
                      className=" s34 text-uppercase dm-sans-bold "
                    >
                      {data && (
                        <>
                          {formatAmount(
                            data.accumulatedAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </>
                      )}
                    </p>
                    {data?.amountStatus === "warning" && (
                      <div>
                        <SVGTriangle />
                      </div>
                    )}
                  </div>
                  <p className=" s16 museo-sans-500 blackColor">
                    <FormattedMessage id="edb.details.params.accumulated.sale" />
                  </p>
                </>
              )}
              {type === "cerrado" && (
                <>
                  <div className="d-flex align-items-center gap-2">
                    <p
                      style={{
                        ...sortColors(data?.amountStatus),
                        fontSize: "32px",
                      }}
                      className=" s34 text-uppercase dm-sans-bold "
                    >
                      {data && (
                        <>
                          {formatAmount(
                            data.accumulatedAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </>
                      )}
                    </p>
                    {data?.amountStatus === "warning" && (
                      <div>
                        <SVGTriangle />
                      </div>
                    )}
                  </div>
                  <p className=" s16 museo-sans-500 blackColor">
                    <FormattedMessage id="edb.details.params.accumulated.sale" />
                  </p>
                </>
              )}
            </div>
          </div>
          <hr className="p-0 m-0"></hr>
          <div className=" p-1 s16 d-flex flex-column">
            {type === "fechado" && (
              <div className=" d-flex my-2 ">
                <span className="museo-sans-500 grayColor">
                  <FormattedMessage id="edb.details.params.host" />
                </span>{" "}
                <p className=" invisible"> a </p>{" "}
                <p className="museo-sans-500">
                  {" "}
                  {data ? data.hostName : "Graciela Márquez"}{" "}
                </p>
              </div>
            )}

            <div className=" d-flex  my-2">
              <span className="museo-sans-500 grayColor">
                <FormattedMessage id="edb.details.params.state" />
              </span>{" "}
              <p className=" invisible"> a </p>{" "}
              {data && edbTextStatus(data.edbState)}
            </div>
            {
              type == "fechado" && (
                <div style={{color: "#795104" }} className="s12 bgWarningHelpMessage museo-sans-500 px-3 py-2 "  >
                <p className="py-1" >
                  Para que tu Encuentro pueda ser confirmado, tiene que tener un anfitrión y 2 asistentes como mínimo.
                </p>
                </div>

              )
            }
            <div style={{ alignItems: "center" }} className=" d-flex my-2 ">
              <span className="museo-sans-500 grayColor">
                <FormattedMessage id="edb.details.params.type" />
              </span>{" "}
              <p className=" invisible"> a </p>{" "}
              {data ? (
                <>
                  <p className="museo-sans-500 text-capitalize">
                    {data.edbType}
                  </p>
                  <div className=" invisible">a</div>

                  {data.edbType === "virtual" && (
                    <div
                      style={{ fill: "black" }}
                      className=" svg-force-color blackColor d-flex "
                    >
                      <SVGPC />
                    </div>
                  )}
                  {data.edbType === "presencial" && (
                    <div
                      style={{ fill: "black" }}
                      className=" svg-force-color blackColor d-flex "
                    >
                      <SVGHome />
                    </div>
                  )}
                </>
              ) : (
                <p className="museo-sans-500 text-capitalize">
                  <FormattedMessage id="edb.filter.label.button.one" />
                </p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export function SkeletonEDBDetailsParams() {
  const JSXRow12 = (
    <div
      style={{ height: "12px" }}
      className="lightui1-shimmer w-75 rounded-3 brightGrayBg"
    ></div>
  );

  return (
    <>
      <div className=" mt-3 d-flex flex-column ">
        <div className=" d-flex flex-column gap-3 ">
          <div
            style={{ height: "8px" }}
            className="lightui1-shimmer w-75 rounded-3 brightGrayBg"
          ></div>
          <div
            style={{ height: "24px" }}
            className="lightui1-shimmer w-50 mt-2 rounded-3 brightGrayBg"
          ></div>
          <div
            style={{ height: "8px" }}
            className="lightui1-shimmer w-25 rounded-3 brightGrayBg"
          ></div>
        </div>
        <hr></hr>
        <div className=" mt-3 d-flex flex-column gap-4 ">
          {JSXRow12}
          {JSXRow12}
          {JSXRow12}
        </div>
      </div>
    </>
  );
}
