import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import EcommerceRegisterForm from "../components/EcommerceRegisterForm";
import EcommerceRegisterPath from "../components/EcommerceRegisterPath";

export default function EcommerceRegister() {

    const [paths, setPaths] = useState({howMany: 1, current: 1});
    const history = useNavigate();

    const progressingOnPath = () => {
        if ((paths.current + 1) > paths.howMany ) {
            history("/dashboard/ecommerce/form");
            return;
        }
        setPaths({howMany: paths?.howMany, current: paths?.current + 1});
    };

    useEffect(() => {
        const country = localStorage.getItem("country");
        const countriesWithStepTwo = ["ar", "co", "uy", "pe"];
        if (countriesWithStepTwo.includes(country)) {
            setPaths({howMany: 2, current: 1})
        }
    }, [])

    return (
        <div style={{marginTop: "40px"}} className="d-flex flex-column gap-4" >
            <EcommerceRegisterPath current={paths?.current} max={paths?.howMany} />
            <EcommerceRegisterForm onProgressingOnPath={progressingOnPath} howMany={paths?.howMany} current={paths?.current} />
        </div>
    );
  }
  