import { useRef, useState, useEffect } from "react";
import { useMediaPredicate } from "react-media-hook";
import { useSelector } from "react-redux";

import "../../../Performance/Presentation/pages/performance.css";
import "../../../common/Header/Presentations/pages/header.css";

import { ReactComponent as TriangleWarningIcon } from "../../../common/svgs/ic-triangle-warning-sm.svg";
import GroupIncorpDescendantUserTooltip from "./GroupIncorpDescendantUserTooltip";
import GroupIncorpDescendantBilledTooltip from "./GroupIncorpDescendantBilledTooltip";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ReformatLevelByGender from "../../../common/Share/ReformatLevelByGender";

export default function GroupSalesWeekRow({ data }) {
  const [headerTogglerBtn, setHeaderTogglerBtn] = useState(false);
  const [billedToggler, setBilledToggler] = useState(false);
  const [currentWeek, setCurrentWeek] = useState(1);
  const [tooltipOffset, setTooltipOffset] = useState(0);
  const containerRef = useRef(null);
  const tooltipRef = useRef(null);
    const smallerThan1280 = useMediaPredicate("(max-width: 1260px)");
    const smallerThan1920 = useMediaPredicate("(max-width: 1900px)");

  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  const headerData = useSelector((state) => state?.headerData);
  const togglePlusModal = (evt) => {
    evt.preventDefault();
    setHeaderTogglerBtn(!headerTogglerBtn);
  };
  const defineCurrentWeek = (weeks) => {

    function truncateTime(date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    let actualDate = new Date();
    actualDate = truncateTime(actualDate);
    if (!weeks?.length) {
      return 4;
    }

    for (let week of weeks) {
      let startDate = new Date(week.startDate);
      let endDate = new Date(week.endDate);

      startDate = truncateTime(startDate);
      endDate = truncateTime(endDate);

      if ((actualDate >= startDate) && (actualDate <= endDate)) {
        return week.id; 
      }
    }
    return 4;
  };
  const defineLength = () => {
    if (smallerThan1280) {
      return 27;
    } else if (!smallerThan1280 && smallerThan1920) {
      return 38;
    } else {
      return 40;
    }
  };
  const splitString = (str) => {
    if (str.length > defineLength()) {
      let aux = str.slice(0, defineLength());
      aux += "...";
      return aux;
    } else {
      return str;
    }
  };
  const ifWarningIsActive = (actual, status) => {
    return (actual < 0 || status === "warning")
  };

  const handleMouseEnter = () => {
    const containerRect = containerRef.current.getBoundingClientRect();
    const tooltipHeight = tooltipRef.current.offsetHeight;
    const containerHeight = containerRect.height;

    const spaceBelow = window.innerHeight - containerRect.bottom;
    const spaceAbove = containerRect.top;

    if (spaceBelow >= tooltipHeight) {
      setTooltipOffset(containerHeight);
    } else if (spaceAbove >= tooltipHeight) {
      setTooltipOffset(-tooltipHeight);
    }
  };

  useEffect(() => {
    setCurrentWeek(defineCurrentWeek(headerData?.period?.periodWeeks));
  }, [headerData?.period?.periodWeeks]);

  return (
      <div className="d-flex s12 museo-sans-500 border-bottom border-1 justify-content-between align-items-center row-height group-sales-row-height">
        <div ref={containerRef} onMouseEnter={handleMouseEnter} className="h-100 d-flex dm-sans-medium s14 blackColor performance-name-week-sales-width position-relative tooltip-container">
          <div className="d-flex align-items-center" onClick={togglePlusModal}>
            <ImageCircleHandler
              width={"32px"}
              url={data?.profileResume?.image}
              name={data?.profileResume?.name}
              size={"10px"}
              firstName={data?.profileResume?.firstName}
              lastName={data?.profileResume?.lastName}
            />

            <div className="d-flex flex-column ms-2">
              <p className="museo-sans-700 s14 blackColor">
                {splitString(
                  data?.profileResume?.lastName +
                    " " +
                    data?.profileResume?.firstName
                )}
              </p>
              <p className="museo-sans-500 s14 grayColor">
                <ReformatLevelByGender
                  gender={data?.profileResume?.gender}
                  level={data?.profileResume?.level}
                  roleDescriptionDefault={data?.profileResume?.roleDescription}
                />
              </p>
            </div>
          </div>
          <GroupIncorpDescendantUserTooltip
            toggler={setHeaderTogglerBtn}
            tooltipRef={tooltipRef}
            top={tooltipOffset}
            userInfo={data}
          />
        </div>

        <div
          className={`dm-sans-medium s14 d-flex justify-content-end performance-week-width ${
            currentWeek >= 1 ? "blackColor" : "brightGrayColor"
          }`}
        >
          {formatAmount(
            data?.weeklySalesAmounts[0]?.amount,
            cultureInfo?.languageCode,
            cultureInfo?.defaultCurrencyCode
          )}
        </div>
        <WeekColumn index={2} cultureInfo={cultureInfo} data={data} currentWeek={currentWeek} />
        <WeekColumn index={3} cultureInfo={cultureInfo} data={data} currentWeek={currentWeek} />
        <WeekColumn index={4} cultureInfo={cultureInfo} data={data} currentWeek={currentWeek} />
        <WeekColumn hideIf={data?.weeklySalesAmounts?.length < 5} index={5} cultureInfo={cultureInfo} data={data} currentWeek={currentWeek} />
        

        <div className="dm-sans-medium s14 button-hover-svg-light-stroke d-flex justify-content-end blackColor performance-billed-width">
          {formatAmount(
            data?.balanceSummary?.billed?.amount,
            cultureInfo?.languageCode,
            cultureInfo?.defaultCurrencyCode
          )}
        </div>

        <div
          className={`dm-sans-medium s14 d-flex justify-content-end performance-balance-width ${
            ifWarningIsActive(data?.balanceSummary?.balance?.amount, data?.balanceSummary?.balance?.status)
              ? "darkYellowColor"
              : "blackColor"
          }`}
        >
          <p className="me-1">
            {formatAmount(
              data?.balanceSummary?.balance?.amount,
              cultureInfo?.languageCode,
              cultureInfo?.defaultCurrencyCode
            )}
          </p>
          {ifWarningIsActive(data?.balanceSummary?.balance?.amount, data?.balanceSummary?.balance?.status) ? (
            <div style={{top: -2}} className="position-relative bill-tooltip-container">
              <TriangleWarningIcon />
              <GroupIncorpDescendantBilledTooltip value={formatAmount(
              data?.balanceSummary?.balance?.amount,
              cultureInfo?.languageCode,
              cultureInfo?.defaultCurrencyCode
            )} toggler={setBilledToggler} />
            </div>
          ) : null}
        </div>
      </div>
  );
}

export function WeekColumn(params) {
  
  return(
    <>
      {
        !params?.hideIf &&
        <>
          {params.currentWeek < params.index ? (
              <div
                className={`dm-sans-medium s14  d-flex justify-content-end performance-week-width 
                  brightGrayColor`}
              >
                {formatAmount(
                  0.0,
                  params.cultureInfo?.languageCode,
                  params.cultureInfo?.defaultCurrencyCode
                )}
              </div>
            ) : (
              <div
                className={`dm-sans-medium s14 d-flex justify-content-end performance-week-width blackColor`}
              >
                {formatAmount(
                  params.data?.weeklySalesAmounts[params.index-1]?.amount,
                  params.cultureInfo?.languageCode,
                  params.cultureInfo?.defaultCurrencyCode
                )}
              </div>
            )}
        </>

      }
    </>
  )
}