export default function Loader({ loading, customBgClass }) {

  return (
    <>
      {loading && (
        <div className={`spinner-border-container`}>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
}
/* 
    Component Loading needs a container with position relative to overtake.
    Its border is set up to 0.5rem to mimic most of components.
    If Component father is not position relative or any position,
    the loader will take the entire screen.
    spinner-border-container has position absolute and CSS prepared for the specifict. Use #ID to change params as needed.
*/
