import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";
import { resetDescendantSalesOffset, resetGroupSalesOffset, resetIncorporatedSalesOffset, upgradeDescendantSalesOffset, upgradeGroupSalesOffset, upgradeIncorporatedSalesOffset } from "./performanceActions";

const getUnsendedOrders = createAsyncThunk(
    'getUnsendedOrders',
    async (data, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            let periodInfo
            if (!data.yearPeriod) {
                periodInfo = await customNetServAxios().get(`/${location}/personal-sales/${data.consultantId ? data.consultantId : userId}/history/sales?historicPeriods=1`);

            }

            const response = await customNetServAxios().get(`/${location}/personal-sales/${data.consultantId ? data.consultantId : userId}/requests?yearPeriod=${data.yearPeriod ? data.yearPeriod : periodInfo.salesByPeriod[0]?.yearPeriod}`);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

const fetchTrackingOrderId = createAsyncThunk(
    'fetchTrackingOrderId',
    async (data, { rejectWithValue }) => {
        try {

            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/tracking-order/${data.consultantId ? data.consultantId : userId}/tracking/${data.trackingId}`)
            return response;
            
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

const getPendingOrders = createAsyncThunk(
    'getPendingOrders',
    async (data, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            let periodInfo
            if (!data.yearPeriod) {
                periodInfo = await customNetServAxios().get(`/${location}/personal-sales/${data.consultantId ? data.consultantId : userId}/history/sales?historicPeriods=1`);
            }

            const response = await customNetServAxios().get(`/${location}/personal-sales/${data.consultantId ? data.consultantId : userId}/pendings?yearPeriod=${data.yearPeriod ? data.yearPeriod : periodInfo.salesByPeriod[0]?.yearPeriod}`);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

const getBilledOrders = createAsyncThunk(
    'getBilledOrders',
    async (data, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            let periodInfo
            if (!data.yearPeriod) {
                periodInfo = await customNetServAxios().get(`/${location}/personal-sales/${data.consultantId ? data.consultantId : userId}/history/sales?historicPeriods=1`);
            }

            const response = await customNetServAxios().get(`/${location}/personal-sales/${data.consultantId ? data.consultantId : userId}/bills?yearPeriod=${data.yearPeriod ? data.yearPeriod : periodInfo.salesByPeriod[0]?.yearPeriod}`);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

const getHistoricPeriods = createAsyncThunk(
    'getHistoricPeriods',
    async (data, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            let periodInfo = await customNetServAxios().get(`/${location}/calendar/period-data`);
            const response = await customNetServAxios().get(`/${location}/personal-sales/${data.consultantId ? data.consultantId : userId}/history/sales?historicPeriods=12`);

            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

const getSalesByWeek = createAsyncThunk(
    'getSalesByWeek',
    async (data, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            let periodInfo
            if (!data.yearPeriod) {
                periodInfo = await customNetServAxios().get(`/${location}/personal-sales/${data.consultantId ? data.consultantId : userId}/history/sales?historicPeriods=1`);

            }
            const response = await customNetServAxios().get(`/${location}/personal-sales/${data.consultantId ? data.consultantId : userId}/bills/weeks?yearPeriod=${data.yearPeriod ? data.yearPeriod : periodInfo.salesByPeriod[0]?.yearPeriod}`);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

/*Ventas Grupales */

const getWeeklyGroupSales = createAsyncThunk(
    'getWeeklyGroupSales',
    async ({ yearPeriod, consultantId }, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            let periodInfo
            if (!yearPeriod) {
                periodInfo = await customNetServAxios().get(`/${location}/personal-sales/${consultantId ? consultantId : userId}/history/sales?historicPeriods=1`);
            }
            const response = await customNetServAxios().get(`/${location}/group-sales/${consultantId ? consultantId : userId}/sales/weeks?yearPeriod=${yearPeriod ? yearPeriod : periodInfo.salesByPeriod[0]?.yearPeriod}`);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

const groupSalesList = createAsyncThunk(
    'groupSalesList',
    async (data, { rejectWithValue, dispatch, getState }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            let periodInfo
            if (!data.yearPeriod) {
                periodInfo = await customNetServAxios().get(`/${location}/personal-sales/${data?.consultantId ? data?.consultantId : userId}/history/sales?historicPeriods=1`);
            }

            let queryParams = [
                `yearPeriod=${data.yearPeriod || periodInfo.salesByPeriod[0]?.yearPeriod}`,
                `limit=10`,
                `offset=${getState().performanceData.groupSalesOffset}`
            ];
            
            // Agregar condiciones dinámicamente según los datos disponibles
            if (userId) queryParams.push(`userId=${userId}`);
            if (data.sortBy) queryParams.push(`sort_by=${data.sortBy}`);
            if (data.filterType) queryParams.push(`filter_type=${data.filterType}`);
            if (data.weeks) queryParams.push(`weeks=${data.weeks}`);
            if (data.orders) queryParams.push(`orders=${data.orders}`);
            if (data.amountMin) queryParams.push(`amount_min=${data.amountMin}`);
            if (data.amountMax) queryParams.push(`amount_max=${data.amountMax}`);
            
            // Construir la URL final
            const url = `/${location}/group-sales/${userId}?${queryParams.join("&")}`;
            
            // Hacer la solicitud
            const response = await customNetServAxios().get(url);            

            await dispatch(resetGroupSalesOffset())
            await dispatch(upgradeGroupSalesOffset())
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
const extraGroupSalesList = createAsyncThunk(
    'extraGroupSalesList',
    async (data, { rejectWithValue, dispatch, getState }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            let periodInfo
            if (!data.yearPeriod) {
                periodInfo = await customNetServAxios().get(`/${location}/personal-sales/${userId}/history/sales?historicPeriods=1`);
            }

            let queryParams = [
                `yearPeriod=${data.yearPeriod || periodInfo.salesByPeriod[0]?.yearPeriod}`,
                `limit=10`,
                `offset=${getState().performanceData.groupSalesOffset}`
            ];
            
            // Agregar condiciones dinámicamente según los datos disponibles
            if (userId) queryParams.push(`userId=${userId}`);
            if (data.sortBy) queryParams.push(`sort_by=${data.sortBy}`);
            if (data.filterType) queryParams.push(`filter_type=${data.filterType}`);
            if (data.weeks) queryParams.push(`weeks=${data.weeks}`);
            if (data.orders) queryParams.push(`orders=${data.orders}`);
            if (data.amountMin) queryParams.push(`amount_min=${data.amountMin}`);
            if (data.amountMax) queryParams.push(`amount_max=${data.amountMax}`);
            
            // Construir la URL final
            const url = `/${location}/group-sales/${userId}?${queryParams.join("&")}`;
            
            // Hacer la solicitud
            const response = await customNetServAxios().get(url);
            

            dispatch(upgradeGroupSalesOffset())
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

/*Ventas Incorporadas */

const getWeeklyIncorporationsSales = createAsyncThunk(
    'getWeeklyIncorporationsSales',
    async ({ yearPeriod, consultantId }, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            let periodInfo
            if (!yearPeriod) {
                periodInfo = await customNetServAxios().get(`/${location}/personal-sales/${consultantId ? consultantId : userId}/history/sales?historicPeriods=1`);
            }
            const response = await customNetServAxios().get(`/${location}/personal-incorporations-sales/${consultantId ? consultantId : userId}/sales/weeks?yearPeriod=${yearPeriod ? yearPeriod : periodInfo.salesByPeriod[0]?.yearPeriod}`);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
const incorporationsSalesList = createAsyncThunk(
    'incorporationsSalesList',
    async (data, { rejectWithValue, dispatch, getState }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            let periodInfo
            if (!data.yearPeriod) {
                periodInfo = await customNetServAxios().get(`/${location}/personal-sales/${data?.consultantId ? data.consultantId : userId}/history/sales?historicPeriods=1`);
            }
            let queryParams = [
                `yearPeriod=${data.yearPeriod || periodInfo.salesByPeriod[0]?.yearPeriod}`,
                `limit=10`,
                `offset=0`
            ];
            
            // Agregar condiciones dinámicamente según los datos disponibles
            if (data?.consultantId || userId) {
                queryParams.push(`consultantId=${data?.consultantId || userId}`);
            }
            if (data.sortBy) queryParams.push(`sort_by=${data.sortBy}`);
            if (data.filterType) queryParams.push(`filter_type=${data.filterType}`);
            if (data.weeks) queryParams.push(`weeks=${data.weeks}`);
            if (data.orders) queryParams.push(`orders=${data.orders}`);
            if (data.amountMin) queryParams.push(`amount_min=${data.amountMin}`);
            if (data.amountMax) queryParams.push(`amount_max=${data.amountMax}`);
            
            // Construir la URL final
            const url = `/${location}/personal-incorporations-sales/${data?.consultantId || userId}?${queryParams.join("&")}`;
            
            // Hacer la solicitud
            const response = await customNetServAxios().get(url);
            
            

            await dispatch(resetIncorporatedSalesOffset())
            await dispatch(upgradeIncorporatedSalesOffset())
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
const extraIncorporationsSalesList = createAsyncThunk(
    'extraIncorporationsSalesList',
    async (data, { rejectWithValue, dispatch, getState }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            let periodInfo
            if (!data.yearPeriod) {
                periodInfo = await customNetServAxios().get(`/${location}/personal-sales/${data?.consultantId ? data.consultantId : userId}/history/sales?historicPeriods=1`);
            }

            let queryParams = [
                `yearPeriod=${data.yearPeriod || periodInfo.salesByPeriod[0]?.yearPeriod}`,
                `limit=10`,
                `offset=${getState().performanceData.incorporationsSalesOffset}`
            ];
            
            // Agregar condiciones dinámicamente según los datos disponibles
            if (data?.consultantId || userId) {
                queryParams.push(`consultantId=${data?.consultantId || userId}`);
            }
            if (data.sortBy) queryParams.push(`sort_by=${data.sortBy}`);
            if (data.filterType) queryParams.push(`filter_type=${data.filterType}`);
            if (data.weeks) queryParams.push(`weeks=${data.weeks}`);
            if (data.orders) queryParams.push(`orders=${data.orders}`);
            if (data.amountMin) queryParams.push(`amount_min=${data.amountMin}`);
            if (data.amountMax) queryParams.push(`amount_max=${data.amountMax}`);
            
            // Construir la URL final
            const url = `/${location}/personal-incorporations-sales/${data?.consultantId || userId}?${queryParams.join("&")}`;
            
            // Hacer la solicitud
            const response = await customNetServAxios().get(url);
            
            dispatch(upgradeIncorporatedSalesOffset())
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

/*Ventas Descendencia*/

const getWeeklyDescendantSales = createAsyncThunk(
    'getWeeklyDescendantSales',
    async ({ yearPeriod, consultantId }, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            let periodInfo
            if (!yearPeriod) {
                periodInfo = await customNetServAxios().get(`/${location}/personal-sales/${consultantId ? consultantId : userId}/history/sales?historicPeriods=1`);
            }
            const response = await customNetServAxios().get(`/${location}/descendant-sales/${consultantId ? consultantId : userId}/sales/weeks?yearPeriod=${yearPeriod ? yearPeriod : periodInfo.salesByPeriod[0]?.yearPeriod}`);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
const descendantSalesList = createAsyncThunk(
    'descendantSalesList',
    async (data, { rejectWithValue, dispatch, getState }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            let periodInfo
            if (!data.yearPeriod) {
                periodInfo = await customNetServAxios().get(`/${location}/personal-sales/${data?.consultantId ? data?.consultantId : userId}/history/sales?historicPeriods=1`);
            }

            let queryParams = [
                `yearPeriod=${data.yearPeriod || periodInfo.salesByPeriod[0]?.yearPeriod}`,
                `limit=10`,
                `offset=0`
            ];
            
            // Agregar condiciones dinámicamente según los datos disponibles
            if (data?.consultantId || userId) {
                queryParams.push(`consultantId=${data?.consultantId || userId}`);
            }
            if (data.sortBy) queryParams.push(`sort_by=${data.sortBy}`);
            if (data.filterType) queryParams.push(`filter_type=${data.filterType}`);
            if (data.weeks) queryParams.push(`weeks=${data.weeks}`);
            if (data.orders) queryParams.push(`orders=${data.orders}`);
            if (data.amountMin) queryParams.push(`amount_min=${data.amountMin}`);
            if (data.amountMax) queryParams.push(`amount_max=${data.amountMax}`);
            
            // Construir la URL final
            const url = `/${location}/descendant-sales/${data?.consultantId || userId}?${queryParams.join("&")}`;
            
            // Hacer la solicitud
            const response = await customNetServAxios().get(url);
            

            await dispatch(resetDescendantSalesOffset())
            await dispatch(upgradeDescendantSalesOffset())
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
const extraDescendantSalesList = createAsyncThunk(
    'extraDescendantSalesList',
    async (data, { rejectWithValue, dispatch, getState }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            let periodInfo
            if (!data.yearPeriod) {
                periodInfo = await customNetServAxios().get(`/${location}/personal-sales/${data?.consultantId ? data?.consultantId : userId}/history/sales?historicPeriods=1`);
            }

            let queryParams = [
                `yearPeriod=${data.yearPeriod || periodInfo.salesByPeriod[0]?.yearPeriod}`,
                `limit=10`,
                `offset=${getState().performanceData.descendantSalesOffset}`
            ];
            
            // Agregar condiciones dinámicamente según los datos disponibles
            if (data?.consultantId || userId) {
                queryParams.push(`consultantId=${data?.consultantId || userId}`);
            }
            if (data.sortBy) queryParams.push(`sort_by=${data.sortBy}`);
            if (data.filterType) queryParams.push(`filter_type=${data.filterType}`);
            if (data.weeks) queryParams.push(`weeks=${data.weeks}`);
            if (data.orders) queryParams.push(`orders=${data.orders}`);
            if (data.amountMin) queryParams.push(`amount_min=${data.amountMin}`);
            if (data.amountMax) queryParams.push(`amount_max=${data.amountMax}`);
            
            // Construir la URL final
            const url = `/${location}/descendant-sales/${data?.consultantId || userId}?${queryParams.join("&")}`;
            
            // Hacer la solicitud
            const response = await customNetServAxios().get(url);
            

            dispatch(upgradeDescendantSalesOffset())
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

/*Grupos Calificados*/

const getQualifiedGroups = createAsyncThunk(
    'getQualifiedGroups',
    async (payload, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/performance/${payload?.root ? payload?.root : userId}/score/groups?order=${payload?.order ? payload?.order : "asc"}`);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

const getPotentialLeaders = createAsyncThunk(
    'getPotentialLeaders',
    async (payload, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/performance/${payload?.root ? payload?.root : userId}/score/potential-leaders?order=${payload?.order ? `qualified.${payload?.order}` : "qualified.asc"}`); // Error Potential Leader
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)


const getPersonalSaleDetailsPendings = createAsyncThunk(
    'getPersonalSaleDetailsPendings',
    async ({ orderId }, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/personal-sales/${userId}/pendings/${orderId}`);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

const getPersonalSaleDetailsRequest = createAsyncThunk(
    'getPersonalSaleDetailsRequest',
    async ({ orderId }, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/personal-sales/${userId}/requests/${orderId}`);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

const getPersonalSaleDetailsBills = createAsyncThunk(
    'getPersonalSaleDetailsBills',
    async ({ orderId }, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/personal-sales/${userId}/bills/${orderId}`);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

const getPersonalSaleDetailsOrders = createAsyncThunk(
    'getPersonalSaleDetailsOrders',
    async ({ orderId }, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/personal-sales/${userId}/orders/${orderId}`);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
const getPersonalSaleDetailsOrdersEDB = createAsyncThunk(
    'getPersonalSaleDetailsOrdersEDB',
    async ({ orderId }, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/personal-sales/${userId}/orders/${orderId}/edb`);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

const getOtherConsultantData = createAsyncThunk(
    'getOtherConsultantData',
    async (userId, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            const response = await customNetServAxios().get(`${location}/profile/${userId}`);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export { getUnsendedOrders, getPendingOrders, getBilledOrders, getHistoricPeriods, getSalesByWeek, getWeeklyGroupSales, groupSalesList, extraGroupSalesList, getWeeklyIncorporationsSales, incorporationsSalesList, extraIncorporationsSalesList, getWeeklyDescendantSales, descendantSalesList, extraDescendantSalesList, getQualifiedGroups, getPersonalSaleDetailsPendings, getPersonalSaleDetailsBills, getPersonalSaleDetailsOrders, getPersonalSaleDetailsOrdersEDB, getPersonalSaleDetailsRequest, getOtherConsultantData, getPotentialLeaders, fetchTrackingOrderId }