import { useContext, useState, useEffect } from "react";
import { ReactComponent as SVGInfo } from "../icons/Info.svg";
import { ReactComponent as SVGError } from "../../../EDBs/Presentation/img/redcross.svg";
import { ReactComponent as SVGGreen } from "../../../common/svgs/ic-check-circle.svg";
import { ReactComponent as SVGTriangle } from "../icons/Warning.svg";
import { ModalClipboardContext } from "../../../common/routes/routes";
import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import EcommerceRegisterModalName from "./modals/EcommerceRegisterModalName";
import EcommerceRegisterModalInfo from "./modals/EcommerceRegisterModalInfo";
import ShareModalWarningMessage from "../../../common/Share/ShareModalWarningMessage";
import { useLocation, useNavigate } from "react-router-dom";

export default function EcommerceRegisterForm({
  current,
  howMany,
  onProgressingOnPath,
}) {
  const [inputValue, setInputValue] = useState({ value: "", error: false });
  const [pathOneStatus, setPathOneStatus] = useState("incompleted");
  const [copyToggler, setCopyToggler] = useState(false);
  const { setModalTexts } = useContext(ModalClipboardContext);
  const location = useLocation();
  const history = useNavigate();

  const onCopyClick = () => {
    const doThis = async () => {
      try {
        await navigator.clipboard.writeText(
          `www.justargentina.com/${inputValue.value}`
        );
      } catch (error) {
        unsecuredCopyToClipboard(`www.justargentina.com/${inputValue.value}`);
      }
    };
    const unsecuredCopyToClipboard = async (text) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    };
    doThis();
    setCopyToggler(true);
  };

  const onPathChange = (newValue) => {
    const modalData = {
      title: "Nombre de Tienda Virtual guardado",
      text: "Has guardado el nombre de tu tienda virtual con éxito.",
      status: "confirm",
      show: false,
      bottom: 84,
    };
    setModalTexts(modalData);
    
    if (!location?.pathname?.includes("/new")) {
      history("/dashboard/ecommerce/edit");
      return;
    };
    
    setPathOneStatus(newValue);
  };

  function validarNombreSitio(nombreSitio) {

    const country = localStorage.getItem("country");

    const palabrasProhibidaPorPais = {
      ar: [
        "just", "JUST", "oficial", "OFICIAL", "Just", "Oficial", 
        "SWISS", "COLOMBIA", "colombia", "Colombia", "BIENESTAR", 
        "YOGA", "ACEITES", "CONSULTORA", "NATURALEZA", "AROMATERAPIA"
    ],
      mx: ["just", "JUST", "oficial", "OFICIAL", "Just", "Oficial", "SWISS"],
      cl: ["just", "JUST", "oficial", "OFICIAL", "Just", "Oficial", "SWISS"],
      co: ["just", "JUST", "oficial", "OFICIAL", "Just", "Oficial", "SWISS","COLOMBIA","colombia","Colombia"],
      cr: ["just", "JUST", "oficial", "OFICIAL", "Just", "Oficial","SWISS","COSTARICA","costarica","Costarica","CostaRica"],
      pa: ["just", "JUST", "oficial", "OFICIAL", "Just", "Oficial","SWISS","COSTARICA","costarica","Costarica","CostaRica"],
      pe: ["just", "JUST", "oficial", "OFICIAL", "Just", "Oficial", "SWISS","PERU","peru","Peru"],
      uy: ["just", "JUST", "oficial", "OFICIAL", "Just", "Oficial", "SWISS","URUGUAY","uruguay","Uruguay"]
    };

    const palabrasProhibidas = palabrasProhibidaPorPais[country] || ["just", "JUST", "oficial", "OFICIAL", "Just", "Oficial", "SWISS"];

    const sinAcentos = nombreSitio.normalize("NFD").replace(/[\u0300-\u036f]/g, "");


    if (/\s/.test(sinAcentos)) {
        return { valid: false, message: "No se permiten espacios en blanco." };
    }

    for (const palabra of palabrasProhibidas) {
        const regex = new RegExp(`\\b${palabra}\\b`, "i"); // La i es para que sea case insensitive
        if (regex.test(sinAcentos)) {
            return { valid: false, message: `No puede contener la palabra prohibida: ${palabra}` };
        }
    }

    // Si pasa todas las validaciones
    return { valid: true, message: "El nombre del sitio es válido.", value: sinAcentos };
  };

  const validateInputValue = (value) => {
    const errorConditions = [
      { condition: value?.length < 1, message: "El campo no puede estar vacío" },
      { condition: value?.length <= 3, message: "El nombre debe tener 3 caracteres como mínimo" },
      { condition: value?.length > 25, message: "El nombre debe tener 25 caracteres como máximo" },
    ];
    
    for (const { condition, message } of errorConditions) {
      if (condition) {
        setInputValue({
          value: value,
          error: true,
          errorMessage: message,
        });
        return;
      }
    }

    const validationResult = validarNombreSitio(value);

    setInputValue({
      value: validationResult.value,
      error: !validationResult.valid,
      errorMessage: validationResult?.message,
    });

    

    console.log("validationResult: ", validationResult);

    return

    const clickMe = document.getElementById("register-modal-continue-pointer");
    clickMe.click();
  };

  const onNewPath = () => {
    onProgressingOnPath();
    setPathOneStatus("incompleted");
  };

  const onChangeInputValue = (e) => {
    setInputValue({ value: e.target.value, error: false });
  };

  const onSubmitPathOne = () => {
    validateInputValue(inputValue.value);
  };

  const tituloId = {
    1: "Elige un Nombre",
    2: "Vincula una cuenta de pago",
  };

  const JSXFormIncompleted = (
    <div className=" d-flex flex-column gap-1 w-100">
      <div className="d-flex gap-2">
        <div className=" d-flex align-items-center grayColor s16 museo-sans-400 ">
          www.justargentina.com/
        </div>
        <input
          value={inputValue.value}
          onChange={(e) => onChangeInputValue(e)}
          placeholder="Ej: tunombre"
          style={{ height: "32px" }}
          className={`w-100 museo-sans-400 s14 ${
            inputValue.error
              ? " input-error-border lightRedColor "
              : "input-border"
          } rounded-3 px-3 `}
        />
      </div>
      {inputValue.error ? (
        <div className=" position-relative lightRedColor museo-sans-400 d-flex s12 gap-1">
          <div>
            <SVGError />
          </div>
          <p style={{ top: "2px" }} className=" position-relative">
            {inputValue?.errorMessage}
          </p>
        </div>
      ) : (
        <div className="d-flex gap-1 s12 museo-sans-400">
          <div>
            <SVGInfo />
          </div>
          <div>
            El nombre debe tener 3 caracteres como mínimo y 25 como máximo.
            Puedes utilizar únicamente letras y números y tiene que cumplir los
            <span
              data-bs-toggle="modal"
              data-bs-target="#register-modal-name"
              className=" pointer skyColor museo-sans-600"
            >
              {" "}
              Requisitos del nombre{" "}
            </span>
          </div>
        </div>
      )}
    </div>
  );

  const JSXFormCompleted = (
    <EcommerceRegisterCompleted
      inputValue={inputValue}
      onCopyClick={onCopyClick}
      copyToggler={copyToggler}
    />
  );

  const formState = {
    completed: JSXFormCompleted,
    incompleted: JSXFormIncompleted,
  };

  const helpMessageId = {
    1: "Ten en cuenta que una vez que selecciones el nombre de tu Tienda no vas a poder cambiarlo.",
    2: "Para configurar y mantener tu Tienda Virtual debes tener una cuenta de MercadoPago vigente.",
  };

  const buttonsPaths = {
    1:
      pathOneStatus == "completed" ? (
        <button
          onClick={onNewPath}
          className=" border-0 skyBg text-white rounded-3 btnLoginWidth s14 museo-sans-600 "
        >
          {howMany == 1
            ? "Personalizar tu Tienda Virtual"
            : "Continuar al siguiente paso"}
        </button>
      ) : (
        <button
          onClick={onSubmitPathOne}
          className=" border-0 skyBg text-white rounded-3 btnLoginWidth s14 museo-sans-600 "
        >
          Guardar
        </button>
      ),
    2:
      pathOneStatus != "completed" ? (
        <button
          onClick={onNewPath}
          className=" border-0 skyBg text-white rounded-3 btnLoginWidth s14 museo-sans-600 "
        >
          Vincular cuenta de pago
        </button>
      ) : (
        <button
          onClick={onNewPath}
          className=" border-0 skyBg text-white rounded-3 btnLoginWidth s14 museo-sans-600 "
        >
          Personalizar tu Tienda Virtual
        </button>
      ),
  };

  useEffect(() => {
    setTimeout(() => {
      copyToggler && setCopyToggler(false);
    }, 3000);
  }, [copyToggler]);

  return (
    <div
      style={{ gap: "20px", width: "600px" }}
      className="d-flex flex-column "
    >
      <ShareModalStructure maxWidth={"696px"} id={"register-modal-name"}>
        <EcommerceRegisterModalName />
      </ShareModalStructure>
      <ShareModalStructure maxWidth={"496px"} id={"register-modal-info"}>
        <EcommerceRegisterModalInfo />
      </ShareModalStructure>
      <ShareModalStructure maxWidth={"496px"} id={"register-modal-continue"}>
        <ShareModalWarningMessage
          buttonConfirmedMessage={"Confirmar"}
          description={`El nombre de tu Tienda Virtual no se puede cambiar, ¿seguro quieres
          avanzar con tu nombre /${inputValue?.value}?`}
          title={"Nombre de la Tienda Virtual"}
          onButtonConfirmedClick={onPathChange}
          buttonIsCanceled={true}
        />
      </ShareModalStructure>
      <div className="position-relative d-flex gap-2 museo-sans-400 s20  ">
        <p>{tituloId[current]}</p>
        {pathOneStatus == "completed" && (
          <div
            onClick={onCopyClick}
            style={{ width: "16px", height: "16px", top: "-2px" }}
            className=" position-relative svg-force-size"
          >
            <SVGGreen />
          </div>
        )}
      </div>
      {current == 1 ? (
        <EcommerceRegisterFormPathOne formState={formState[pathOneStatus]} />
      ) : (
        <EcommerceRegisterFormPathTwo />
      )}
      {pathOneStatus != "completed" && (
        <div
          style={{ width: "600px", height: "70px" }}
          className="position-relative bgWarningHelpMessage d-flex gap-3 p-3 museo-sans-400 s14"
        >
          <div className=" svg-force-color blackColor">
            <SVGTriangle />
          </div>
          <p>{helpMessageId[current]}</p>
        </div>
      )}
      <div style={{ marginTop: "20px" }}>{buttonsPaths[current]}</div>
      <div
        data-bs-toggle="modal"
        data-bs-target="#register-modal-continue"
        id="register-modal-continue-pointer"
      ></div>
    </div>
  );
}

export function EcommerceRegisterFormPathTwo(params) {
  return (
    <div style={{ gap: "20px" }} className="d-flex flex-column">
      <p className=" museo-sans-600 s14">Cuenta de pago asociada</p>
      <p className=" grayColor museo-sans-400 s14">
        Aún no has asociado ninguna cuenta.
      </p>
    </div>
  );
}

export function EcommerceRegisterFormPathOne({ formState }) {
  return (
    <div className="w-100">
      <div className="d-flex museo-sans-600 s14 gap-1 mb-1">
        <p>Nombre de la Tienda Virtual</p>
        <div
          data-bs-toggle="modal"
          data-bs-target="#register-modal-info"
          style={{ width: "16px", height: "16px", top: "-2px" }}
          className=" position-relative svg-force-size pointer"
        >
          <HelpIconNoTarget />
        </div>
      </div>
      {formState}
    </div>
  );
}

export function EcommerceRegisterCompleted(params) {
  const [copyToggler, setCopyToggler] = useState(false);

  const onCopyClick = () => {
    const doThis = async () => {
      try {
        await navigator.clipboard.writeText(
          `www.justargentina.com/${params.inputValue.value}`
        );
      } catch (error) {
        unsecuredCopyToClipboard(
          `www.justargentina.com/${params.inputValue.value}`
        );
      }
    };
    const unsecuredCopyToClipboard = async (text) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    };
    doThis();
    setCopyToggler(true);
  };

  useEffect(() => {
    setTimeout(() => {
      copyToggler && setCopyToggler(false);
    }, 3000);
  }, [copyToggler]);

  return (
    <div className=" position-relative d-flex gap-1">
      <p className={`s20 museo-sans-400 ${params?.onStatusConfig ? "lightRedColor" : "grayColor"} `}>
        www.justargentina.com/{params.inputValue.value}
      </p>

      <div
        onClick={onCopyClick}
        style={{ top: "7px" }}
        className=" position-relative pointer skyColor s14 museo-sans-600"
      >
        {copyToggler && (
          <div
            style={{
              height: "38px",
              width: "120px",
              top: "-8px",
            }}
            className=" justify-content-center greenModalBg s16 d-flex align-items-center gap-2 position-absolute"
          >
            <i
              style={{ justifySelf: "center" }}
              className=" greenColor bi bi-check-circle"
            ></i>
            <p className="greenColor">
              <FormattedMessage id="buttons.copied" />
            </p>
          </div>
        )}
        <FormattedMessage id="copy" />
      </div>
    </div>
  );
}
