import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";
import DashboardFirstStepsModal from "../../../Dashboard/Presentation/components/Modals/DashboardFirstStepsModal";
import DashboardFirstStepsFilter from "../../../Dashboard/Presentation/components/DashboardFirstStepsFilter";
import DashboardFirstStepsDetailsModal from "../../../Dashboard/Presentation/components/Modals/DashboardFirstStepsDetailsModal";
import FirstStepsModalIncorporatedDetails from "../components/modals/FirstStepsModalIncorporatedDetails";
import FirstStepsModalHelpTwo from "../components/modals/FirstStepsModalHelpTwo";
import FirstStepsModalGuidelinesA from "../components/modals/FirstStepsModalGuidelinesA";

import {
  getDataLiderAndDirector,
  getDataFirstGeneration,
} from "../../Framework/redux/FirstStepsAllSideEffect";
import { cleanFirstGenerationData } from "../../Framework/redux/FirstStepsAllActions";
import ShareCenterControlPage from "../../../common/Share/ShareCenterControlPage";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";

export default function FirstStepsDashboard() {
  let country = localStorage.getItem("country");
  const history = useNavigate();
  const dispatch = useDispatch();
  const [toggler, setToggler] = useState("a");
  const [filter, setFilter] = useState(false);
  const [ordernarPor, setOrdenarPor] = useState("stepsReached");
  const [orden, setOrden] = useState("desc");
  const [selectedButton, setSelectedButton] = useState(0);
  const [selectedButtonOrden, setSelectedButtonOren] = useState(0);
  const [toggleSkeletonOnClick, setToggleSkeletonOnClick] = useState(false);
  const TableLoader = useSelector(
    (state) => state?.firstStepsAllReducer?.TableLoader
  );
  const profileData = useSelector((state) => state.userInfo.userInfo);
  const firstGenerationInfo = useSelector(
    (state) => state?.firstStepsAllReducer?.firstGenerationData
  );
  const stepsData = useSelector(
    (state) => state?.firstStepsAllReducer?.firstStepsIncorp
  );

  const callFilteredData = async () => {
    await dispatch(getDataLiderAndDirector({ ordernarPor, orden }));
  };

  const checkFilterOpen = () => {
    if (filter == true) {
      setFilter(false);
    }
  };

  const checkTab = () => {
    const fullUrl = window.location.href;
    const pathname = new URL(fullUrl).pathname;
    const parts = pathname.split("/");
    const name = parts[parts.length - 1];

    if (name == "incorporatedPersonal") {
      setToggler("a");
      history("incorporatedPersonal");
    }
    if (name == "restOfGroup") {
      setToggler("b");
      history("restOfGroup");
    }
    if (name == "firstGeneration") {
      setToggler("c");
      history("firstGeneration");
    }
  };

  const callServiceFirstSteps = async (index) => {
    await dispatch(getDataLiderAndDirector({ ordernarPor, orden }));
  };

  const activatedSkeleton = () => {
    setToggleSkeletonOnClick(true);
    setTimeout(() => {
      setToggleSkeletonOnClick(false);
    }, 2000);
  };

  const controlClick = async (toggler, location) => {
    if (toggler == "c") {
      activatedSkeleton();
    let ordernarPor = "stepsReached";
    let orden = "desc";
    let auxOffSet = 0;
    dispatch(cleanFirstGenerationData());
    await dispatch(getDataFirstGeneration({ ordernarPor, orden, auxOffSet }));
    }
    setFilter(false);
    setToggler(toggler);
    history(location);
    checkTab();
  };

  const onClickOne = () => {
    controlClick("a", "incorporatedPersonal");
  };

  const onClickTwo = () => {
    controlClick("b", "restOfGroup");
  };

  const onClickThree = async () => {
    controlClick("c", "firstGeneration");
  };

  let ConsultantsGroup = 0;
  let varRestGroup = 0;

  for (
    let index = 0;
    index < stepsData?.personalGroup?.consultantsGroup?.length;
    index++
  ) {
    if (
      stepsData?.personalGroup?.consultantsGroup[index]
        ?.isPersonalIncorporated == true
    ) {
      ConsultantsGroup = ConsultantsGroup + 1;
    }
    if (
      stepsData?.personalGroup?.consultantsGroup[index]
        ?.isPersonalIncorporated == false
    ) {
      varRestGroup = varRestGroup + 1;
    }
  }
  let index = 0;

  if (ConsultantsGroup > 0) {
    index = index + 184;
  }
  if (varRestGroup > 0) {
    index = index + 184;
  }

  useEffect(() => {
    checkFilterOpen();
  }, []);

  useEffect(() => {
    callServiceFirstSteps();
    checkTab();
  }, []);

  return (
    <div
      className={`  ${profileData?.level < 2 ? " bg-white pt-5" : "pt-4 mt-3"}`}
    >
      <ShareModalStructure
        id={"modalFirstStepsGuidelinesA"}
        maxWidth={"807px"}
        maxHeight={"640px"}
      >
        <FirstStepsModalGuidelinesA />
      </ShareModalStructure>
      <ShareModalStructure
        id={"modalFirstOne"}
        maxWidth={"608px"}
        maxHeight={"608px"}
      >
        <FirstStepsModalHelpTwo />
      </ShareModalStructure>
      <FirstStepsModalIncorporatedDetails />
      <ShareModalStructure
        id={"modalDetailsInfo"}
        maxWidth={"610px"}
        maxHeight={"439px"}
      >
        <DashboardFirstStepsDetailsModal />
      </ShareModalStructure>
      <ShareModalStructure
        id={"modalInfo"}
        maxWidth={"610px"}
        maxHeight={"439px"}
      >
        <DashboardFirstStepsModal />
      </ShareModalStructure>
      <ShareCenterControlPage
        pageBackground={profileData?.level < 2 ? "#FFF" : false}
      >
        <DashboardFirstStepsFilter
          filter={filter}
          setFilter={setFilter}
          callFilteredData={callFilteredData}
          setOrdenarPor={setOrdenarPor}
          setOrden={setOrden}
          setSelectedButtonOren={setSelectedButtonOren}
          selectedButtonOrden={selectedButtonOrden}
          setSelectedButton={setSelectedButton}
          selectedButton={selectedButton}
          profileData={profileData}
        />
      </ShareCenterControlPage>
      <ShareCenterControlPage>
        {TableLoader ? (
          <DashboardTabsTableEskeleton />
        ) : (
          <>
            {profileData?.level > 1 && (
              <DashboardTabsTable
                profileData={profileData}
                onClickOne={onClickOne}
                onClickTwo={onClickTwo}
                onClickThree={onClickThree}
                toggler={toggler}
                varRestGroup={varRestGroup}
                ConsultantsGroup={ConsultantsGroup}
                index={index}
                firstGenerationInfo={firstGenerationInfo}
                setSelectedButtonOren={setSelectedButtonOren}
                setSelectedButton={setSelectedButton}
                callServiceFirstSteps={callServiceFirstSteps}
              />
            )}
          </>
        )}
      </ShareCenterControlPage>
      <ShareCenterControlPage pageBackground={"#FFF"}>
        {TableLoader || toggleSkeletonOnClick ? <SkeletonOutlet /> : <Outlet />}
      </ShareCenterControlPage>
    </div>
  );
}

export function DashboardTabsTable({
  onClickOne,
  onClickTwo,
  toggler,
  onClickThree,
  profileData,
  setSelectedButtonOren,
  setSelectedButton,
  callServiceFirstSteps,
}) {
  const onClickTab = (index) => {
    setSelectedButtonOren(0);
    setSelectedButton(0);
    callServiceFirstSteps(index);

    if (index == 1) {
      onClickOne();
    }
    if (index == 2) {
      onClickTwo();
    }
    if (index == 3) {
      onClickThree();
    }
  };

  return (
    <div className="">
      <div className=" w-100">
        <div className=" position-relative" style={{ height: "30px" }}>
          <div
            id="incorporated"
            onClick={() => onClickTab(1)}
            style={{
              width: "184px",
              height: "38px",
              top: -8,
              borderRadius: "16px 16px 0px 0px ",
              zIndex: 0,
            }}
            className={`${
              toggler == "a" ? "bg-white" : "lightGreyBg grayColor"
            } tabs-shadow pointer d-flex justify-content-center align-items-center py-2   position-absolute`}
          >
            <p className=" museo-sans-500 s14 ">
              <FormattedMessage
                id="nav.label.one.b"
                defaultMessage={"Incorporados"}
              />
            </p>
          </div>
          <div
            id="rest"
            onClick={() => onClickTab(2)}
            style={{
              width: "184px",
              height: "38px",
              top: -8,
              borderRadius: "16px 16px 0px 0px ",
              left: 184,
              zIndex: 0,
            }}
            className={` ${
              toggler == "b" ? "bg-white" : "lightGreyBg grayColor"
            } tabs-shadow pointer d-flex justify-content-center align-items-center py-2  position-absolute`}
          >
            <p className=" museo-sans-500 s14 ">
              <FormattedMessage
                id="dashboard.graph.dropmenu.sublevel.five"
                defaultMessage={"Consultora"}
              />
            </p>
          </div>
          {profileData?.level > 2 && (
            <>
              <div
                id="firstGen"
                onClick={() => onClickTab(3)}
                style={{
                  width: "184px",
                  height: "38px",
                  top: -8,
                  borderRadius: "16px 16px 0px 0px ",
                  left: 368,
                  zIndex: 0,
                }}
                className={` ${
                  toggler == "c" ? "bg-white" : "lightGreyBg grayColor"
                } tabs-shadow pointer d-flex justify-content-center align-items-center py-2  position-absolute`}
              >
                <p className=" museo-sans-500 s14 ">
                  <FormattedMessage
                    id="dashboard.graph.dropmenu.sublevel.ten"
                    defaultMessage={"Consultora"}
                  />
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export function DashboardTabsTableEskeleton() {
  return (
    <>
      {" "}
      <div className="">
        <div className=" w-100">
          <div className=" position-relative" style={{ height: "30px" }}>
            <div
              style={{
                width: "184px",
                height: "38px",
                top: -8,
                borderRadius: "16px 16px 0px 0px ",
                zIndex: 0,
              }}
              className={`${"brightGrayBg lightui1-shimmer"} grayColor tabs-shadow  d-flex justify-content-center align-items-center py-2   position-absolute`}
            ></div>

            <div
              style={{
                width: "184px",
                height: "38px",
                top: -8,
                borderRadius: "16px 16px 0px 0px ",
                left: 184,
                zIndex: 0,
              }}
              className={` ${"brightGrayBg lightui1-shimmer"} grayColor tabs-shadow  d-flex justify-content-center align-items-center py-2  position-absolute`}
            ></div>

            <>
              <div
                style={{
                  width: "184px",
                  height: "38px",
                  top: -8,
                  borderRadius: "16px 16px 0px 0px ",
                  left: 368,
                  zIndex: 0,
                }}
                className={` ${"brightGrayBg lightui1-shimmer"} grayColor tabs-shadow  d-flex justify-content-center align-items-center py-2  position-absolute`}
              ></div>
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export function SkeletonOutlet() {
  const biggerThan1000 = useMediaPredicate("(min-width: 1281px)");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {}, [windowWidth]);

  return (
    <div className="container--padding--card-holder">
      <div className="w-100">
        <div
          className=" bg-white w-100 position-relative h-100"
          style={{ zIndex: 1 }}
        >
          <div className="table--header--1eraGen pt-4 px-0 museo-sans-500 s12 grayColor">
            <p>NOMBRE</p>
            <p>AVANCE</p>
            <p>PROGRESO</p>
            <p></p>
          </div>
          <div
            style={{
              paddingBottom: "73px",
              maxHeight: "827px",
              minHeight: biggerThan1000
                ? "980px"
                : !windowWidth == 1280
                ? "350px"
                : "390px",
            }}
            className="hidden-y-scroll"
          >
            <>
              <div className="pointer w-100">
                <div
                  className={`w-100 pointer  ${"table--header--1eraGenList"} px-0 align-items-center w-100 hidden-y-scroll  } `}
                >
                  <>
                    <>
                      <div className="d-flex flex-row gap-1 align-items-center museo-sans-700 s14">
                        <div
                          style={{
                            height: "32px",
                            width: "32px",
                            borderRadius: "50%",
                          }}
                          className="  brightGrayBg lightui1-shimmer"
                        ></div>
                        <div
                          className="d-flex flex-row gap-1 align-items-center rounded gap-2  brightGrayBg lightui1-shimmer"
                          style={{ width: "100px", height: "16px" }}
                        ></div>
                      </div>
                      <div
                        className="d-flex flex-row gap-1 align-items-center rounded gap-2  brightGrayBg lightui1-shimmer"
                        style={{ width: "100px", height: "16px" }}
                      ></div>
                      <div className="d-flex flex-row gap-1 align-items-center gap-2">
                        <div
                          style={{ height: "16px", width: "16px" }}
                          className="brightGrayBg lightui1-shimmer rounded"
                        ></div>
                        <div
                          style={{ height: "16px", width: "16px" }}
                          className="brightGrayBg lightui1-shimmer rounded"
                        ></div>
                        <div
                          style={{ height: "16px", width: "16px" }}
                          className="brightGrayBg lightui1-shimmer rounded"
                        ></div>
                      </div>
                      <span
                        className="d-flex "
                        style={{ justifyContent: "right" }}
                      >
                        <button style={{ border: "0px" }} className="bg-white">
                          {/* <ArrowRight /> */}
                        </button>
                      </span>
                    </>
                  </>
                </div>
              </div>
            </>
            <hr
              style={{
                marginLeft: "0px",
                marginRight: "0px",
                marginBottom: "0px",
                marginTop: "0px",
              }}
            ></hr>
          </div>
        </div>
      </div>
    </div>
  );
}
