import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import ShareModalWarningMessage from "../../../common/Share/ShareModalWarningMessage";
import { ShareRadio } from "../../../common/Share/ShareRadio";
import { fetchVirtualEcommerceTopics, updateVirtualEcommerce } from "../../Framework/redux/EcommerceSideEffect";
import EcommerceFormCustomHolder from "../components/EcommerceFormCustomHolder";
import EcommerceFormHelpMessage from "../components/EcommerceFormHelpMessage";
import EcommerceFormProfileHolder from "../components/EcommerceFormProfileHolder";
import EcommerceFormSocialHolder from "../components/EcommerceFormSocialHolder";
import EcommerceFormModalAssociate from "../components/modals/EcommerceFormModalAssociate";
import EcommerceFormModalProfilePicture from "../components/modals/EcommerceFormModalProfilePicture";
import EcommerceFormModalSelect from "../components/modals/EcommerceFormModalSelect";
import EcommerceRegisterModalInfo from "../components/modals/EcommerceRegisterModalInfo";

export default function EcommerceForm() {
  const ecommerceData = useSelector((state) => state.ecommerceData);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    errorToggler: false,
    body: {
      topics: { list: [], selected: "", error: "" },
      color: { selected: 2, error: "" },
      privacy: { selected: true, error: "" },
      history: { value: "", error: "" },
      phrase: { value: "", error: "" },
      id: { value: "", error: "" },
      photo: { value: "", error: "" },
      sitename: { value: "", error: "" },
    },
  });

  const onStartUp = async () => {
    await dispatch(fetchVirtualEcommerceTopics());
  };

  const onChangeFormBodyByPath = (path, value) => {
    setFormData((prevState) => {
      const newBody = { ...prevState.body };
      const updateNestedField = (obj, keys, value) => {
        const [key, ...rest] = keys;
        try {
          if (rest.length === 0) {
            obj[key] = value;
          } else {
            if (!obj[key]) obj[key] = {};
            updateNestedField(obj[key], rest, value);
          }
        } catch (error) {
          console.log(error);
        }
      };
      updateNestedField(newBody, path.split("."), value);
      return {
        ...prevState,
        body: newBody,
      };
    });
  };

  const onClickActivator = (activator) => {
    const clickMe = document.getElementById(activator);
    clickMe.click();
  };

  const validatePerRequest = (value, request) => {
    if (request == "notVoid") {
      return value?.length > 0;
    }
    if (request?.type == "includes") {
      return value.includes(request?.value);
    }
    if (request?.type == "validateWords") {
      const ar = [
        "just",
        "JUST",
        "oficial",
        "OFICIAL",
        "Just",
        "Oficial",
        "SWISS",
        "COLOMBIA",
        "colombia",
        "Colombia",
        "BIENESTAR",
        "YOGA",
        "ACEITES",
        "CONSULTORA",
        "NATURALEZA",
        "AROMATERAPIA",
      ];

      for (const palabra of ar) {
        const regex = new RegExp(`\\b${palabra}\\b`, "i"); 
        if (regex.test(value)) {
          return false;
        }
        return true;
      }
    }
  };

  const onSubmit = async () => {
    const callBackNetworkConditoinals = (value, label) => {
      if (!value) {
        return true;
      }
      const country = localStorage.getItem("country");
      const socialNetworkConditionals = {
        ar:
          validatePerRequest(value, {
            type: "includes",
            value: `/www.${label}.com/`,
          }) &&
          validatePerRequest(value, {
            type: "validateWords",
          }),
        mx: true,
        co: validatePerRequest(value, {
          type: "includes",
          value: `/www.${label}.com/`,
        }),
        cl: validatePerRequest(value, {
          type: "includes",
          value: `/www.${label}.com/`,
        }),
        cr: validatePerRequest(value, {
          type: "includes",
          value: `/www.${label}.com/`,
        }),
        pa: validatePerRequest(value, {
          type: "includes",
          value: `/www.${label}.com/`,
        }),
        uy: validatePerRequest(value, {
          type: "includes",
          value: `/www.${label}.com/`,
        }),
        pe: validatePerRequest(value, {
          type: "includes",
          value: label == "facebook" ? "facebook" : `${label}.com`,
        }),
      };

      return socialNetworkConditionals[country];
    };

    let errorHandler = {
      photo: !validatePerRequest(formData?.body?.photo?.value, "notVoid"),
      phrase: !validatePerRequest(formData?.body?.phrase?.value, "notVoid"),
      history: !validatePerRequest(formData?.body?.history?.value, "notVoid"),
      facebook: !callBackNetworkConditoinals(
        formData?.body?.socialNetwork?.facebook,
        "facebook"
      ),
      instagram: !callBackNetworkConditoinals(
        formData?.body?.socialNetwork?.instagram,
        "instagram"
      ),
      linkedin: !callBackNetworkConditoinals(
        formData?.body?.socialNetwork?.linkedin,
        "linkedin"
      ),
      youtube: !callBackNetworkConditoinals(
        formData?.body?.socialNetwork?.youtube,
        "youtube"
      ),
    };

    let isErrorToggler = false;
    for (const [key, value] of Object.entries(errorHandler)) {
      if (value) { 
        isErrorToggler = true;
        setFormData((prevState) => {
          let adjustingState = { ...prevState };
          adjustingState.errorToggler = true;
    
          let newBody = { ...prevState.body };
          if (key === "facebook") {
            newBody.socialNetwork.facebook.error = true;
          }
          if (key === "instagram") {
            newBody.socialNetwork.instagram.error = true;
          }
          if (key === "linkedin") {
            newBody.socialNetwork.linkedin.error = true;
          }
          if (key === "youtube") {
            newBody.socialNetwork.youtube.error = true;
          }
          if (
            key === "photo" ||
            key === "phrase" ||
            key === "history"
          ) {
            newBody[key].error = true;
          }
          return {
            ...adjustingState,
            body: newBody,
          };
        });
      }
    };
    if (isErrorToggler) {
      console.log("Error en el formulario");
      console.log("errorHandler: ", errorHandler);
      return;
    };

    const preBody = {
      id: ecommerceData?.ecommerceData.data?.id,
      clientId: localStorage.getItem("clientId"),
      siteName: formData?.body?.sitename?.value,
      photo: formData?.body?.photo?.value,
      phrase: formData?.body?.phrase?.value,
      history: formData?.body?.history?.value,
      socialNetwork: formData?.body?.socialNetwork,
      colorBackground: formData?.body?.color?.selected,
      visible: formData?.body?.privacy?.selected,
      accountMP: ecommerceData?.ecommerceData.data?.accountMP ?? "",
    };

    const result = await dispatch(updateVirtualEcommerce(preBody));

    console.log("result: ", result);

  };

  useEffect(() => {
    setFormData({
      ...formData,
      body: {
        ...formData.body,
        topics: {
          ...formData.body.topics,
          list: ecommerceData.ecommerceDataTopics.data?.topics,
        },
        history: {
          ...formData.body.history,
          value: ecommerceData.ecommerceData.data?.history,
        },
        color: {
          ...formData.body.color,
          selected: ecommerceData.ecommerceData.data?.colorBackground ?? 2,
        },
        id: {
          ...formData.body.id,
          value: ecommerceData.ecommerceData.data?.id,
        },
        phrase: {
          ...formData.body.phrase,
          value: ecommerceData.ecommerceData.data?.phrase,
        },
        photo: {
          ...formData.body.photo,
          value: ecommerceData.ecommerceData.data?.photo,
        },
        sitename: {
          ...formData.body.sitename,
          value: ecommerceData.ecommerceData.data?.storeName,
        },
        socialNetwork: { ...ecommerceData?.ecommerceData?.data?.socialNetwork },
      },
    });
  }, [ecommerceData?.ecommerceData, ecommerceData.ecommerceDataTopics]);

  useEffect(() => {
    onStartUp();
  }, []);

  return (
    <div
      style={{ gap: "40px", marginBottom: "72px" }}
      className="d-flex flex-column mt-4 pt-3"
    >
      <ShareModalStructure id={"form-select-phrase"} maxWidth={"496px"}>
        <EcommerceFormModalSelect
          onSelect={onChangeFormBodyByPath}
          label={"phrase.value"}
          list={formData.body.topics.list}
        />
      </ShareModalStructure>
      <ShareModalStructure id={"form-associate"} maxWidth={"496px"}>
        <EcommerceFormModalAssociate />
      </ShareModalStructure>
      <ShareModalStructure maxWidth={"496px"} id={"register-modal-info"}>
        <EcommerceRegisterModalInfo />
      </ShareModalStructure>
      <ShareModalStructure id={"ecommerce-profile-helper"} maxWidth={"496px"}>
        <EcommerceFormModalProfilePicture />
      </ShareModalStructure>
      <ShareModalStructure maxWidth={"496px"} id={"ecommerce-profile-pop-up"}>
        <ShareModalWarningMessage
          buttonConfirmedMessage={"Confirmar"}
          description={
            "Al cambiar tu foto de perfil para la Tienda Virtual también cambiará la foto en tu perfil del registro en la App Oficina Virtual Just."
          }
          title={"¿Deseas cambiar tu foto de perfil?"}
          onButtonConfirmedClick={() =>
            onClickActivator("ecommerce-profile-helper-activator")
          }
          buttonIsCanceled={true}
        />
      </ShareModalStructure>
      <div
        data-bs-toggle="modal"
        data-bs-target="#ecommerce-profile-helper"
        id="ecommerce-profile-helper-activator"
      ></div>
      <EcommerceFormHelpMessage
        ecommerceData={ecommerceData?.ecommerceData?.data}
      />
      <EcommerceFormProfileHolder
        ecommerceData={ecommerceData}
        formData={formData}
      />
      <EcommerceFormCustomHolder
        onChange={onChangeFormBodyByPath}
        ecommerceData={ecommerceData?.ecommerceData}
        formData={formData}
      />
      <EcommerceFormSocialHolder
        onChange={onChangeFormBodyByPath}
        formData={formData}
      />
      <EcommerceFormRadioHolder
        dataLabel={"color"}
        formData={formData}
        onSelect={onChangeFormBodyByPath}
        label={"Paleta"}
        sublabel={"Color de fondo"}
        index={0}
      />
      <EcommerceFormRadioHolder
        dataLabel={"privacy"}
        formData={formData}
        onSelect={onChangeFormBodyByPath}
        label={"Privacidad"}
        sublabel={"Visibilidad de tu Tienda"}
        index={1}
      />
      <button
        onClick={onSubmit}
        className=" btnLoginWidth rounded-3 border-0 skyBg text-white museo-sans-600 s14 "
      >
        Guardar
      </button>
    </div>
  );
}

export function EcommerceFormRadioHolder(params) {
  const arrayRadios = [
    {
      labelId: "Rosa",
      disabled: false,
      active: params?.formData?.body[params?.dataLabel]?.selected == 2,
      onClick: () => params?.onSelect(`${params?.dataLabel}.selected`, 2),
    },
    {
      labelId: "Azul",
      disabled: false,
      active: params?.formData?.body[params?.dataLabel]?.selected == 1,
      onClick: () => params?.onSelect(`${params?.dataLabel}.selected`, 1),
    },
    {
      labelId: "Gris",
      disabled: false,
      active: params?.formData?.body[params?.dataLabel]?.selected == 3,
      onClick: () => params?.onSelect(`${params?.dataLabel}.selected`, 3),
    },
  ];
  const arrayRadiosTwo = [
    {
      labelId: "Visible",
      disabled: false,
      active: params?.formData?.body[params?.dataLabel]?.selected,
      onClick: () => params?.onSelect(`${params?.dataLabel}.selected`, true),
    },
    {
      labelId: "No Visible",
      disabled: false,
      active: !params?.formData?.body[params?.dataLabel]?.selected,
      onClick: () => params?.onSelect(`${params?.dataLabel}.selected`, false),
    },
  ];
  const wholeArrays = [arrayRadios, arrayRadiosTwo];

  return (
    <div className="d-flex flex-column gap-3">
      <div>
        <p className=" s20 museo-sans-400">{params?.label}</p>
      </div>
      <div>
        <p className=" s16 museo-sans-600">{params?.sublabel}</p>
      </div>
      <div className="d-flex gap-4 museo-sans-400 s14">
        <ShareRadio gap={"8px"} arrayRadios={wholeArrays[params?.index]} />
      </div>
    </div>
  );
}
