import imageOne from "../../icons/ecommerceProfilePictureHelpImage.png";
import { ReactComponent as SVGError } from "../../../../EDBs/Presentation/img/redcross.svg";
import { ReactComponent as SVGGreen } from "../../../../common/svgs/ic-check-circle.svg";
import ModalWhiteShadow from "../../../../common/Share/ModalWhiteShadow";
import { useRef } from "react";

export default function EcommerceFormModalProfilePicture(params) {

    const scrollRef = useRef(null);

    const onClickActivateConfiguration = () => {
        const clickMe = document.getElementById("configuration-button-start");
        clickMe.click();
        setTimeout(() => {
          const clickMeStepTwo = document.getElementById("configuration-button-start-profile-change");
          clickMeStepTwo.click();
        }, 100);
    };


  return (
    <div style={{ margin: "32px" }} className=" position-relative museo-sans-400">
      <p className=" museo-sans-600 s20 mb-4">Requisitos de imágenes</p>
      <div ref={scrollRef} style={{height: "364px"}} className="d-flex flex-column gap-4 s14 hidden-y-scroll ">
        <div className=" d-flex flex-column gap-3">
          <div>
            <div className=" d-flex gap-1" >
              <div style={{width: "16px", top: "-2px"}} className=" svg-force-size position-relative" >
                <SVGGreen />
              </div>
              <p className=" museo-sans-600" >Lo que sí:</p>
            </div>
            <div>
              <p>La foto debe ser personal y verse de forma clara.</p>
            </div>
          </div>
          <div style={{ width: "420px" }} className="d-flex justify-content-center w-100">
            <div style={{ border: "1px solid var(--Brand-Lilac-200, #DBDBF4)" }} className="mx-2 rounded-3 ">
              <img className="px-3" style={{ width: "404px" }} src={imageOne} />
            </div>
          </div>
        </div>
        <div>
          <div className=" d-flex gap-1" >
            <div style={{width: "16px", top: "-2px"}} className=" svg-force-size position-relative" >
                <SVGError />
            </div>
            <p className=" museo-sans-600" >Lo que no:</p>
          </div>
          <div>
            No deberías elegir fotos con las siguientes especificaciones:
          </div>
          <div>
            <ul>
              <li>Logos creados para las Regiones Just o Just.</li>
              <li>Fotos de productos Just u otras marcas.</li>
              <li>
                Fotos de mascotas o cualquier otra cosa que no sea una foto
                tuya.
              </li>
              <li>
                Fotos donde no se reconozca tu cara (desenfocadas, con mucho
                contraste o caras tapadas).
              </li>
              <li>Fotos con anteojos negros.</li>
              <li>Fotos de la Consultora con otra persona.</li>
              <li>Fotos de cuerpo entero donde no se pueda ver tu cara.</li>
              <li>La imagen debe ser JPG o PNG. Tamaño máx. 2MB.</li>
            </ul>
          </div>
        </div>
      </div>
      <ModalWhiteShadow aidHide={20} bottom={1} scrollbarRef={scrollRef} />
      <div style={{zIndex: 10}} className=" position-relative d-flex justify-content-center w-100" >
        <div
          onClick={onClickActivateConfiguration}
          className=" d-flex justify-content-center align-items-center pointer skyBg text-white rounded-3 s-14 museo-sans-600 mt-3 btnLoginWidth"
        >
          Seleccionar
        </div>
      </div>
    </div>
  );
}
