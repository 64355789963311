import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {ReactComponent as DollarIcon} from '../../../common/svgs/ic-dollar-sm.svg'
import {ReactComponent as RequestsIcon} from '../../../common/svgs/ic-pedidos-sm.svg'
import { useDispatch, useSelector } from 'react-redux';
import { groupSalesList, incorporationsSalesList, descendantSalesList } from "../../Framework/redux/performanceSideEffects";
import { changeFilters, defineFiltersApplied, resetFilters } from "../../Framework/redux/performanceActions";

import { ReactComponent as SVGClose } from "../../../common/svgs/ic-x-lg.svg";

import Radius from "../../../common/Share/Radius";
import { async } from "q";

export function EDBModalDropMenuTitle({order}) {
  return (
    <div>
      {order.highLow ? 
      <FormattedMessage
        id="edb.filter.higher.to.lower"
        defaultMessage="Ordenar por:"
      />
      : null}
      {!order.highLow ? 
      <FormattedMessage
        id="edb.filter.lower.to.higher"
        defaultMessage="Ordenar por:"
      />
      : null}
    </div>
  
  )
  
}
export function EDBModalDropMenu({ setTogglerOrganizer, setOrder, order}) {

  const onOrderSelect = (evt) => {
    evt.preventDefault();
    let value = evt.target.id;

    switch (value) {
      case "HLSale":
        setOrder({
          highLow: true,
          sale: true,
        });
        return;

      case "LHSale":
        setOrder({
          highLow: false,
          sale: true,
        });
        return;
    }

    setTogglerOrganizer(false);
  };

  return (
    <div
      style={{
        zIndex: "10",
        width: "160px",
        top: "30px",
        left: "90px",
        borderRadius: "1rem",
      }}
      className=" shadow position-absolute bg-white "
    >
      <div>
        <p className=" p-3 grayColor text-uppercase s12 museo-sans-500">
          <FormattedMessage
            id="edb.filter.sales"
            defaultMessage="Ordenar por:"
          />
        </p>

        {order.highLow && order.sale ? 
        <p
        id="HLSale"
        onClick={onOrderSelect}
        className="pointer p-3 museo-sans-700 s14"       >
        <FormattedMessage
          id="edb.filter.higher.to.lower"
          defaultMessage="Ordenar por:"
        />
        {/*<FormattedMessage
          id="edb.filter.sales"
          defaultMessage="Ordenar por:"
        />*/}
      </p> 
        : 
        <p
          id="HLSale"
          onClick={onOrderSelect}
          className="pointer p-3 museo-sans-500 s14"       >
          <FormattedMessage
            id="edb.filter.higher.to.lower"
            defaultMessage="Ordenar por:"
          />
          {/*<FormattedMessage
            id="edb.filter.sales"
            defaultMessage="Ordenar por:"
          />*/}
        </p>
        }
        {!order.highLow && order.sale ? 
        <p
        id="LHSale"
        onClick={onOrderSelect}
        className="pointer p-3 museo-sans-700 s14"        >
        <FormattedMessage
          id="edb.filter.lower.to.higher"
          defaultMessage="Ordenar por:"
        />
        {/*<FormattedMessage
          id="edb.filter.sales"
          defaultMessage="Ordenar por:"
        />*/}
      </p> 
        : 
        <p
          id="LHSale"
          onClick={onOrderSelect}
          className="pointer p-3 museo-sans-500 s14"        >
          <FormattedMessage
            id="edb.filter.lower.to.higher"
            defaultMessage="Ordenar por:"
          />
          {/*<FormattedMessage
            id="edb.filter.sales"
            defaultMessage="Ordenar por:"
          />*/}
        </p>
        }
      </div>
    </div>
  );
}

export default function GroupSalesFilter({groupSalesInfo, order, setOrder, toggleOrganizer, setTogglerOrganizer, yearPeriod, semanaActual, dataVentasSemanales, filtrosAplicados}) {

    const dispatch = useDispatch()
  
  const [switchButtons, setSwitchButtons] = useState(true);
  const [selectedWeeks, setSelectedWeeks] = useState([     
    {id: 1, value: true},
    {id: 2, value: true},
    {id: 3, value: true},
    {id: 4, value: true},
    {id: 5, value: false}])
  const [selectedStatusOptions, setSelectedStatusOptions] = useState([     
    {id: 1, value: true},
    {id: 2, value: false},
    {id: 3, value: false},
    {id: 4, value: false}])
  const [week5, setWeek5] = useState(false);
  const [selectedCheckFilter, setSelectedCheckFilter] = useState(0);
  const [showSalesExtraFilters, setShowSalesExtraFilters] = useState(false)
  const [minValue, setMinValue] = useState("0")
  const [maxValue, setMaxValue] = useState("0")
  const [specialCharacterError, setSpecialCharacterError] = useState(false)
  const [emptyFieldError, setEmptyFieldError] = useState(false)
  const [minGreaterThanMaxError, setMinGreaterThanMaxError] = useState(false)
  const currentWeek = useSelector((state) => state.headerData.period.currentWeek );
  const weekSalesInfo = useSelector(
    (state) => state.performanceData.salesByWeek
  );

  const onToggle = () => {
    setTogglerOrganizer(!toggleOrganizer);
  };

  function defineDefaultSelectedWeeks(){
    let selectedWeeksArray = []
    if(semanaActual){
        for (let index = 1; index <= selectedWeeks.length; index++) {
            if(index <= semanaActual){
                selectedWeeksArray.push({id: index, value: true})
            } else {
                selectedWeeksArray.push({id: index, value: false})
            }
        }

        setSelectedWeeks(selectedWeeksArray);
    }
  }

  useEffect(() => {
    groupSalesInfo?.map((element) => {
      return element?.weeklySalesAmounts[4] ? setWeek5(true) : ""
    })

    defineDefaultSelectedWeeks()
  }, [semanaActual, groupSalesInfo]);

  function changeSectionAndFilterType(section, filterType){ 
    setSwitchButtons(section); 
    setSelectedCheckFilter(filterType) 
    setShowSalesExtraFilters(false) 
    setMinValue("0") 
    setMaxValue("0")
    if(section === true){
        defineDefaultSelectedWeeks()
    } else {
        setSelectedStatusOptions([     
            {id: 1, value: true},
            {id: 2, value: true},
            {id: 3, value: true},
            {id: 4, value: true}
        ])
    }
  }

  const buttonWeek = (name, id) => {

    const changeSelectedWeeks = (id) => { 
      const newArray = [];
      selectedWeeks.forEach(elm => {
        if(elm?.id === id){
          if(elm.value && selectedWeeks.filter(element => element.value === true).length > 1){
            elm.value = false 
          }else{
            elm.value = true
          }
        }
        newArray.push(elm);
      });
      setSelectedWeeks(newArray);
    }


    const checkStatus = (id) => { 
      if(selectedWeeks.find(elm => elm?.id === id).value === true){
        return true;
      } 
      return false;
    }

    if(id <= semanaActual){
        return(   
            <button
            name="noAward"
            style={{
              height: "32px",
              width: "256px",
              borderRadius: '1rem',
              backgroundColor: checkStatus(id) ? '#5AAFF1' : '',
              color: checkStatus(id) ? 'white' : '',
            }}
            onClick={() => changeSelectedWeeks(id)}
            className={checkStatus(id) ?
               ' s14 museo-sans-700 skyBorder btnCustom py-1 ' 
               : ' buttonBehavior museo-sans-700 skyBorder skyColor s14 bg-white btnCustom py-1 '}
          >
            <FormattedMessage
              id={name}
              defaultMessage="Entendido"
            />
          </button> 
            )
    } else {
        return(   
            <button
            name="noAward"
            style={{
              borderRadius: '1rem',
              width: "256px",
              height: "32px"
            }}
            onClick={() => changeSelectedWeeks(id)}
            className="border  unnamedGrayColor grayBorder bronceBg s14 museo-sans-700 btnCustom py-1 pointer-events-none"
          >
            <FormattedMessage
              id={name}
              defaultMessage="Entendido"
            />
          </button> 
            )
    }
  }

  function checkAndExtraFilters(check, extra) {
    setSelectedCheckFilter(check)
    setShowSalesExtraFilters(extra)
    setMaxValue("0")
    setMinValue("0")
  }

  const checkFilter = () => {

    return(
      <div className="">
        <div className='d-flex hover-pointer' onClick={() => checkAndExtraFilters(0, false)}>
            <Radius status={selectedCheckFilter === 0 ? true : false} />
            <p className='ms-2 museo-sans-500 s14 blackColor user-select-none'><FormattedMessage id="performance.modal.filter.filter.type.option1" defaultMessage="Todos/as"/></p>
        </div>
        <div className='d-flex hover-pointer mt-3' onClick={() => checkAndExtraFilters(1, false)}>
            <Radius status={selectedCheckFilter === 1 ? true : false} />
            <p className='ms-2 museo-sans-500 s14 blackColor user-select-none'><FormattedMessage id="performance.modal.filter.filter.type.option2" defaultMessage="Sin ventas"/></p>
        </div>
        <div className='d-flex hover-pointer mt-3' onClick={() => selectedCheckFilter !== 2 && showSalesExtraFilters === false ? checkAndExtraFilters(2, true) : null}>
            <Radius status={selectedCheckFilter === 2 || selectedCheckFilter === 3 ? true : false} />
            <p className='ms-2 museo-sans-500 s14 blackColor user-select-none'><FormattedMessage id="performance.modal.filter.filter.type.option3" defaultMessage="Con ventas"/></p>
        </div>
      </div>
    )
  }

  const buttonStatusOption = (name, id, currentWeek) => { // Name = IdlIbrary , id= Static 1/2/3/4 , currentWeek= ??? WeekVar

    const changeSelectedWeeks = (id) => { 
      const newArray = [];
      selectedStatusOptions.forEach(elm => {
        if(elm?.id === id){
          if(elm.value && selectedStatusOptions.filter(element => element.value === true).length > 1){
            elm.value = false 
          }else{
            elm.value = true
          }
        }
        newArray.push(elm);
      });
      setSelectedStatusOptions(newArray);
    }

    const checkStatus = (id) => { 
      if(selectedStatusOptions.find(elm => elm?.id === id).value === true){
        return true;
      } 
      return false;
    }


    return <button
    name="noAward"      
    style={{
      borderRadius: '1rem',
      backgroundColor: checkStatus(id) ? '#5AAFF1' : '',
      color: checkStatus(id) ? 'white' : '',
    }} 
    onClick={() => changeSelectedWeeks(id)}
    className={checkStatus(id) ?
      ' s14 museo-sans-700 skyBorder btnLoginWidth btnCustom py-1 ' 
      : ' buttonBehavior museo-sans-700 skyBorder skyColor s14 btnLoginWidth bg-white btnCustom py-1 '}
    >
    <FormattedMessage
      id={name}
      defaultMessage="Entendido"
      />
  </button>
  }

  const ordersStatus = () =>{
    return(
      <div >
        <div className=" d-flex flex-column align-items-center justify-content-between">
        <div style={{ width: "528px" }} className="mt-4">
          <p className="s14 museo-sans-500 grayColor mb-2">
            <FormattedMessage id="performance.requests.filter.warning" defaultMessage="Debe haber por lo menos una opción seleccionada" />
          </p>
          <div className=" d-flex gap-3 py-1">
            {buttonStatusOption("performance.modal.filter.statusOrders.option1", 1, currentWeek)}
            {buttonStatusOption("performance.modal.filter.statusOrders.option2", 2, currentWeek)}
          </div>
          <div className=" d-flex gap-3 py-1">
            {buttonStatusOption("performance.modal.filter.statusOrders.option3", 3, currentWeek)}
            {buttonStatusOption("performance.modal.filter.statusOrders.option4", 4, currentWeek)}
          </div>
        </div>
      </div>
      <br />
      </div>
    )
  }

  function changeMinValue(e) {
    const limit = 15
    setMinValue(e.target.value.slice(0, limit))
  }

  function changeMaxValue(e) {
    const limit = 15
    setMaxValue(e.target.value.slice(0, limit))
  }
  
  const weekSale = () => {  
    return(
      <div>
        <div style={{ paddingLeft: "40px", paddingTop: "28px"}}>
        {checkFilter()}
        {
            showSalesExtraFilters ?
                <div className="d-flex flex-column justify-content-start mt-3">
                    <p className="s12 museo-sans-700 grayColor"><FormattedMessage id="performance.modal.filter.sales.rank" defaultMessage="Rango de ventas PVP"/></p>
                    <div className="d-flex gap-2">
                        <div className='d-flex hover-pointer mt-3' 
                            onClick={() => {
                                setSelectedCheckFilter(2)
                                setMaxValue("0")
                                setMinValue("0")                            }}>
                            <Radius status={selectedCheckFilter === 2 ? true : false} />
                            <p className='ms-2 museo-sans-500 s14 blackColor user-select-none'><FormattedMessage id="performance.modal.filter.sales.rank.weekly" defaultMessage="Por semana"/></p>
                        </div>
                        <div className='d-flex hover-pointer mt-3' onClick={() => setSelectedCheckFilter(3)}>
                            <Radius status={selectedCheckFilter === 3 ? true : false} />
                            <p className='ms-2 museo-sans-500 s14 blackColor user-select-none'><FormattedMessage id="performance.modal.filter.sales.rank.accumulated" defaultMessage="Acumulado"/></p>
                        </div>
                    </div>
                </div>
            :
                null
        }
          
      </div>
      <div className=" d-flex flex-column align-items-center justify-content-between">
        <div style={{ width: "528px" }} className="mt-4">
        <>
        {
          (selectedCheckFilter == 2 || selectedCheckFilter == 3) &&
                    <div className="d-flex gap-3">
                        <div className="d-flex flex-column gap-2 w-50">
                            <label htmlFor="minValue" className="museo-sans-500 s14 blackColor ms-3"><FormattedMessage id="performance.week.filter.min.value.label" defaultMessage="Mínimo" /></label>
                            <div className="d-flex performance-filter-input align-items-center ps-3">                       
                                <p className="museo-sans-500 s14 blackColor user-select-none">
                                <FormattedMessage id="currency"/>
                                </p>
                                <input className="museo-sans-500 s16 blackColor button-border-none ms-1 no-outline-input" type="number" value={minValue} onChange={(e) => changeMinValue(e)}
                                onClick={() => {
                                    if(minValue === "0"){
                                        setMinValue("")
                                    }
                                }}
                                />
                            </div>
                            
                        </div>
                        <div className="d-flex flex-column gap-2 w-50">
                            <label htmlFor="minValue" className="museo-sans-500 s14 blackColor ms-3"><FormattedMessage id="performance.week.filter.max.value.label" defaultMessage="Máximo" /></label>
                            <div className="d-flex performance-filter-input align-items-center ps-3">
                                <p className="museo-sans-500 s14 blackColor user-select-none"> <FormattedMessage id="currency"/></p>
                                <input className="museo-sans-500 s16 blackColor button-border-none ms-1 no-outline-input" type="number" value={maxValue} onChange={(e) => changeMaxValue(e)}
                                onClick={() => {
                                    if(maxValue === "0"){
                                        setMaxValue("")
                                    }
                                }}
                                />
                            </div>
                            
                        </div>
                    </div>

        }
                    {
                        specialCharacterError ? 
                            <p className="museo-sans-500 s14 redColor"><FormattedMessage id="performance.modal.filter.special.character.error" defaultMessage="Solo se pueden usar números, sin caracteres especiales, a excepción del +" /></p>
                        :
                            emptyFieldError ? 
                            <p className="museo-sans-500 s14 redColor"><FormattedMessage id="performance.modal.filter.empty.space.error" defaultMessage="No puede haber espacios en blanco" /></p>
                        :
                            minGreaterThanMaxError ?
                            <p className="museo-sans-500 s14 redColor"><FormattedMessage id="performance.modal.filter.min.max.error" defaultMessage="El monto máximo no puede ser menor que el monto mínimo" /></p>
                        :
                            null
                    }
                </>

<>
                    <p className=" grayColor py-2 museo-sans-700 s12 text-start mt-2">
                        <FormattedMessage
                        id="performance.modal.filter.inWeeks"
                        defaultMessage="En semanas"
                        />
                    </p>
                    <p className="s14 museo-sans-500 grayColor mb-2">
                      <FormattedMessage id="performance.week.filter.warning" defaultMessage="Debe haber por lo menos una semana seleccionada" />
                    </p>
                    <div className=" d-flex gap-3 py-1">
                        {buttonWeek("performance.modal.filter.week1", 1)}
                        {buttonWeek("performance.modal.filter.week2", 2)}
                    </div>
                    <div className=" d-flex gap-3  py-1">
                        {buttonWeek("performance.modal.filter.week3", 3)}
                        {buttonWeek("performance.modal.filter.week4", 4)}
                    </div>
                    <div className=" d-flex gap-3  p-1">
                        { weekSalesInfo?.totalWeeks > 4 ? buttonWeek("performance.modal.filter.week5", 5) : ""}
                    </div>
                </>
          
        </div>
      </div>
      <br />
    </div>
    )
  }

  function defineFilterType(value){
    switch (value) {
        case 0:
            return ""
        case 1:
            return "noSales"
        case 2:
            return "weeklySales"
        case 3:
            return "periodicSales"
        case 4:
            return "orders"
        default:
            return "";
    }
  }

  function defineHighLow(value){
    switch (value) {
        case true:
            return "billed.desc"
        case false:
            return "billed.asc"
        default:
            return null;
    }
  }

  function translateOrder(value){
    switch (value) {
        case 1:
            return "noRequests"
        case 2:
            return "notSent"
        case 3:
            return "toInvoice"
        case 4:
            return "billed"
        default:
            return null;
    }
  }

  function defineOrders(ordersArray){
    const selected = ordersArray.filter(element => element.value === true)
    let stringForService = ""
    for (let index = 0; index < selected.length; index++) {
       stringForService += translateOrder(selected[index].id)
        if(index !== selected.length - 1){
            stringForService += ","
        }
    }
    return stringForService
  }

  function defineWeeks(weeksArray){
    const selected = weeksArray.filter(element => element.value === true)
    let stringForService = ""

    for (let index = 0; index < selected.length; index++) {
        stringForService += String(selected[index].id)
        if(index !== selected.length - 1){
            stringForService += ","
        }
    }
    
    return stringForService
  }

  function showSpecialCharacterError(){
    setSpecialCharacterError(true)
    setTimeout(() => {
        setSpecialCharacterError(false)
    }, 3000);
  }

  function showEmptyFieldError(){
    setEmptyFieldError(true)
    setTimeout(() => {
        setEmptyFieldError(false)
    }, 3000);
  }

  function showMinGreaterThanMaxError(){
    setMinGreaterThanMaxError(true)
    setTimeout(() => {
        setMinGreaterThanMaxError(false)
    }, 3000);
  }
      
  const saveOrder = async () => {
    const newOrder = order;
    newOrder.sale = switchButtons;
    newOrder.checkFilter = selectedCheckFilter;
    newOrder.selectedWeeks = selectedWeeks;
    newOrder.selectedStatusOptions = selectedStatusOptions;
    const isMinNumber = minValue
    if(isNaN(Number(minValue)) || isNaN(Number(maxValue)) || minValue.includes("-") || minValue.includes("+") || minValue.includes(".") || maxValue.includes("-") || maxValue.includes("+") || maxValue.includes(".")){
        
        showSpecialCharacterError()
    } else if(false){ // minValue === "" || maxValue === ""
        
        showEmptyFieldError()
    } else if(false){ // Number(minValue) > Number(maxValue)
        showMinGreaterThanMaxError()
    } else {
      let result = "";
        if(window.location?.pathname?.indexOf("/dashboard/performance/groupSales") !== -1) {
        dispatch(groupSalesList({yearPeriod: yearPeriod, sortBy: defineHighLow(newOrder.highLow), filterType: defineFilterType(newOrder.checkFilter), orders: defineOrders(newOrder.selectedStatusOptions), weeks: defineWeeks(newOrder.selectedWeeks), amountMin: Number(minValue), amountMax: Number(maxValue)}))

        } else if (window.location?.pathname?.indexOf("/dashboard/performance/personalIncorporated") !== -1) {
        dispatch(incorporationsSalesList({yearPeriod: yearPeriod, sortBy: defineHighLow(newOrder.highLow), filterType: defineFilterType(newOrder.checkFilter), orders: defineOrders(newOrder.selectedStatusOptions), weeks: defineWeeks(newOrder.selectedWeeks), amountMin: Number(minValue), amountMax: Number(maxValue)}))

        } else if(window.location?.pathname?.indexOf("/dashboard/performance/descendantSales") !== -1) {
          result = await dispatch(descendantSalesList({yearPeriod: yearPeriod, sortBy: defineHighLow(newOrder.highLow), filterType: defineFilterType(newOrder.checkFilter), orders: defineOrders(newOrder.selectedStatusOptions), weeks: defineWeeks(newOrder.selectedWeeks), amountMin: Number(minValue), amountMax: Number(maxValue)}))
        }

        dispatch(changeFilters({yearPeriod: yearPeriod, sortBy: defineHighLow(newOrder.highLow), filterType: defineFilterType(newOrder.checkFilter), orders: defineOrders(newOrder.selectedStatusOptions), weeks: defineWeeks(newOrder.selectedWeeks), amountMin: Number(minValue), amountMax: Number(maxValue)}))
        dispatch(defineFiltersApplied(true))
        setOrder(newOrder)

    }
    
  }

  function eraseOrder() {
    if(window.location?.pathname?.indexOf("/dashboard/performance/groupSales") !== -1) {
        dispatch(groupSalesList({yearPeriod: yearPeriod, sortBy: null, filterType: null, orders: null, weeks: null}))

    } else if (window.location?.pathname?.indexOf("/dashboard/performance/personalIncorporated") !== -1) {
        dispatch(incorporationsSalesList({yearPeriod: yearPeriod, sortBy: null, filterType: null, orders: null, weeks: null}))

    } else if(window.location?.pathname?.indexOf("/dashboard/performance/descendantSales") !== -1) {
        dispatch(descendantSalesList({yearPeriod: yearPeriod, sortBy: null, filterType: null, orders: null, weeks: null}))
    }
    dispatch(defineFiltersApplied(false))
    dispatch(resetFilters())
    setSelectedWeeks([
           
    {id: 1, value: true},
    {id: 2, value: true},
    {id: 3, value: true},
    {id: 4, value: true},
    {id: 5, value: true}
    ]);
    setMaxValue("0");
    setMinValue("0");
    setSelectedCheckFilter(0);
    setShowSalesExtraFilters(false);
    setSelectedStatusOptions(
      [
        {id: 1, value: true},
            {id: 2, value: true},
            {id: 3, value: true},
            {id: 4, value: true}
      ]
    )
  }

  return (
    <div 
      className="modal fade"
      id="modalEDBFilter"
      aria-hidden="true"
      aria-labelledby="modalEDBFilter"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "608px" }}
      >
        <div className="modal-content modal-radius">
          <div className="modal-body p-0">
            <div>
              <div className="d-flex justify-content-between p-4 m-3 align-items-center">
                <div className="d-flex">
                  <h5 className="museo-sans-500 s20 m-0">
                    <FormattedMessage
                      id="performance.modal.filter.title"
                      defaultMessage="Entendido"
                    />
                  </h5>
                </div>
                <div className=" pointer " data-bs-dismiss="modal" aria-label="Close">
                    <SVGClose />
                </div>
              </div>
              <hr className="m-0"></hr>
              <br />
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="d-flex flex-wrap justify-items-center align-items-center lightVioletBg p-1 rounded-3" style={{width: "528px"}}>
                      <div onClick={() => changeSectionAndFilterType(true, 0)} className={`${switchButtons ? "bronceBg" : "hover-pointer"} rounded-3 ps-4 pe-4 pt-1 pb-1 d-flex align-items-center w-50 justify-content-center`}>
                          <DollarIcon />
                          <p className='ms-2 museo-sans-700 s14 lightBlueColor user-select-none'><FormattedMessage id="group.incorporations.descendant.switch.week.sales" defaultMessage="Venta semanal" /></p>
                      </div>
                      <div onClick={() => changeSectionAndFilterType(false, 4)} className={`${!switchButtons ? "bronceBg" : "hover-pointer"} rounded-3 ps-4 pe-4 pt-1 pb-1 d-flex align-items-center w-50 justify-content-center`}>
                          <RequestsIcon />
                          <p className='ms-2 museo-sans-700 s14 lightBlueColor user-select-none'><FormattedMessage id="group.incorporations.descendant.switch.request.status" defaultMessage="Status pedidos" /></p>
                      </div>
              </div>
                <div
                  style={{ width: "528px", maxHeight: "639px" }}
                  className="mt-4">
                  <div className='d-flex justify-content-between'>
                  </div>
                  <div className=" py-1 s14 museo-sans-700 position-relative d-flex mb-3">
                    <p className="">
                      <FormattedMessage
                        id="edb.filter.dropdown.p"
                        defaultMessage="Ordenar por:"
                      />
                    </p>
                    {toggleOrganizer && (
                      <EDBModalDropMenu
                        setTogglerOrganizer={setTogglerOrganizer}
                        setOrder={setOrder}
                        order={order}
                      />
                    )}

                    <p onClick={onToggle} className=" pointer skyColor px-2">
                        <EDBModalDropMenuTitle order={order} />
                    </p>
                    <i className="bi bi-chevron-down text-info px-0"></i>
                  </div>
                <hr className="m-0"></hr>
              </div>
            </div>
            {switchButtons ? weekSale() : ordersStatus()}
          </div>
          <div className="d-flex flex-column align-items-center">
              <div className="d-flex flex-column flex-wrap justify-items-center align-items-center mb-5">
                {
                    isNaN(Number(minValue)) || isNaN(Number(maxValue)) || minValue === "" || maxValue === "" || minValue.includes("-") || minValue.includes(".") || minValue.includes("+")|| maxValue.includes("-") || maxValue.includes("+") || maxValue.includes(".") || Number(minValue) > Number(maxValue) ?
                        <button
                            type="button"
                            className=" btnLoginWidth mt-2 museo-sans-700 s14 whiteColor skyBg skyBorder button-hover-light-text button-bootstrap-radius button-padding"
                            onClick={() => saveOrder()}
                            >
                            <FormattedMessage
                                id="buttons.apply"
                                defaultMessage="Aplicar"
                            />
                        </button>
                    :

                        <button
                            type="button"
                            className=" btnLoginWidth mt-2 museo-sans-700 s14 whiteColor skyBg skyBorder button-hover-light-text button-bootstrap-radius button-padding"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => saveOrder()}
                            >
                            <FormattedMessage
                                id="buttons.apply"
                                defaultMessage="Aplicar"
                            />
                        </button>

                }
                {
                    (filtrosAplicados || (selectedWeeks[0].value == false || selectedWeeks[1].value == false || selectedWeeks[2].value == false || selectedWeeks[3].value == false || minValue != 0 || maxValue != 0 || selectedCheckFilter != 0 ) ) ?
                        <button
                            type="button"
                            className=" btnLoginWidth mt-2 museo-sans-700 s14 skyColor bronceBg skyBorder button-hover-light-text button-bootstrap-radius button-padding"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => eraseOrder()}
                        >
                        <FormattedMessage
                            id="buttons.remove.filters"
                            defaultMessage="Limpiar filtros"
                        />
                        </button>
                    :
                        <button
                            type="button"
                            className=" btnLoginWidth mt-2 museo-sans-700 s14 brightGrayColor bronceBg brightGrayBorder  button-bootstrap-radius button-padding pointer-events-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                        <FormattedMessage
                            id="buttons.remove.filters"
                            defaultMessage="Limpiar filtros"
                        />
                        </button>
                }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
