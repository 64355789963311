import { FormattedMessage } from "react-intl";

import { useEffect, useRef } from "react";

import { ReactComponent as Edbs } from "../../../../common/svgs/ic-new-edb.svg";
import { ReactComponent as NewOrder } from "../../../../common/svgs/ic-new-order.svg";
import { ReactComponent as AddPerson } from "../../../../common/svgs/ic-add-person.svg";
import { ReactComponent as SVGExternal } from "../../../../common/svgs/ic-external-link-md.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchExtLinks } from "../../Framework/HeaderSideEffect";
import { useMediaPredicate } from "react-media-hook";

import { useNavigate } from "react-router-dom";
import { useOnClickOutside } from "../../../Share/ShareFunctionUseOnClickOutside";
import { clearClientDetailsForm } from "../../../../EDBs/Framework/redux/EDBsActions";
import ShareFeaturedChildren from "../../../Share/ShareFeaturedChildren";

export default function HeaderNewBtn({ toggler, invitationToggler }) {
  const ref = useRef();
  const dispatch = useDispatch();
  const history = useNavigate();
  const externalLinks = useSelector((state) => state.headerData.extLinks);
  useOnClickOutside(ref, () => toggler(false));

  const biggerThan1440 = useMediaPredicate("(min-width: 1440px)");
  const biggerThan1800 = useMediaPredicate("(min-width: 1800px)");

  const onStartUp = async () => {
    await dispatch(fetchExtLinks());
  };

  const onClickCreateInvitation = () => {
    history("invitations/add");
  };

  const onClickEDB = async () => {
    localStorage.removeItem("formBody");
    localStorage.removeItem("formBodyType");
    await dispatch(clearClientDetailsForm());
    history("/dashboard/edbs/form/type/present");
  };

  const stylesByResolution = () => {
    if (biggerThan1800) {
      return { width: "288px", right: "80px" };
    } else if (biggerThan1440) {
      return { width: "288px", right: "55px" };
    } else {
      return { width: "288px", right: "35px" };
    }
  };

  useEffect(() => {
    onStartUp();
  }, []);

  const JSXRow = (
    <>
      {invitationToggler == "true" && (
        <>
          <li>
            <a
              target="_blank"
              style={{ justifySelf: "center" }}
              href={externalLinks?.links[1]?.link}
            >
              <div className="p-0 model-plus--row">
                <i className="s16">
                  <AddPerson />
                </i>
                <p className="museo-sans-500 s14 blackColor mb-0 ">
                  <FormattedMessage
                    id="header.button.new.two"
                    defaultMessage="New incorporation"
                  />
                </p>
                <div style={{ justifySelf: "center" }}>
                  <SVGExternal />
                </div>
              </div>
            </a>
          </li>
          <hr></hr>
        </>
      )}
    </>
  );

  return (
    <div ref={ref} id="header--model-plus" className=" position-absolute">
      {externalLinks?.links && (
        <ul
          style={stylesByResolution()}
          className="  shadow bg-white p-3 m-0 list-unstyled"
        >
          <ShareFeaturedChildren keyType="CREATE_EDB">
            <>
              <li onClick={onClickEDB}>
                <div className="p-0 model-plus--row pointer ">
                  <div style={{ width: "20px" }} className=" svg-force-size ">
                    <Edbs />
                  </div>
                  <p className=" s14 museo-sans-500 blackColor mb-0 ">
                    <FormattedMessage
                      id="header.button.new.one"
                      defaultMessage="Crear EDB"
                    />
                  </p>
                  <div className=" invisible" style={{ justifySelf: "center" }}>
                    <SVGExternal />
                  </div>
                </div>
              </li>
              <hr></hr>
            </>
          </ShareFeaturedChildren>
          <ShareFeaturedChildren keyType="CREATE_EDB" falsePositive={true}>
            <>
              <li>
                <div
                  data-bs-toggle="modal"
                  data-bs-target="#modalEDB"
                  className="p-0 model-plus--row pointer "
                >
                  <div style={{ width: "20px" }} className=" svg-force-size ">
                    <Edbs />
                  </div>
                  <p className=" s14 museo-sans-500 blackColor mb-0 ">
                    <FormattedMessage
                      id="header.button.new.one"
                      defaultMessage="Crear EDB"
                    />
                  </p>
                  <div style={{ justifySelf: "center" }}>
                    <SVGExternal />
                  </div>
                </div>
              </li>
              <hr></hr>
            </>
          </ShareFeaturedChildren>

          <ShareFeaturedChildren keyType="INCORPORATIONS">
            <>
              {invitationToggler == "true" ? (
                <>
                  <li>
                    <a
                      onClick={onClickCreateInvitation}
                      style={{ justifySelf: "center" }}
                      className="pointer"
                    >
                      <div className="p-0 model-plus--row">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="22"
                            viewBox="0 0 24 22"
                            fill="none"
                          >
                            <path
                              d="M15.6564 10.3552C11.0347 10.3552 7.31055 14.4632 7.31055 19.5994V19.6329C7.31055 19.6804 7.31065 19.7249 7.31672 19.7714C7.33575 20.0718 7.41379 20.3654 7.54648 20.6357C7.67917 20.9059 7.86383 21.1474 8.08992 21.3462C8.31602 21.545 8.57912 21.6971 8.86411 21.7941C9.14911 21.8911 9.45044 21.9309 9.75084 21.9113H21.5619C21.6054 21.9113 21.6487 21.9113 21.6932 21.9113C21.9967 21.9109 22.2974 21.8507 22.5777 21.7341C22.858 21.6176 23.1124 21.4469 23.3267 21.2319C23.541 21.0169 23.711 20.762 23.8267 20.4814C23.9424 20.2008 24.0015 19.9 24.001 19.5964C24.001 14.4632 20.2791 10.3552 15.6564 10.3552Z"
                              fill="#171A22"
                            />
                            <path
                              d="M15.6554 9.07132C18.137 9.07132 20.1487 7.0596 20.1487 4.57802C20.1487 2.09644 18.137 0.0847168 15.6554 0.0847168C13.1738 0.0847168 11.1621 2.09644 11.1621 4.57802C11.1621 7.0596 13.1738 9.07132 15.6554 9.07132Z"
                              fill="#171A22"
                            />
                            <path
                              d="M7.50265 7.9574H1.01099"
                              stroke="#171A22"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M4.25781 11.2073V4.7135"
                              stroke="#171A22"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                        <p className="museo-sans-500 s14 blackColor mb-0 ">
                          <FormattedMessage id="header.button.invite.new.incorp" />
                        </p>
                        <div
                          className=" invisible"
                          style={{ justifySelf: "center" }}
                        >
                          <SVGExternal />
                        </div>
                      </div>
                    </a>
                  </li>
                  <hr></hr>
                </>
              ) : (
                <>{JSXRow}</>
              )}
            </>
          </ShareFeaturedChildren>

          <ShareFeaturedChildren keyType="INCORPORATIONS" falsePositive={true}>
            <>{JSXRow}</>
          </ShareFeaturedChildren>

          <li>
            <a
              target="_blank"
              style={{ justifySelf: "center" }}
              href={externalLinks?.links[2]?.link}
            >
              <div className="p-0 model-plus--row">
                <i className="s16">
                  <NewOrder />
                </i>
                <p className="s14 museo-sans-500 blackColor mb-0 ">
                  <FormattedMessage
                    id="header.button.new.four"
                    defaultMessage="Load purchase order"
                  />
                </p>
                <div style={{ justifySelf: "center" }}>
                  <SVGExternal />
                </div>
              </div>
            </a>
          </li>
        </ul>
      )}
    </div>
  );
}
