import ShareFeaturedChildren from "../../../common/Share/ShareFeaturedChildren";
import InvitationsDashboardShowcase from "../../../Invitations/Presentation/pages/InvitationsDashboardShowcase";

export default function DashboardInvitationsContainer({invitationToggler, directorId}) {


    return(
        <ShareFeaturedChildren keyType="INCORPORATIONS" >
          <>
            {
              (invitationToggler === "true" && !directorId ) &&
              <InvitationsDashboardShowcase />

            }
          </>
          </ShareFeaturedChildren>
        
    )
}