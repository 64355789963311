import { ReactComponent as WarningSVG } from "../../Ecommerce/Presentation/icons/WarningRed.svg";

export default function ShareModalWarningMessage(params) {
  const ifButtonConfirmHasFunction = () => {
    params?.onButtonConfirmedClick("completed");
  };

  return (
    <div
      style={{ padding: "0px 44px 32px 44px" }}
      className="d-flex flex-column justify-content-center align-items-center museo-sans-600 "
    >
      <div
        style={{ marginTop: "40px", marginBottom: "16px" }}
        className=" position-relative"
      >
        <div className=" position-relative d-flex justify-content-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="104"
            height="104"
            viewBox="0 0 104 104"
            fill="none"
          >
            <circle cx="52" cy="52" r="52" fill="#FDF0F0" />
          </svg>
          <div className=" position-absolute d-flex align-items-center h-100">
            <WarningSVG />
          </div>
        </div>
      </div>
      <div
        style={{ gap: "12px", marginBottom: "32px" }}
        className="d-flex flex-column text-center "
      >
        <p className=" s24 museo-sans-600 ">
          {params?.title ?? "Tienes que cargar una foto de perfil en la App"}
        </p>
        <p className=" s16 museo-sans-400 grayColor ">
          {params?.description ??
            "Hemos identificado que no cargaste una foto de perfil al momento de registrarte en la App y es obligatorio tener una foto de perfil para activar tu Tienda Virtual."}
        </p>
      </div>
      <div className="d-flex flex-column gap-3 text-center ">
        <div
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={ifButtonConfirmHasFunction}
          className=" pointer btnLoginWidth border-1 skyBorder skyColor s14 rounded-3 d-flex justify-content-center align-items-center "
        >
          {params?.buttonConfirmedMessage || "Cargar foto de perfil"}
        </div>
        {params?.buttonIsCanceled && (
          <div
            data-bs-dismiss="modal"
            aria-label="Close"
            className=" pointer btnLoginWidth border-1 redBorder lightRedColor  s14 rounded-3 d-flex justify-content-center align-items-center "
          >
            {params?.buttonCanceledMessage || "Cancelar"}
          </div>
        )}
      </div>
    </div>
  );
}
