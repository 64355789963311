import { ReactComponent as SVGInfo } from "../icons/Info.svg";
import { ReactComponent as SVGChevArrowDown } from "../../../common/svgs/ic-chevron-down-md.svg";

export default function EcommerceFormCustomHolder(params) {

    return(
        <div style={{width: "600px"}} className="d-flex flex-column gap-4 museo-sans-400" >
                <div className=" museo-sans-400 s20" >
                Personaliza tu Tienda Virtual
                </div>
                <div className="s14 bgWarningHelpMessage museo-sans-400 p-3" >
                No te olvides de escribir la historia por la cual te sumaste a Just, cuenta qué te motivó y
                por qué eliges Just, o cualquier historia que te identifique para que tus clientes te conozcan mejor.
                </div>
                <div className=" museo-sans-400 s20" >
                Tu Historia
                </div>
                <div className="d-flex flex-column gap-2" >
                <p className="s14 museo-sans-600" >
                    Frase Just
                </p>
                <div className="d-flex flex-column gap-1" >
                    <div
                    data-bs-toggle="modal"
                    data-bs-target="#form-select-phrase"
                    className={` ${(params?.formData?.body?.phrase?.value?.title || params?.ecommerceData?.data?.phrase ) ? "" : "grayColor"} pointer w-100 s14 d-flex justify-content-between rounded-3 input-border px-3 py-2`} >
                        <p  >
                            {params?.formData?.body?.phrase?.value?.title ? params?.formData?.body?.phrase?.value?.title : params?.ecommerceData?.data?.phrase || "Seleccionar frase"}
                        </p>
                        <div>
                            <SVGChevArrowDown />
                        </div>
                    </div>
                    <div className="d-flex gap-1 s12 museo-sans-400" >
                        <div>
                            <SVGInfo />
                        </div>
                        <p>
                        Elije la frase de just que quieras que te represente
                        </p>
                    </div>
                </div>
                </div>
                <div className="w-100" >
                    <div className="d-flex justify-content-between s14" >
                        <p>
                            Cuenta tu historia (opcional)
                        </p>
                        <div className=" dm-sans-medium " >
                            <p>
                                {params?.formData?.body?.history?.value?.length ?? 0}/480
                            </p>

                        </div>
                    </div>
                    <textarea onChange={(evt) => params?.onChange("history.value", evt.target.value )} value={params?.formData?.body?.history?.value} maxLength={480} style={{padding: "16px 12px 16px 12px", height: "144px", resize: "none", outline: "none"}} className="input-border rounded-3 museo-sans-400 w-100 s14 museo-sans-400" placeholder="Ej.: Placeholder" />
                </div>
            </div>
    )
}   