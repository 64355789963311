import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { ReactComponent as Edbs } from "../../../svgs/ic-edbs.svg";
import { ReactComponent as SVGClient } from "../img/client-svg.svg";

import {
  faUser,
  faAward,
  faChartPie,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons";
import { clearClientDetailsForm } from "../../../../EDBs/Framework/redux/EDBsActions";
import { useDispatch } from "react-redux";
import ShareFeaturedChildren from "../../../Share/ShareFeaturedChildren";

export default function NavBarFirstRow({
  activeBackground,
  onHistory,
  location,
  decenderPersonalProfile,
  showDropdownOne,
  showDropdownTwo,
  showDropdownThree,
  dropdownOne,
  dropdownTwo,
  dropdownThree,
  profileData,
  activeBackgroundOnEDBIf,
  activeBackgroundOnDashboardIf,
  invitationToggler,
}) {
  const directorId = localStorage.getItem("directorId");
  const dispatch = useDispatch();

  const onEDBForm = () => {
    localStorage.removeItem("formBody");
    localStorage.removeItem("formBodyType");
    dispatch(clearClientDetailsForm());
    onHistory("/dashboard/edbs/form/type/present", true);
  }

  return (
    <ul className="nav--btn-holder position-relative w-100">
      <div
        onClick={showDropdownOne}
        style={
          activeBackgroundOnDashboardIf()
            ? { background: activeBackground }
            : { background: "transparent" }
        }
        className=" position-relative nav-info"
      >
        <div className=" position-absolute w-75 h-100"></div>
        <i className="s14">
          <FontAwesomeIcon icon={faChartPie} />
        </i>
        <div className="nav-desc ">
          <p>
            <FormattedMessage
              id="nav.label.one.index"
              defaultMessage="Performance"
            />
          </p>
        </div>
        <i className="bi bi-chevron-down nav-desc ms-auto"></i>
      </div>
      {dropdownOne ? (
        <>
          <div
            onClick={() => onHistory("/dashboard")}
            className="nav-info navbar--dropdown-menu"
          >
            <aside></aside>
            <p className="nav-item-desc">
              <FormattedMessage id="navbar.dashboard.landing" />
            </p>
          </div>
          {profileData.level < 2 ? (
            <>
              <div
                onClick={() =>
                  onHistory("/dashboard/performance/personalSales")
                }
                className="nav-info navbar--dropdown-menu"
              >
                <aside></aside>
                <p className="nav-item-desc">
                  <FormattedMessage
                    id="nav.label.one.a"
                    defaultMessage="Personal sales"
                  />
                </p>
              </div>
              <div
                onClick={() =>
                  onHistory("/dashboard/performance/personalIncorporated")
                }
                className="nav-info navbar--dropdown-menu"
              >
                <aside></aside>
                <p className=" nav-item-desc">
                  <FormattedMessage
                    id="nav.label.one.b"
                    defaultMessage="Personal incorporation"
                  />
                </p>
              </div>
            </>
          ) : (
            <>
              {profileData.level < 3 ? (
                <>
                  <div
                    onClick={() =>
                      onHistory("/dashboard/performance/personalSales")
                    }
                    className="nav-info navbar--dropdown-menu"
                  >
                    <aside></aside>
                    <p className="nav-item-desc">
                      <FormattedMessage
                        id="nav.label.one.a"
                        defaultMessage="Personal sales"
                      />
                    </p>
                  </div>
                  <div
                    onClick={() =>
                      onHistory("/dashboard/performance/personalIncorporated")
                    }
                    className="nav-info navbar--dropdown-menu"
                  >
                    <aside></aside>
                    <p className=" nav-item-desc">
                      <FormattedMessage
                        id="nav.label.one.b"
                        defaultMessage="Personal incorporation"
                      />
                    </p>
                  </div>
                  <div
                    onClick={() =>
                      onHistory("/dashboard/performance/groupSales")
                    }
                    className="nav-info navbar--dropdown-menu"
                  >
                    <aside></aside>
                    <p className=" nav-item-desc">
                      <FormattedMessage
                        id="nav.label.one.c"
                        defaultMessage="Group Sales"
                      />
                    </p>
                  </div>
                  {profileData.level > 1 && (
                    <div
                      onClick={() =>
                        onHistory("/dashboard/performance/qualifiedGroups")
                      }
                      className="nav-info navbar--dropdown-menu"
                    >
                      <aside></aside>
                      <p className=" nav-item-desc">
                        <FormattedMessage
                          id="potential.leaders"
                          defaultMessage="Potential leader"
                        />
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {profileData.level < 6 ? (
                    <>
                      <div
                        onClick={() =>
                          onHistory("/dashboard/performance/personalSales")
                        }
                        className="nav-info navbar--dropdown-menu"
                      >
                        <aside></aside>
                        <p className="nav-item-desc">
                          <FormattedMessage
                            id="nav.label.one.a"
                            defaultMessage="Personal sales"
                          />
                        </p>
                      </div>
                      <div
                        onClick={() =>
                          onHistory(
                            "/dashboard/performance/personalIncorporated"
                          )
                        }
                        className="nav-info navbar--dropdown-menu"
                      >
                        <aside></aside>
                        <p className=" nav-item-desc">
                          <FormattedMessage
                            id="nav.label.one.b"
                            defaultMessage="Personal incorporation"
                          />
                        </p>
                      </div>
                      <div
                        onClick={() =>
                          onHistory("/dashboard/performance/groupSales")
                        }
                        className="nav-info navbar--dropdown-menu"
                      >
                        <aside></aside>
                        <p className=" nav-item-desc">
                          <FormattedMessage
                            id="nav.label.one.c"
                            defaultMessage="Group Sales"
                          />
                        </p>
                      </div>
                      <div
                        onClick={() =>
                          onHistory("/dashboard/performance/qualifiedGroups")
                        }
                        className="nav-info navbar--dropdown-menu"
                      >
                        <aside></aside>
                        <p className=" nav-item-desc">
                          <FormattedMessage
                            id="nav.label.one.f"
                            defaultMessage="Qualified groups"
                          />
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        onClick={() =>
                          onHistory("/dashboard/performance/personalSales")
                        }
                        className="nav-info navbar--dropdown-menu"
                      >
                        <aside></aside>
                        <p className="nav-item-desc">
                          <FormattedMessage
                            id="nav.label.one.a"
                            defaultMessage="Personal sales"
                          />
                        </p>
                      </div>
                      <div
                        onClick={() =>
                          onHistory(
                            "/dashboard/performance/personalIncorporated"
                          )
                        }
                        className="nav-info navbar--dropdown-menu"
                      >
                        <aside></aside>
                        <p className=" nav-item-desc">
                          <FormattedMessage
                            id="nav.label.one.b"
                            defaultMessage="Personal incorporation"
                          />
                        </p>
                      </div>
                      <div
                        onClick={() =>
                          onHistory("/dashboard/performance/groupSales")
                        }
                        className="nav-info navbar--dropdown-menu"
                      >
                        <aside></aside>
                        <p className=" nav-item-desc">
                          <FormattedMessage
                            id="nav.label.one.c"
                            defaultMessage="Group Sales"
                          />
                        </p>
                      </div>
                      <div
                        style={
                          location?.pathname?.indexOf(
                            "/dashboard/performance/descendantSales"
                          ) != -1
                            ? { background: activeBackground }
                            : {}
                        }
                        onClick={() =>
                          onHistory("/dashboard/performance/descendantSales")
                        }
                        className="nav-info navbar--dropdown-menu"
                      >
                        <aside></aside>
                        <p className=" nav-item-desc">
                          <FormattedMessage
                            id="nav.label.one.d"
                            defaultMessage="Descendant Sales"
                          />
                        </p>
                      </div>
                      <div
                        onClick={() =>
                          onHistory("/dashboard/performance/qualifiedGroups")
                        }
                        className="nav-info navbar--dropdown-menu"
                      >
                        <aside></aside>
                        <p className=" nav-item-desc">
                          <FormattedMessage
                            id="nav.label.one.f"
                            defaultMessage="Qualified groups"
                          />
                        </p>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : null}
      <div
        style={
          location?.pathname?.indexOf("/dashboard/goodpractice") != -1
            ? { background: activeBackground }
            : {}
        }
        onClick={() => onHistory("/dashboard/goodpractice")}
        className="nav-info"
      >
        <i className="s16">
          <FontAwesomeIcon icon={faAward} />
        </i>
        <div className="nav-desc  ">
          <FormattedMessage
            id="nav.label.two.index"
            defaultMessage="Great Practice"
          />
        </div>
      </div>
      <ShareFeaturedChildren keyType="CLIENTS">
      <>
          {!directorId && (
            <div
              style={
                location?.pathname?.indexOf("/dashboard/clientlist") != -1
                  ? { background: activeBackground }
                  : {}
              }
              onClick={() => onHistory("/dashboard/clientlist")}
              className="nav-info"
            >
              <i style={{ top: -3 }} className=" position-relative s16">
                <SVGClient />
              </i>
              <div className="nav-desc museo-sans-500 ">
                <FormattedMessage id="client.navbar.clients" />
              </div>
            </div>
          )}
        </>
      </ShareFeaturedChildren>
     
      <div
        onClick={showDropdownTwo}
        style={
          activeBackgroundOnEDBIf() ? { background: activeBackground } : {}
        }
        className=" position-relative nav-info"
      >
        <div className=" position-absolute w-75 h-100"></div>
        <i
          style={{ top: "-2px", width: "18px", height: "18px" }}
          className=" position-relative s16"
        >
          <Edbs />
        </i>
        <div className="nav-desc ">
          <FormattedMessage
            id="nav.label.three.index"
            defaultMessage="Well-Being Meetings"
          />
        </div>
        <i className="bi bi-chevron-down nav-desc museo-sans-500  ms-auto"></i>
      </div>
      {dropdownTwo ? (
        <>
          <div
            onClick={() => onHistory("/dashboard/edbs")}
            className="nav-info pointer navbar--dropdown-menu"
          >
            <aside></aside>
            <p className=" nav-desc museo-sans-500 ">
              <FormattedMessage id="navbar.edb.landing" />
            </p>
          </div>
          {
            !directorId && (
              <div
                onClick={() => onEDBForm()}
                className="nav-info pointer navbar--dropdown-menu"
              >
                <aside></aside>
                <p className=" nav-desc museo-sans-500 ">
                  <FormattedMessage
                    id="header.button.new.one"
                    defaultMessage="Create EDB"
                  />
                </p>
              </div>

            )
          }
          <div
            onClick={() => onHistory("/dashboard/edbs/list/open")}
            className="nav-info pointer navbar--dropdown-menu"
          >
            <aside></aside>
            <p className=" nav-desc museo-sans-500 ">
              <FormattedMessage
                id="nav.label.three.a"
                defaultMessage="Open meetings"
              />
            </p>
          </div>

          <div
            onClick={() => onHistory("/dashboard/edbs/list/dated")}
            className="nav-info pointer navbar--dropdown-menu"
          >
            <aside></aside>
            <p className=" nav-desc museo-sans-500 ">
              <FormattedMessage
                id="nav.label.three.c"
                defaultMessage="Dated meetings"
              />
            </p>
          </div>
          <div
            //style={ ( location?.pathname?.indexOf("/dashboard/edbs/list/closed") != -1  ) ? {background: activeBackground} : {}}
            onClick={() => onHistory("/dashboard/edbs/list/closed")}
            className="nav-info pointer navbar--dropdown-menu"
          >
            <aside></aside>
            <p className=" nav-desc museo-sans-500 ">
              <FormattedMessage
                id="nav.label.three.b"
                defaultMessage="Closed meetings"
              />
            </p>
          </div>
        </>
      ) : null}

      <>
        {!directorId && (
          <ShareFeaturedChildren keyType="INCORPORATIONS">
            <>
              {
                invitationToggler === "true" && (
                  <>
                  <div
                    onClick={showDropdownThree}
                    style={
                      location?.pathname?.indexOf("/dashboard/invitations") !=
                      -1
                        ? { background: activeBackground }
                        : {}
                    }
                    className="nav-info "
                  >
                    <div className=" position-absolute w-75 h-100"></div>
                    <i style={{ left: 4 }} className=" position-relative s14">
                      <FontAwesomeIcon icon={faUser} />
                      <div
                        style={{ top: -5, left: -6 }}
                        className=" position-absolute"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="7"
                          viewBox="0 0 8 7"
                          fill="none"
                        >
                          <path
                            d="M6.42186 3.58185H1.66089"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <path
                            d="M4.04211 5.96378V1.20438"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                    </i>
                    <div className="nav-desc museo-sans-500 ">
                      <p>
                        <FormattedMessage
                          id="nav.label.sixthy.index"
                          defaultMessage="My Profile"
                        />
                      </p>
                    </div>
                    <i
                      onClick={showDropdownThree}
                      className="bi bi-chevron-down nav-desc museo-sans-500  ms-auto"
                    ></i>
                  </div>
                  {dropdownThree && (
                    <>
                      <div
                        onClick={() => onHistory("/dashboard/invitations/add")}
                        className="nav-info pointer navbar--dropdown-menu"
                      >
                        <aside></aside>
                        <p className=" nav-desc museo-sans-500 ">
                          <FormattedMessage id="invitation.navbar.new.invitation" />
                        </p>
                      </div>

                      <div
                        onClick={() => onHistory("/dashboard/invitations/list")}
                        className="nav-info pointer navbar--dropdown-menu"
                      >
                        <aside></aside>
                        <p className=" nav-desc museo-sans-500 ">
                          <FormattedMessage id="invitation.navbar.your.invitation" />
                        </p>
                      </div>
                    </>
                  )}
                </>
                )
              }
            </>
            </ShareFeaturedChildren>
        )}
      </>

      <>
        {profileData.level < 2 ? (
          <div
            style={
              location?.pathname?.indexOf("/dashboard/descendants") != -1 ||
              location?.pathname?.indexOf("/dashboard/otherUserSales") != -1
                ? { background: activeBackground }
                : {}
            }
            onClick={decenderPersonalProfile}
            className="nav-info"
          >
            <i className="s14">
              <FontAwesomeIcon icon={faSitemap} />
            </i>
            <div className="nav-desc">
            <FormattedMessage
                id="nav.label.four.index"
                defaultMessage="Descendants"
              />
            </div>
          </div>
        ) : (
          <div
            style={
              location?.pathname?.indexOf("/dashboard/descendants") != -1 ||
              location?.pathname?.indexOf("/dashboard/otherUserSales") != -1
                ? { background: activeBackground }
                : {}
            }
            onClick={() => onHistory("/dashboard/descendants/dashboard")}
            className="nav-info"
          >
            <i className="s14">
              <FontAwesomeIcon icon={faSitemap} />
            </i>
            <div className="nav-desc ">
              <FormattedMessage
                id="nav.label.four.index"
                defaultMessage="Descendants"
              />
            </div>
          </div>
        )}
      </>
    </ul>
  );
}
