import { useLocation } from "react-router-dom";

export default function EcommerceRegisterPath({current, max}) {

    const location = useLocation();

    return (
        <div className="d-flex flex-column gap-1 museo-sans-400" >
            {
                location?.pathname?.includes("/new") &&

            <p className=" grayColor s12" >PASO {current} DE {max}</p>
            }
            <p className="s14" >Configurar Tienda Virtual</p>
        </div>
    );
}