import { useCallback, useEffect, useState } from "react";

export default function ModalWhiteShadow({
  scrollbarRef,
  left,
  bottom,
  width,
  aidHide,
  height,
  zIndex,
  reverseModal,
  isDebugger,
}) {
  const [toggler, setToggler] = useState(true);

  const handleScrollreverseModal = useCallback(() => {
    let currentPosition = scrollbarRef.current.scrollTop;
    if (currentPosition <= (aidHide ? aidHide : 0)) {
      setToggler(false);
    } else {
      setToggler(true);
    }
  }, [aidHide, scrollbarRef]);

  const handleScroll = useCallback(() => {
    
    let maxScrollHeight =
      scrollbarRef.current.scrollHeight - scrollbarRef.current.clientHeight;
    let currentPosition = scrollbarRef.current.scrollTop;
    if (isDebugger) {
      console.log("maxScrollHeight: ", maxScrollHeight);
      console.log("currentPosition: ", currentPosition);
      console.log("aidHide: ", aidHide);
    }
    if (maxScrollHeight <= currentPosition + (aidHide ? aidHide : 0)) {
      setToggler(false);
    } else {
      setToggler(true);
    }
    
  }, []);

  useEffect(() => {
    if (reverseModal) {
      const div = scrollbarRef?.current;
      div?.addEventListener("scroll", handleScrollreverseModal);
      return () => div?.removeEventListener("scroll", handleScrollreverseModal);
    }
  }, [handleScrollreverseModal, scrollbarRef]);

  useEffect(() => {
    if (!reverseModal) {
      const div = scrollbarRef?.current;
      div?.addEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  return (
    <>
      {toggler ? (
        <div
          style={{
            height: height ? height : "40px",
            left: left ? left : "5%",
            bottom: bottom ? bottom : "100px",
            overflowX: "hidden",
            width: width ? width : "90%",
            zIndex: zIndex ? zIndex : 5,
            transform: reverseModal ? "rotate(180deg" : "",
          }}
          className={` d-flex position-absolute ${
            reverseModal ? "shadow-effect-reverse" : "shadow-effect"
          }`}
        ></div>
      ) : (
        <></>
      )}
    </>
  );
}
