import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useCallback } from "react";

import { getCountriesTimeZone } from "../../../../common/Share/getCountriesTimeZone";
import { setCurrentWeek } from "../../Framework/HeaderAction";

import ProgressBarParams from "../../../Share/ProgressBarParams";

import moment from "moment/moment";

export default function HeaderProgress({ user }) {
  const headerData = useSelector((state) => state?.headerData);
  const [periodState, setPeriodState] = useState({ week: 1, index: 0 });
  const dispatch = useDispatch();

  const compareDate = useCallback((weeks) => {
    if (!weeks) return;

    const countryCode = localStorage.getItem("country");
    const currentDate = new Date().getTime();
    let weekIndex = 1;
    let index = 0;

    weeks.forEach((week) => {
      const startDate = moment.tz(week.startDate, getCountriesTimeZone(countryCode)).toDate();
      const endDate = moment.tz(week.endDate, getCountriesTimeZone(countryCode)).toDate();

      if (currentDate < startDate.getTime()) return;

      if (currentDate >= startDate.getTime() && currentDate <= endDate.getTime()) {
        weekIndex = week.id;
        index = currentDate === endDate.getTime() ? 3 : new Date().getDay();
      } else if (currentDate > endDate.getTime()) {
        weekIndex = week.id;
        index = 3;
      }

      setPeriodState({ week: weekIndex, index });
      dispatch(setCurrentWeek(weekIndex));
    });
  }, [dispatch]);

  const constructHeaderProgressBar = useCallback(() => {
    const maxWeeks = headerData?.period?.periodWeekCount || 4;
    const progressData = {
      1: [constructIncompletedProgress(), 0, 0, 0, ...(maxWeeks === 5 ? [0] : [])],
      2: [100, constructIncompletedProgress(), 0, 0, ...(maxWeeks === 5 ? [0] : [])],
      3: [100, 100, constructIncompletedProgress(), 0, ...(maxWeeks === 5 ? [0] : [])],
      4: [100, 100, 100, constructIncompletedProgress(), ...(maxWeeks === 5 ? [0] : [])],
      ...(maxWeeks === 5 && {
        5: [100, 100, 100, 100, constructIncompletedProgress()],
      }),
    };

    const currentProgress = progressData[periodState.week] || Array(maxWeeks).fill(0);
  
    return currentProgress.map((completed, idx) => (
      <ProgressBarParams key={idx} header={true} completed={completed} height={4} />
    ));
  }, [periodState, headerData]);
  

  const constructIncompletedProgress = useCallback(() => {
    const progressMap = {
      1: 80,
      2: 90,
      3: 100,
      4: 10,
      5: 25,
      6: 45,
      7: 65,
    };

    return progressMap[periodState.index] || 0;
  }, [periodState]);

  useEffect(() => {
    compareDate(headerData?.period?.periodWeeks);
  }, [compareDate, headerData?.period?.periodWeeks, user]);

  return (
    <div id="header--progressbar">
      {constructHeaderProgressBar()}
    </div>
  );
}
