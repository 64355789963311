
import { getFirebaseData } from "../services/firebase";

export default function ShareCheckEnvKey(keyType) {
  const country = localStorage.getItem("country");
  const getValueFromFirebase = async () => {
    const resultObject = await getFirebaseData(keyType);
    if (resultObject) {
      return resultObject[country];
    }
    return false;
  };

  return getValueFromFirebase();
}
