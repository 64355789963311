import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import ShareCenterControlPage from "../../../common/Share/ShareCenterControlPage";
import { fetchVirtualEcommerce } from "../../Framework/redux/EcommerceSideEffect";

export default function EcommerceDashboard() {

  const dispatch = useDispatch();
  const history = useNavigate();

  const onStartUp = async () => {
    const serveceResponse = await dispatch(fetchVirtualEcommerce());
    const responseStatus = serveceResponse.payload.response?.status;

    console.log("serveceResponse: ", serveceResponse)
    
    if (serveceResponse.payload?.id) {
      history(`/dashboard/ecommerce/edit/${serveceResponse.payload?.id}`);
      return;
    };
    if (responseStatus == 400 || responseStatus == 500) {
      history("/dashboard/ecommerce/error");
      return;
    };
    if (responseStatus == 404) {
      history("/dashboard/ecommerce/new");
      return;
    }
    return;
  };

  useEffect(() => {
    onStartUp()
  }, [])

  return (
      <ShareCenterControlPage pageBackground={"white"} >
        <Outlet />
      </ShareCenterControlPage>
  );
}
