import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";

export default function EcommerceRegisterModalInfo({ current }) {
  return (
    <div style={{ padding: "44px" }}>
      <div className="d-flex align-items-center gap-2 museo-sans-600 s24 ">
        <div
         
        style={{ top: "-6px" }} className=" position-relative">
          <HelpIconNoTarget />
        </div>
        <p>Nombre de la Tienda Virtual</p>
      </div>
      <div className=" d-flex flex-column gap-4 mt-2 pt-1 s14 museo-sans-400">
        <p>
          El nombre es la dirección web única y personal que va a tener tu
          Tienda Virtual.
        </p>
        <p>
          Recuerda seleccionar un nombre corto y memorable que te identifique
          individualmente con la gente que te conoce.
        </p>
        <p className=" museo-sans-600">
          La misma es permanente y no puede ser modificado después de haberlo
          configurado.
        </p>
      </div>
      <div
        style={{ marginTop: "44px" }}
        className="w-100 d-flex justify-content-center"
      >
        <button
          data-bs-dismiss="modal"
          aria-label="Close"
          className=" border-0 rounded-3 btnLoginWidth museo-sans-600 s14 skyBg text-white "
        >
          <FormattedMessage id="buttons.understood" />
        </button>
      </div>
    </div>
  );
}
