import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { ReactComponent as SVGClose } from "../../common/svgs/ic-x-lg.svg";
import PerformanceLoadingMoreRequests from './../../Performance/Presentation/components/PerformanceLoadingMoreRequests';
import HelpIconNoTarget from "./HelpIconNoTarget";
import { getPeriod } from "../Header/Framework/HeaderSideEffect";

export default function LastUpdate({isDashboard}) {

  const dispatch = useDispatch();
    const lastUpdateInfo = useSelector((state) => state.headerData.period);

    const [updateDate, setUpdateDate] = useState(undefined)

    useEffect(() => {
        onStartUp();
      }, [])
      
      const onStartUp = async () => {
        const value = await dispatch(getPeriod());
        setUpdateDate(new Date(value?.payload?.currentEarningsUpdate))
    };
    
    function defineHours(){
        
        const hourNumber = Number(lastUpdateInfo?.currentEarningsUpdate.substring(11,13))
        return hourNumber;
    }

    function defineMinutes(){
        return lastUpdateInfo?.currentEarningsUpdate.substring(14,16)
    }

    function defineDay(){
        return lastUpdateInfo?.currentEarningsUpdate.substring(8,10)
    }

    function defineMonth(){
        const monthNumber = Number(lastUpdateInfo?.currentEarningsUpdate.substring(5,7))
        if (monthNumber < 10) {
          return `0${monthNumber}`;  
        }
        return monthNumber;
    }
  
    return (
        <>
        <div style={{height: "33px"}} className={` ${isDashboard ? "" : "mb-4"} d-flex justify-content-between `} >
            <button type="button" data-bs-toggle="modal" data-bs-target="#edbUpdateModal" className='d-flex align-items-center gap-1 button-border-none button-bootstrap-radius button-padding-2 blueColor bronceBg museo-sans-500 s14 edb-lastest-update-shadow'>
                {
                    !updateDate ?
                        <PerformanceLoadingMoreRequests text={""} small={true}/>
                    :
                      <>
                            <FormattedMessage id="edb.lastest.update" defaultMessage="Ultima actualización:" />
                            {
                              lastUpdateInfo?.currentEarningsUpdate &&
                            <p className="pe-1" >
                                {`${defineDay()}/${defineMonth()}, `} {`${defineHours()}:${defineMinutes()}`}
                            </p>

                            }
                            <HelpIconNoTarget size={"sm"} />
                      </>
                }
                

            </button>
        </div>
        <div
      className="modal fade"
      id="edbUpdateModal"
      aria-hidden="true"
      aria-labelledby="edbUpdateModal"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "600px" }}
      >
        <div className="modal-content modal-radius">
          <div className="modal-body p-0">
            <div>
              <div className="d-flex align-items-center justify-content-between px-5 py-4 my-3">
                <div style={{minHeight: "32px"}} className="d-flex align-items-center">
                  <HelpIconNoTarget />
                  <h5 className="museo-sans-500 px-2 s20 m-0">
                    <FormattedMessage
                      id="edb.lastest.update.modal.title"
                      defaultMessage="Última actualizacion"
                    />
                  </h5>
                </div>
                <div className=" pointer " data-bs-dismiss="modal"
                  aria-label="Close">
                    <SVGClose />
                </div>
              </div>
              <hr className="m-0"></hr>
              <div className="d-flex flex-column align-items-center">
                <div className="mt-4 px-5">
                  <div className=" d-flex py-2 s16">
                    <p className="museo-sans-500">
                      <FormattedMessage
                        id="edb.lastest.update.modal.info.p.1.500"
                        defaultMessage=" "
                      />
                    <span className="museo-sans-700" >
                    <FormattedMessage
                        id="edb.lastest.update.modal.info.p.1.700"
                        defaultMessage=" "
                      />
                    </span>
                    </p>

                  </div>
                  <div className=" d-flex py-2 s16" >
                    <p className="museo-sans-500">
                      <FormattedMessage
                        id="edb.lastest.update.modal.info.p.2.500"
                        defaultMessage=" "
                      />
                    </p>
                    <p className="museo-sans-700">
                      <FormattedMessage
                        id="edb.lastest.update.modal.info.p.2.700"
                        defaultMessage=" "
                      />
                    </p>

                  </div>

                </div>
                <button
                  type="button"
                  className=" btnLoginWidth my-5 museo-sans-700 s14 whiteColor skyBorder skyBg button-hover-light-text button-bootstrap-radius button-padding mt-4 mb-5"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <FormattedMessage
                    id="buttons.understood"
                    defaultMessage=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </>
    )
}