import { FormattedMessage } from "react-intl";
import { SkeletonAccountBalanceEthic } from "./AccountBalance";
import ShareFeaturedChildren from "../../../common/Share/ShareFeaturedChildren";

export default function AccountBalanceSmaller({
  loading,
  goTo,
  renderBalance,
  periodReducer,
}) {
  return (
    <div>
      <div
        className="d-flex flex-row align-items-center"
        style={{ gap: "25px" }}
      >
        <p className="d-flex museo-sans-500 s20">
          <FormattedMessage id="balance.salary.title" />
        </p>
        {loading ? (
          <div
            style={{ height: "12px" }}
            className="rounded-3 brightGrayBg w-25"
          ></div>
        ) : (
          <ShareFeaturedChildren keyType="STATUS_ACCOUNT">
            <div onClick={goTo}>
              <a className="pointer s14 skyColor museo-sans-700">
                <FormattedMessage id="account.balance.button.message" />
              </a>
            </div>
          </ShareFeaturedChildren>
        )}
      </div>
      <ShareFeaturedChildren keyType="JUST_COINS">
        <ContainerBalanceSmaller isJustCoins={true}>
          {loading ? (
            <SkeletonAccountBalanceEthic />
          ) : (
            <>
              {periodReducer.data && (
                <>
                  <div
                    style={{ width: "auto" }}
                    className="d-flex py-4 justify-content-between flex-column text-center"
                  >
                    {renderBalance(
                      "current",
                      periodReducer.data.currentAccount,
                      periodReducer.data.currentAccountStatus
                    )}
                  </div>
                  <div
                    style={{ width: "1px", height: "56px" }}
                    className="brightGrayBg"
                  ></div>
                  <div className="d-flex py-4 justify-content-between flex-column text-center">
                    {renderBalance(
                      "justCoins",
                      periodReducer.data.justCoins,
                      periodReducer.data.justCoinsStatus
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </ContainerBalanceSmaller>
      </ShareFeaturedChildren>
      <ShareFeaturedChildren keyType="JUST_COINS" falsePositive={true}>
        <ContainerBalanceSmaller isJustCoins={false}>
          {loading ? (
            <SkeletonAccountBalanceEthic />
          ) : (
            <>
              {periodReducer.data && (
                <div
                  style={{ width: "80%" }}
                  className="d-flex py-4 justify-content-between flex-column text-center"
                >
                  {renderBalance(
                    "current",
                    periodReducer.data.currentAccount,
                    periodReducer.data.currentAccountStatus
                  )}
                </div>
              )}
            </>
          )}
        </ContainerBalanceSmaller>
      </ShareFeaturedChildren>
    </div>
  );
}

export function ContainerBalanceSmaller(params) {
  return (
    <div
      className="f9GrayBorder position-relative align-items-center justify-content-evenly mt-3 d-flex account--card-shadow bg-white rounded-account-cards"
      style={
        params?.isJustCoins
          ? { width: "auto", height: "96px" }
          : { width: "276px", height: "96px" }
      }
    ></div>
  );
}
