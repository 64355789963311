import ShareErrorHandlerServices from "../../../common/Share/ShareErrorHandlerServices";
import DashboardTriperiodSale from "./DashboardTriperiodSale";

export default function DashboardTriperiodContainer({
  profileData,
  scoreData,
  errorScoreData,
  periodGap,
  fetchScore,
}) {
  return (
    <>
      {profileData.userInfo.level < "2" &&
        !scoreData?.consultantStatus?.potentialLeader && (
          <div className=" w-100">
            {errorScoreData ? (
              <div className=" pt-5 w-100 h-100 d-flex justify-content-center align-items-center">
                <div className=" pt-5 mt-5">
                  <ShareErrorHandlerServices service={fetchScore} />
                </div>
              </div>
            ) : (
              <DashboardTriperiodSale
                status={scoreData.consultantStatus.checkMarkStatus}
                level={profileData.userInfo.level}
                potentialLeader={scoreData?.consultantStatus?.potentialLeader}
                gap={periodGap}
                scoreData={scoreData?.generalDataArray}
              />
            )}
          </div>
        )}
    </>
  );
}
